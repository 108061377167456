import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';

import { Heading, Button, ProgressBar } from '../../Components/Atoms';
import { AuthLayout } from '../../Layout';
import { useSelector } from 'react-redux';

const CheckForFiles = ({ profile: { children_certificate, govt_identity, insruance_certificate, certification } }) => {
  if (children_certificate && govt_identity && insruance_certificate && certification) return null;
  return (
    <FilesWarningBox>
      <h2 style={{ color: 'white' }}>To be able to finish registration you need to upload the files below:</h2>
      <FilesWarningWrapper>
        <h5 style={{ color: 'white', marginRight: 10 }}>Mandatory files:</h5>
        <div style={{ display: 'flex' }}>
          <span> {certification && <h6 style={{ color: 'white' }}>Certification</h6>}</span>
          <span> {govt_identity && <h6 style={{ color: 'white' }}>Government ID</h6>}</span>
          <span> {insruance_certificate && <h6 style={{ color: 'white' }}>Indemnity Insurance</h6>}</span>
          <span> {children_certificate && <h6 style={{ color: 'white' }}>DBS Check Certificate</h6>}</span>
        </div>
      </FilesWarningWrapper>
      <StyledLink to="/t/signup/step-5">Finish file Upload</StyledLink>
    </FilesWarningBox>
  );
};

const FilesWarningBox = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.statusWarning};
  border-radius: 5px;
  margin: 20px 0;
`;

const FilesWarningWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  span {
    margin: 0 10px;
  }
`;

const StyledLink = styled(Link)`
  margin-top: 40px;
  color: white;
  font-size: 16px;
`;

const Step8 = () => {
  const [scheduleState, setScheduleState] = useState(false);
  const profile = useSelector((store) => store.user?.profile);

  const history = useHistory();

  const nextStep = () => history.push('/t/signup/step-9');

  const isAllFilesPresent = useMemo(() => {
    if (!profile) return false;
    return !!(
      profile.children_certificate &&
      profile.govt_identity &&
      profile.insruance_certificate &&
      profile.certification
    );
  }, [profile]);

  const scheduleOnFinish = ({ data: { event } }) => {
    if (event === 'calendly.event_scheduled') setScheduleState(true);
  };

  if (!profile) return null;

  return (
    <AuthLayout>
      <Wrapper>
        <Heading align="center">Training session</Heading>
        <ProgressBar progress="80" style={{ margin: '20px auto', maxWidth: 500 }} />

        <p>
          In order to work as a SupportRoom Therapist we need to book in a 15 minute training session so you understand
          how the platform works. Please pick a date and time from the below options:
        </p>

        <CheckForFiles profile={profile} />

        {isAllFilesPresent ? (
          <>
            <CalendarWrapper>
              <CalendlyEventListener onEventScheduled={scheduleOnFinish} />
              <InlineWidget styles={{ height: 600 }} url="https://calendly.com/supportroom-ashvipani" />
            </CalendarWrapper>
            <br />
            <br />
            <Button disabled={!scheduleState} variant="primary" onClick={nextStep}>
              Continue
            </Button>
          </>
        ) : null}
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  max-width: 100%;
  margin: auto;
`;
Wrapper.displayName = 'Wrapper';

const ButtonWrapper = styled.div`
  margin: ${({ theme }) => theme.spacingXl} 0;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 32px;
  }
`;
ButtonWrapper.displayName = 'ButtonWrapper';

const CalendarWrapper = styled.div`
  position: relative;
  padding: 0 30px;
  border-radius: 10px;
`;

export default Step8;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import InfiniteScroll from 'react-infinite-scroll-component';

import { DashboardLayout, DefaultLayout } from '../../Layout';

import Filter from './filter';
import PostCard from './postCard';
import { Loading } from '../../Components/Atoms';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_CATEGORIES,
  SET_HAS_MORE,
  SET_PAGE,
  SET_POSTS,
  SET_SELECTED_CATEGORIES,
  SET_VIDEO_POSTS,
} from '../../Constants/actionTypes';
import { useHistory, useLocation } from 'react-router-dom';
import { calmMusicPlayLists, podcastPlayLists, recipesPlayLists } from './spotifyPlayLists';
import { useTranslation } from 'react-i18next';
import { axiosPost, axiosVideoPost } from '../../Util/axiosConfig';

export const getPosts = (page, categories) => (dispatch) =>
  new Promise((resolve, reject) =>
    axiosPost
      .get('/posts?_embed', { params: { per_page: 20, page, categories } })
      .then((res) => {
        dispatch({ type: SET_POSTS, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getCategories = () => (dispatch) =>
  new Promise((resolve, reject) =>
    axiosPost
      .get('/categories?per_page=100&parent=0')
      .then((res) => {
        dispatch({ type: GET_CATEGORIES, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getVideoPosts = (categoryId) => (dispatch) =>
  new Promise((resolve, reject) =>
    axiosVideoPost
      .get(`/videos/${categoryId}`)
      .then((res) => {
        dispatch({ type: SET_VIDEO_POSTS, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getVideoCategories = () => (dispatch) =>
  new Promise((resolve, reject) =>
    axiosVideoPost
      .get('/video-categories?per_page=100&parent=0')
      .then((res) => {
        dispatch({ type: GET_CATEGORIES, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

const loadingStyles = {
  position: 'initial',
  width: '100%',
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
};

const WellBeing = () => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  // const queryParam = search.slice(4, search.indexOf('='));
  const isVideo = useMemo(() => query.get('is_video'), [query]);
  const isPodcast = useMemo(() => query.get('is_podcast'), [query]);
  const isCalmMusicRecipes = useMemo(() => query.get('is_calmmusic') || query.get('is_recipes'), [query]);
  const [activeView, setActiveView] = useState('blog');
  const [podcastFilter, setPodcastFilter] = useState([0]);
  const [loaderVal, setLoaderVal] = useState(false);

  const role = useSelector((store) => store.user?.role);
  const posts = useSelector((store) => store.chatroom?.wellBeing?.posts);
  const videoPosts = useSelector((store) => store.chatroom?.wellBeing?.videoPosts);
  const categories = useSelector((store) => store.chatroom?.wellBeing?.categories);
  const page = useSelector((store) => store.chatroom?.wellBeing?.page);
  const hasMore = useSelector((store) => store.chatroom?.wellBeing?.hasMore);
  const selectedCategories = useSelector((store) => store.chatroom?.wellBeing?.selectedCategories);

  const dispatch = useDispatch();
  const history = useHistory();

  const fetchNextPosts = useCallback(() => {
    dispatch(getPosts(page, selectedCategories))
      .then((res) => {
        dispatch({ type: SET_POSTS, payload: [...posts, ...res.data] });
        dispatch({ type: SET_PAGE, payload: page + 1 });
        dispatch({ type: SET_HAS_MORE, payload: true });
      })
      .catch((err) => {
        if (err.response?.data?.code === 'rest_post_invalid_page_number') {
          dispatch({ type: SET_HAS_MORE, payload: false });
        }
      });
  }, [page, selectedCategories, dispatch, posts]);

  const categoryOnClick = (e) => {
    const id = activeView === 'podcast' ? e.target.id : parseInt(e.target.id);
    let newCategories = activeView === 'podcast' ? [...podcastFilter] : [...selectedCategories];
    const allCheckBoxIndex = newCategories.indexOf(0);
    if (activeView === 'podcast') {
      if (newCategories.length === 0 || allCheckBoxIndex > -1) {
        newCategories.splice(allCheckBoxIndex, 1);
      }
      if (id === '0' && newCategories.length > 0) newCategories = [];
      else if (newCategories.includes(id)) {
        const index = newCategories.indexOf(id);
        if (index > -1) newCategories.splice(index, 1);
      } else if (!podcastFilter.includes(id)) {
        const index = newCategories.indexOf('0');
        if (index > -1) newCategories.splice(index, 1);
        newCategories = [...newCategories, id];
      }
      return setPodcastFilter(newCategories);
    }
    if (activeView === 'blog') {
      if (id === 0 && newCategories.length > 0) newCategories = [];
      else if (newCategories.includes(id)) {
        const index = newCategories.indexOf(id);
        if (index > -1) newCategories.splice(index, 1);
      } else if (!selectedCategories.includes(id)) {
        const index = newCategories.indexOf(0);
        if (index > -1) newCategories.splice(index, 1);
        newCategories = [...newCategories, id];
      }

      dispatch(getPosts(1, newCategories, activeView))
        .then((res) => {
          if (res.data.length) dispatch({ type: SET_PAGE, payload: 2 });
          else dispatch({ type: SET_HAS_MORE, payload: false });
        })
        .catch((err) => {
          if (err.response?.data?.code === 'rest_post_invalid_page_number') {
            dispatch({ type: SET_HAS_MORE, payload: false });
          }
        });
    } else if (activeView === 'video') {
      newCategories = [id];

      dispatch(getVideoPosts(newCategories[0] || 0))
        .then(() => {
          dispatch({ type: SET_PAGE, payload: 2 });
        })
        .catch((err) => {
          if (err.response?.data?.code === 'rest_post_invalid_page_number') {
            dispatch({ type: SET_HAS_MORE, payload: false });
          }
        });
    } else {
      return;
    }

    dispatch({ type: SET_SELECTED_CATEGORIES, payload: newCategories });
    dispatch({ type: SET_HAS_MORE, payload: true });
  };

  const viewOnChange = (e) => {
    const view = e.target.getAttribute('name');

    if (view === 'blog' && view !== activeView) {
      dispatch({ type: SET_SELECTED_CATEGORIES, payload: [] });
      dispatch(getPosts(page, []));
      dispatch(getCategories());
      history.push(`${role === 'therapist' ? '/t' : ''}/well-being`);
    } else if (view === 'video' && view !== activeView) {
      dispatch({ type: SET_PAGE, payload: 1 });
      dispatch({ type: SET_SELECTED_CATEGORIES, payload: [] });
      dispatch(getVideoPosts(0));
      dispatch(getVideoCategories());
      history.push(`${role === 'therapist' ? '/t' : ''}/well-being?is_video=true`);
    } else if (view === 'podcast' && view !== activeView) {
      setLoaderVal(true);
      history.push(`${role === 'therapist' ? '/t' : ''}/well-being?is_podcast=true`);
    } else if (view === 'calmmusic' && view !== activeView) {
      setLoaderVal(true);
      history.push(`${role === 'therapist' ? '/t' : ''}/well-being?is_calmmusic=true`);
    } else if (view === 'recipes' && view !== activeView) {
      setLoaderVal(true);
      history.push(`${role === 'therapist' ? '/t' : ''}/well-being?is_recipes=true`);
    }

    setActiveView(view);
  };

  useEffect(() => {
    if (isVideo === 'true') {
      setActiveView('video');
      if (!videoPosts) dispatch(getVideoPosts(0)).then(() => dispatch({ type: SET_PAGE, payload: page + 1 }));
      if (!categories) dispatch(getVideoCategories());
    } else {
      if (!posts)
        dispatch(getPosts(page, selectedCategories)).then(() => dispatch({ type: SET_PAGE, payload: page + 1 }));
      dispatch(getCategories());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Layout = role === 'therapist' ? DashboardLayout : DefaultLayout;

  return (
    <Layout>
      <WellBeingPage>
        <WellBeingWrapper>
          <h2>{t('resourceCentre')}</h2>
          {loaderVal && <Loading />}
          <MainWrapper>
            <Filter
              categories={isPodcast ? podcastPlayLists : isCalmMusicRecipes ? [] : categories}
              selectedCategories={activeView === 'podcast' ? podcastFilter : selectedCategories}
              onClick={categoryOnClick}
              activeView={activeView}
              isCalmMusicRecipes={isCalmMusicRecipes}
              showFilter={!isCalmMusicRecipes}
            />
            <ContentWrapper>
              <TypeWrapper>
                <TypeButton name="blog" active={activeView === 'blog'} onClick={viewOnChange}>
                  {t('article')}
                </TypeButton>
                <TypeButton name="video" active={activeView === 'video'} onClick={viewOnChange}>
                  {t('videos')}
                </TypeButton>
                <TypeButton name="podcast" active={activeView === 'podcast'} onClick={viewOnChange}>
                  {t('podcast')}
                </TypeButton>
                <TypeButton name="calmmusic" active={activeView === 'calmmusic'} onClick={viewOnChange}>
                  {t('calmMusic')}
                </TypeButton>
                {/* <TypeButton name="recipes" active={activeView === 'recipes'} onClick={viewOnChange}>
                  Recipes
                </TypeButton> */}
              </TypeWrapper>
              {activeView === 'blog' && (
                <PostsWrapper
                  dataLength={posts?.length || 0}
                  next={fetchNextPosts}
                  hasMore={hasMore}
                  loader={<Loading styles={loadingStyles} />}
                  endMessage={
                    <SeenAll>
                      <h3>{t('seenItAll')}</h3>
                    </SeenAll>
                  }
                >
                  {posts?.map((post) => (
                    <CardWrap>
                      <PostCard key={post.id} data={post} activeView={activeView} />
                    </CardWrap>
                  ))}
                </PostsWrapper>
              )}
              {activeView === 'video' && (
                <PostsWrapperNoLoader>
                  {videoPosts?.map((post) => (
                    <CardWrap>
                      <PostCard key={post.ID} data={post} activeView={activeView} />
                    </CardWrap>
                  ))}
                </PostsWrapperNoLoader>
              )}
              {activeView === 'podcast' && (
                <PostsWrapperNoLoader>
                  {podcastPlayLists.map(({ id, name }) => {
                    const ismatchedCatagory =
                      podcastFilter.includes(id) ||
                      podcastFilter.includes('0') ||
                      podcastFilter.length === 0 ||
                      podcastFilter.includes(0);
                    return (
                      ismatchedCatagory && (
                        <CardWrap key={id}>
                          <iframe
                            onLoad={() => setLoaderVal(false)}
                            title={name}
                            src={`https://open.spotify.com/embed/playlist/${id}?utm_source=generator`}
                            width="100%"
                            height="380"
                            frameBorder="0"
                            allowfullscreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen;"
                          ></iframe>
                        </CardWrap>
                      )
                    );
                  })}
                </PostsWrapperNoLoader>
              )}
              {activeView === 'calmmusic' && (
                <PostsWrapperNoLoader>
                  {calmMusicPlayLists.map(({ id, name }) => {
                    const ismatchedCatagory =
                      podcastFilter.includes(id) ||
                      podcastFilter.includes('0') ||
                      podcastFilter.length === 0 ||
                      podcastFilter.includes(0);
                    return (
                      ismatchedCatagory && (
                        <CardWrap key={id}>
                          <iframe
                            onLoad={() => setLoaderVal(false)}
                            title={name}
                            src={`https://open.spotify.com/embed/playlist/${id}?utm_source=generator`}
                            width="100%"
                            height="380"
                            frameBorder="0"
                            allowfullscreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen;"
                          ></iframe>
                        </CardWrap>
                      )
                    );
                  })}
                </PostsWrapperNoLoader>
              )}
              {activeView === 'recipes' && (
                <PostsWrapperNoLoader>
                  {recipesPlayLists.map(({ id, name }) => {
                    const ismatchedCatagory =
                      podcastFilter.includes(id) ||
                      podcastFilter.includes('0') ||
                      podcastFilter.length === 0 ||
                      podcastFilter.includes(0);
                    return (
                      ismatchedCatagory && (
                        <CardWrap key={id}>
                          <iframe
                            onLoad={() => setLoaderVal(false)}
                            title={name}
                            src={`https://open.spotify.com/embed/playlist/${id}?utm_source=generator`}
                            width="100%"
                            height="380"
                            frameBorder="0"
                            allowfullscreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen;"
                          ></iframe>
                        </CardWrap>
                      )
                    );
                  })}
                </PostsWrapperNoLoader>
              )}
            </ContentWrapper>
          </MainWrapper>
        </WellBeingWrapper>
      </WellBeingPage>
    </Layout>
  );
};

export const WellBeingPage = styled.div`
  padding-top: 30px;

  @media screen and (max-width: 500px) {
    padding: 20px 8px 20px 8px;
  }
`;

export const CardWrap = styled.div`
  margin: 10px;
`;

export const WellBeingWrapper = styled.div`
  h2 {
    font-size: 32px;
    line-height: 37px;
    font-weight: 900;
    color: ${({ theme }) => theme.secondaryMedium};
  }

  @media screen and (max-width: 500px) {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  padding-top: 40px;
  padding-bottom: 20px;

  @media screen and (max-width: 900px) {
    padding-top: 20px;
    flex-wrap: wrap;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 35px;
  width: 80%;

  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 0;
  }
`;

const TypeButton = styled.div`
  padding: 4px 30px;
  display: inline-block;
  font-size: 16px;

  cursor: pointer;

  border: ${({ theme }) => `1px solid ${theme.greyMedium}`};

  color: ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.primaryLight};

  &:hover {
    font-weight: 600;
  }

  ${({ active }) =>
    active &&
    css`
      font-weight: 600;
      background-color: ${({ theme }) => theme.primary};
      color: white !important;
    `}
`;

const TypeWrapper = styled.div`
  ${TypeButton} {
    &:first-child {
      //border-right: 1px solid white;
    }
  }
`;

const PostsWrapper = styled(InfiniteScroll)`
  position: relative;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  padding-bottom: 50px;

  @media screen and (max-width: 900px) {
    margin-top: 20px;
    justify-content: space-evenly;
  }
`;

const PostsWrapperNoLoader = styled.div`
  position: relative;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  padding-bottom: 50px;

  @media screen and (max-width: 900px) {
    margin-top: 20px;
    justify-content: space-evenly;
  }
`;

const SeenAll = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  color: black;
`;

export default WellBeing;

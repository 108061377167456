import React, { memo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Logo, ChevronIcon, TextBlock, ModalComponent } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';
import { store } from 'react-notifications-component';

import { useTranslation } from 'react-i18next';
import GoogleInitComponent from './googleInitComponent';
import FacebookInitComponent from './facebookInitComponent';
import { SignUpButton, SocialIcon, ButtonTextWrapper, IconWrapper } from './signUpButton';
import { ReactComponent as EnvelopeIcon } from '../../Assets/SignUp/envelope-o.svg';
import { BottomSectionWrapper, MainSectionWrapper, ModalOverlay } from '../Components/';
import { socialLogin, toggleSocialInformationRequest } from '../../Actions/patient.actions';
import { Heading } from '../../Components/Atoms';
import SocialSignupAdditionalInformation from './socialSignUpInformationForm';
import { useDeviceType } from '../../Util';
import EmergencyBtn from '../../Components/Atoms/EmergencyBtn';

const Account = () => {
  const { t } = useTranslation();
  const [socialData, setSocialData] = useState(null);
  const isPartner = useSelector((store) => store?.isPartner?.isPartner);
  const [enterpriseLink, setEnterpriseLink] = useState(false);
  const history = useHistory();
  const { userType } = useParams();

  const [modalState, setModalState] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useDeviceType();
  const closeModal = () => dispatch(toggleSocialInformationRequest());

  const successToater = () => {
    store.addNotification({
      title: t("signUpSuccessful"),
      message: t("completeSignUpprocess"),
      type: 'success',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };

  const socialSignUp = useCallback(
    (data, type) => {
      setSocialData(data);
      if (type === 'google' && data) {
        const { googleId, email, name } = data;
        if (googleId) {
          const data = {
            email: email ? email : `${googleId}@example.com`,
            social_id: googleId,
            social_type: 'google',
            mobile: '00000000000',
            first_name: name,
            quiz_group: localStorage.getItem('quiz_journey'),
          };
          dispatch(socialLogin(data, history))
            .then((response) => {
              if (response.firstUse) setModalState(true);
              successToater();
            })
            .catch((err) => {
              store.addNotification({
                title: t("somethingWentWrong"),
                message: err.response?.data?.message,
                type: 'danger',
                container: 'top-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
              // redirectToQuestionnaire();
            });
        }
      } else if (type === 'facebook' && data) {
        const { id, email, name } = data;
        if (id) {
          const data = {
            email: email ? email : `${id}@example.com`,
            social_id: id,
            social_type: 'facebook',
            mobile: '00000000000',
            first_name: name,
            quiz_group: localStorage.getItem('quiz_journey'),
          };
          dispatch(socialLogin(data, history))
            .then((response) => {
              if (response.firstUse) setModalState(true);
              successToater();
            })
            .catch((err) => {
              store.addNotification({
                title: t("somethingWentWrong"),
                message: err.response?.data?.message,
                type: 'danger',
                container: 'top-right',
                animationIn: ['animate__animated animate__fadeIn'],
                animationOut: ['animate__animated animate__fadeOut'],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
              // redirectToQuestionnaire();
            });
        }
      }
    },
    [dispatch, history],
  );

  return (
    <WelcomeLayout noPadding noTopBar>
      {modalState && (
        <ModalComponent open={modalState} closeModal={closeModal} showCloseButton={false} closeOnDocumentClick={false}>
          <div>
            <Heading level={3}>{t("needAdditionalInformation")}</Heading>
            <SocialSignupAdditionalInformation socialData={socialData} />
          </div>
        </ModalComponent>
      )}
      <section>
        <MainSectionWrapper isMobile={isMobile} noPadding>
          <Logo />
          <TextBlock type={'primary'}>{t("CreateYourAccount")}</TextBlock>
          {window.location.host !== 'gympass.supportroom.com' && (
            <GoogleInitComponent buttonText={t("signUpWithGoogle")} initAuth={socialSignUp} />
          )}
          {/* <FacebookInitComponent buttonText="Sign up with Facebook" initAuth={socialSignUp} /> */}
          <SignUpButton onClick={() => history.push(userType ? `/welcome/${userType}/signup` : '/welcome/signup')}>
            <IconWrapper>
              <SocialIcon as={EnvelopeIcon} />
            </IconWrapper>
            <ButtonTextWrapper>{t("signUpWithEmail")}</ButtonTextWrapper>
          </SignUpButton>
          {!isPartner && (
            <Button
              variant="secondary"
              style={{
                fontWeight: 600,
                fontSize: 14,
                color: 'white',
                background: '#33c8a0',
                // marginTop: isMobile ? '0px' : '40px',
                width: isMobile ? '100%' : '350px',
                height: isMobile ? '45px' : '50px',
              }}
              onClick={() => setEnterpriseLink(!enterpriseLink)}
            >
              {t("recievedEnterpriseLink")}
              <ChevronIcon direction="right" width="13" height="13" />
            </Button>
          )}
        </MainSectionWrapper>
      </section>
      <section>
        <BottomSectionWrapper>
          <EmergencyBtn />
        </BottomSectionWrapper>
      </section>
      {enterpriseLink && (
        <ModalOverlay>
          <ModalComponent open={enterpriseLink} closeModal={() => setEnterpriseLink(!enterpriseLink)}>
            <BoldPrimaryText>
            {t("enterpriseText")}
            </BoldPrimaryText>
          </ModalComponent>
        </ModalOverlay>
      )}
    </WelcomeLayout>
  );
};

const BoldPrimaryText = styled.div`
  font-size: 1.5em;
  color: #491f69;
  margin-bottom: 20px;
  font-weight: bold;
  padding: 10px;
`;
export default memo(Account);

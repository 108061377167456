import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import AccountIcon from '../../../Assets/Icons/MyAccount.svg';
import AccountWhiteIcon from '../../../Assets/Icons/MyAccountWhite.svg';
import CloseIcon from '../../../Assets/Icons/ClosePurple.svg';
import { PatientMenuLinks, GympassPatientMenuLinks } from '../../../Data/Menus';
import ModalComponent from '../../Atoms/Modal/Modal';
import { Button, Heading } from '../../Atoms';
import Faq from '../../../Therapist/Chatroom/Components/Faq';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import LanguageSwitch from '../../LangSwitcher/LanguageSwitch';
import SwitchLanguage from '../../LangSwitcher/SwitchLanguage';
import LanguageSwitch from '../../LangSwitcher/LanguageSwitch';
import { useDeviceType } from '../../../Util';
import ChangeTherapist from '../../../Patient/Account/Components/ChangeTherapist';

const PatientMenu = ({ showWhiteIcon, chatRoomID, subscription, rooms }) => {
  const { t } = useTranslation();
  const menuLink =
    window.location.host === 'gympass.supportroom.com' ? GympassPatientMenuLinks(t) : PatientMenuLinks(t);
  const [isOpen, setIsOpen] = useState(false);
  const [modalState, setModalState] = useState(false);

  const profile = useSelector((store) => store.user?.profile);
  const isPatient = useSelector((state) => state.user.role) === 'patient';
  const isAllowedSwitchTherapist = useSelector((store) => store.user?.profile?.switch_therapist);
  const isB2b = useSelector((store) => store.user?.profile?.['B2B'] !== 0);

  const history = useHistory();
  const isMobile = useDeviceType();

  const handleStateChange = ({ isOpen }) => setIsOpen(isOpen);

  const closeMenu = () => setIsOpen(false);

  const closeModal = () => setModalState(false);
  const openModal = () => {
    closeMenu();
    setModalState(true);
  };

  const signOut = () => {
    closeModal();
    history.push('/logout');
  };

  const isChatRoomActive = useMemo(() => {
    return !!(rooms && rooms.length && rooms[0].chatroom_status === 'approved');
  }, [rooms]);

  return (
    <div style={{ marginTop: '-5px' }} isMobile={isMobile}>
      <Menu
        width={isMobile ? 250 : 300}
        isOpen={isOpen}
        onStateChange={handleStateChange}
        right
        customBurgerIcon={<img src={showWhiteIcon ? AccountWhiteIcon : AccountIcon} alt="" />}
        customCrossIcon={<img src={CloseIcon} alt="" />}
        styles={NavStyles}
        pageWrapId={'mainContent'}
        outerContainerId={'siteWrapper'}
        disableAutoFocus
      >
        <MenuWrapper>
          <StyledHeading>{t('myAccount')}</StyledHeading>
          {modalState && (
            <ModalComponent open={modalState} closeModal={closeModal}>
              <div style={{ textAlign: 'center' }}>
                <Heading level={3}>{t('signOutheading')}</Heading>
                <ButtonsWrapper>
                  <Button inline className="small success" onClick={signOut}>
                    {t('signOut')}
                  </Button>
                  <Button inline className="small warning" onClick={closeModal}>
                    {t('cancel')}
                  </Button>
                </ButtonsWrapper>
              </div>
            </ModalComponent>
          )}

          <ul>
            {menuLink.map((item) => {
              if (isPatient && item.url === '/saved-messages') return null;

              return (
                <li key={item.id}>
                  <StyledLink to={item.url} onClick={closeMenu}>
                    {item.title}
                  </StyledLink>
                  {item.subNav ? (
                    <ul>
                      {item.url === '/account' ? (
                        <>
                          <li>
                            <StyledLink style={{ marginBottom: '21px' }}>
                              <SwitchLanguage showWhitetext positionBox />
                              <Lang>
                                <LanguageSwitch showText />
                              </Lang>
                            </StyledLink>
                          </li>
                          <li>
                            <StyledLink style={{ marginBottom: '21px' }}>
                              {isAllowedSwitchTherapist && isChatRoomActive && (subscription?.is_paid || isB2b) && (
                                <ChangeTherapist
                                  closeMenu={closeMenu}
                                  isSideBar
                                  chatroomID={chatRoomID}
                                  exceedsswitchTherapist={subscription?.exceedsswitchTherapist}
                                />
                              )}
                            </StyledLink>
                          </li>
                        </>
                      ) : null}

                      {item.subNav.map((subItem) => {
                        if (profile && profile['B2B'] && subItem.id === 1) return null;
                        // if (isPatient && profile && !profile['B2B'] && subItem.url === '/teams') return null;

                        return (
                          <li key={subItem.id}>
                            <StyledLink to={subItem.url} onClick={closeMenu}>
                              {subItem.title}
                            </StyledLink>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </li>
              );
            })}
            <li>
              <FaqSidebar>
                <Faq />
              </FaqSidebar>
            </li>
            <li>
              <StyledLinkButton onClick={openModal}>{t('signOut')}</StyledLinkButton>
            </li>
          </ul>
        </MenuWrapper>
      </Menu>
    </div>
  );
};

const MenuWrapper = styled.div`
  text-align: left;
`;
const Lang = styled.div`
  position: absolute;
  top: 160px;
`;

const FaqSidebar = styled.div`
  button {
    outline: none;
  }
  #faq-button {
    text-align: left;
    font-weight: 400;
    display: block;
    font-size: 17px;
    color: #491f69;
    margin-bottom: 32px;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
  }
`;

const NavStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '18px',
    top: '0',
    zIndex: 999,
  },
  bmCrossButton: {
    width: '18px',
    top: '26px',
    right: '16px',
  },

  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
    right: 0,
  },
  bmMenu: {
    background: '#ffffff',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#491F69',
    padding: '64px 32px 32px',
  },
  bmItem: {
    display: 'block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

const StyledLink = styled(Link)`
  display: block;
  font-size: 17px;
  color: ${({ theme }) => theme.primary};
  margin-bottom: ${({ theme }) => theme.spacingLg};
  text-decoration: none;
`;
StyledLink.displayName = 'StyledLink';

const StyledLinkButton = styled.div`
  display: block;
  font-size: 17px;
  color: ${({ theme }) => theme.primary};
  margin-bottom: ${({ theme }) => theme.spacingLg};
  text-decoration: none;
  cursor: pointer;
`;

const StyledHeading = styled.h3`
  margin-top: -40px;
  margin-bottom: 40px;
`;
StyledHeading.displayName = 'StyledHeading';

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing} 0;

  button {
    margin: 0 ${({ theme }) => theme.spacing};
  }
`;

const mapStateToProps = (store) => ({
  chatRoomID: store.chatroom?.chatRoomID,
  subscription: store.user.subscription,
  rooms: store.chatroom?.rooms,
});

export default connect(mapStateToProps)(PatientMenu);

import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import { getJournals } from '../../Actions/chatroom.actions';

import { Heading } from '../../Components/Atoms';
import { DashboardLayout } from '../../Layout';
import Sidebar from './Sidebar';

const JournalList = memo(({ journals }) => {
  const { chatRoomId } = useParams();
  const dispatch = useDispatch();

  const goToPage = (page) => dispatch(getJournals(page));

  const tableRows = useMemo(
    () =>
      journals && journals.data
        ? journals.data.map((journal) => {
            const date = moment(journal.created_at).format('YYYY/MM/DD');
            return (
              <tr key={journal.journal_id}>
                <td>{date}</td>
                <td width="100%">
                  <Link to={`/t/journal/${chatRoomId}/${journal.journal_id}`}>{journal.title}</Link>
                </td>
              </tr>
            );
          })
        : [],
    [journals, chatRoomId],
  );

  const getPages = () => {
    if (journals) {
      const {
        pagination: { total_journals, page_no },
      } = journals;

      const numberOfPages = parseInt((total_journals / 10).toFixed(0));
      const pagesComponent = [];

      for (let page = 1; page <= numberOfPages; page++) {
        pagesComponent.push(
          <PageNumber key={`page-${page}`} activePage={page === parseInt(page_no)} onClick={() => goToPage(page)}>
            {page}
          </PageNumber>,
        );
      }

      if (pagesComponent.length > 1) return pagesComponent;

      return null;
    }

    return null;
  };

  return (
    <>
      <Header>
        <Heading text="Journal Entries" />
      </Header>
      <table>
        <tbody>{tableRows}</tbody>
      </table>
      <br />
      <br />
      <TableActionsWrapper>
        <div>{getPages()}</div>
      </TableActionsWrapper>
    </>
  );
});

const JournalEntry = memo((props) => (
  <>
    <Heading text={props.currentEntry.title} />
    <div dangerouslySetInnerHTML={{ __html: props.currentEntry.content }} />
  </>
));

const JournalNotFound = memo(() => (
  <>
    <Heading text="No entry found" />
    <p>The Journal entry you are looking for cannot be found.</p>
    <p>
      <Link to="/journal">View all Journal entries</Link>
    </p>
  </>
));

const Journal = ({ journals }) => {
  let currentEntry = {};

  const dispatch = useDispatch();
  const { chatRoomId, journalId } = useParams();

  useEffect(() => {
    dispatch(getJournals(1, chatRoomId));
  }, [dispatch, chatRoomId]);

  journals &&
    journals.data &&
    journals.data.forEach((entry) => {
      if (entry.journal_id === parseInt(journalId)) {
        currentEntry = entry;
      }
      return false;
    });

  return (
    <DashboardLayout hideSidebar>
      <Wrapper>
        <Sidebar />
        <Window>
          {journalId === undefined ? (
            <JournalList journals={journals} />
          ) : currentEntry.journal_id ? (
            <JournalEntry currentEntry={currentEntry} />
          ) : (
            <JournalNotFound />
          )}
        </Window>
      </Wrapper>
    </DashboardLayout>
  );
};

/* --------------------------------- Styles --------------------------------- */
const Wrapper = styled.div`
  padding: 0 20px;
  ${({ theme }) => theme.md`
    padding: 0 50px;
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: ${({ theme }) => theme.spacing};
  `}
`;

const Window = styled.div``;
const Header = styled.div`
  ${({ theme }) => theme.md`
  display: flex;
  align-items: center;
  justify-content: space-between;
`}
`;

const TableActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PageNumber = styled.span`
  margin: 0 5px;
  color: ${({ activePage, theme }) => (activePage ? theme.secondaryMedium : 'black')};
  font-weight: ${({ activePage }) => activePage && '600'};

  cursor: pointer;
`;

const mapStateToProps = (store) => ({
  journals: store.chatroom.journals,
});

Journal.propTypes = {
  journals: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        journal_id: PropTypes.number,
        title: PropTypes.string,
        content: PropTypes.string,
        created_at: PropTypes.string,
      }),
    ),
  }),
};

export default connect(mapStateToProps)(Journal);

import React, { forwardRef, useState, useCallback, memo, useMemo } from 'react';
import styled from 'styled-components';
import { ReactComponent as VdoIcon } from '../../Assets/Icons/Video.svg';
import VideoRecorderWrapper from './VideoRecorderWrapper';

const VdoRecording = forwardRef(({ chatRoomID, pageLimit }, ref) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <DictateButton>
      <VdoIcon
        onClick={() => {
          setOpenModal(true);
        }}
      />
      {openModal ? (
        <VideoRecorderWrapper setOpenModal={setOpenModal} chatRoomID={chatRoomID} pageLimit={pageLimit} />
      ) : null}
    </DictateButton>
  );
});

const DictateButton = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 11px 0px;
  svg {
    fill: ${({ theme }) => theme.primary};
  }
`;

export default memo(VdoRecording);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TherapistQualificationsForm } from '../../../Components/Organisms';
import { Alert } from '../../../Components/Atoms';

const Qualifications = ({ profile }) => {
  const [initialValues, setInitialValues] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    setInitialValues({
      insruance_certificate: profile.insruance_certificate,
      certification: profile.certification,
      govt_identity: profile.govt_identity,
      work_with_children: !!profile.work_with_children,
      children_certificate: profile.children_certificate,
      certification_body: profile.certification_body,
      license_no: profile.license_no,
    });
  }, [profile]);

  return (
    <TherapistQualificationsForm initialValues={initialValues} onSubmitDone={setResponseMessage}>
      {responseMessage && <StyledAlert>{responseMessage}</StyledAlert>}
    </TherapistQualificationsForm>
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing};
  grid-column: 1 / 2 span;
`;
StyledAlert.displayName = 'StyledAlert';

Qualifications.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default Qualifications;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';

import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';

import { getActiveChatRooms, getChatResources, getScheduleList } from '../../Actions/chatroom.actions';
import { getProfile, getSubscriptionStatus } from '../../Actions/patient.actions';
import { useTranslation } from 'react-i18next';
import { DefaultLayout } from '../../Layout';
import { Sidebar } from './Components';
import { Loading } from '../../Components/Atoms';
import ChatWindow from '../../Components/Molecules/Chatroom/ChatWindow';
import ModalComponent from '../../Components/Atoms/Modal/Modal';

const Chatroom = ({ chatRoomID, rooms }) => {
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [availability, setAvailability] = useState(null);
  const patientProfile = useSelector((store) => store.user?.profile);
  const isMoodRatingNotSaved = patientProfile?.is_save_today_mood === 0;
  const patientRef = useRef(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const checkForTherapistInterval = useRef(0);

  const setTherapistAssignmentCheckingInterval = useCallback(() => {
    if (checkForTherapistInterval.current === 0) {
      checkForTherapistInterval.current = setInterval(() => {
        const registerDate = patientRef.current?.register_date;
        const now = moment(new Date());
        const momentRegisterDate = moment.utc(registerDate).local();
        const duration = moment.duration(now.diff(momentRegisterDate));
        const minutes = duration.asMinutes();
        if (minutes > 7 && !localStorage.getItem('therapistUnavailableModal')) {
          setModalState(true);
          localStorage.setItem('therapistUnavailableModal', 'true');
        }

        dispatch(getActiveChatRooms()).then((response) => {
          if (response[0]?.chatroom_status === 'approved') {
            dispatch(getProfile());
            dispatch(getChatResources(chatRoomID));
            dispatch(getScheduleList(chatRoomID));

            clearInterval(checkForTherapistInterval.current);
            checkForTherapistInterval.current = 0;
          }
        });
      }, 10000);
    }
  }, [chatRoomID, dispatch, patientRef]);

  useEffect(() => {
    patientRef.current = patientProfile;
  }, [patientProfile]);

  useEffect(() => {
    if (!chatRoomID) {
      dispatch(getActiveChatRooms()).then((response) => {
        if (response.length === 0) {
          history.push('/welcome/questionnaire');
          return;
        }
        if (response[0]?.chatroom_status !== 'approved') {
          setTherapistAssignmentCheckingInterval();
        }
      });
      return;
    }

    dispatch(getChatResources(chatRoomID));
    dispatch(getScheduleList(chatRoomID));
    dispatch(getSubscriptionStatus());
    setLoading(false);
  }, [chatRoomID, dispatch, setTherapistAssignmentCheckingInterval, history]);

  useEffect(() => {
    if (patientProfile?.moodbox_page_visited === 0 && patientProfile['B2B'] && !loading) history.push('/moodbox');
  }, [patientProfile, history, loading]);

  useEffect(() => {
    if (rooms?.length && rooms[0]?.chatroom_status !== 'approved') {
      if (checkForTherapistInterval.current === 0) {
        setTherapistAssignmentCheckingInterval();
      }
    }
  }, [rooms, setTherapistAssignmentCheckingInterval]);

  useEffect(() => {
    return () => {
      if (checkForTherapistInterval.current !== 0) {
        clearInterval(checkForTherapistInterval.current);
      }
    };
  }, []);

  return !loading ? (
    <>
      <DefaultLayout noPadding disableHeader={isMoodRatingNotSaved}>
        <ModalComponent open={modalState}>
          <h3 style={{ textAlign: 'center' }}>
          {t("therapistCurrentlyUnavailable")}
          </h3>
        </ModalComponent>
        <ChatWrapper>
          <div
            style={
              isMoodRatingNotSaved
                ? { pointerEvents: 'none', opacity: 0.4, position: 'relative' }
                : { position: 'relative' }
            }
          >
            <Sidebar chatroomAvailable={!!chatRoomID} setTherapistAvailability={setAvailability} />
          </div>
          <ChatWindow availability={availability} />
        </ChatWrapper>
      </DefaultLayout>
    </>
  ) : (
    <Loading />
  );
};

const mapStateToProps = (store) => ({
  chatRoomID: store.chatroom.chatRoomID,
  rooms: store.chatroom.rooms,
});

/* --------------------------------- Styles --------------------------------- */
const ChatWrapper = styled.section`
  display: grid;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  grid-template-rows: auto 1fr;

  ${({ theme }) => theme.md`
    grid-template-columns: 320px 1fr;
    grid-template-rows: initial;
  `}
`;

export default connect(mapStateToProps)(Chatroom);

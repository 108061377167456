import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';

import { useSelector } from 'react-redux';

import MenuIcon from '../../../Assets/Icons/Menu.svg';
import MenuIconWhite from '../../../Assets/Icons/MenuWhite.svg';
import CloseIcon from '../../../Assets/Icons/CloseWhite.svg';
import { ReactComponent as IconAnalytics } from '../../../Assets/Icons/Analytics.svg';
import { ReactComponent as IconChat } from '../../../Assets/Icons/Chat.svg';
import { ReactComponent as IconJournal } from '../../../Assets/Icons/Journal.svg';
import { ReactComponent as IconCalendar } from '../../../Assets/Icons/Calendar.svg';
import { ReactComponent as IconMood } from '../../../Assets/Icons/Mood.svg';
import { ReactComponent as IconTracker } from '../../../Assets/Icons/Tracker.svg';
import { ReactComponent as PlayIcon } from '../../../Assets/Icons/Play.svg';
import { ReactComponent as NotificationIcon } from '../../../Assets/Icons/Notification.svg';
import { ReactComponent as IconPlay } from '../../../Assets/Icons/Play.svg';
import TrainingVideo from '../../Organisms/TrainingVideo';
import { SidebarNavigateHelper } from '..';
import { useTranslation } from 'react-i18next';

const links = [
  { icon: IconChat, path: '/chatroom', title: 'chatroom' },
  { icon: IconTracker, path: '/therapytracker', title: 'therapyTracker' },
  { icon: IconAnalytics, path: '/analytics', title: 'wellbeingMeter' },
  { icon: PlayIcon, path: '/well-being', title: 'resourceCentre' },
  { icon: IconJournal, path: '/journal', title: 'journal' },
  { icon: IconMood, path: '/moodbox', title: 'moodBox' },
  { icon: IconCalendar, path: '/calendar', title: 'videoAppointments' },
  { icon: IconJournal, path: '/additional-support', title: 'additionalSupport' },
  // { icon: IconJournal, path: '/employee-benefits', title: 'employeeBenefits' },
  // { icon: IconJournal, path: '/discounts', title: 'discounts' },
  { icon: NotificationIcon, path: '/account/notifications', title: 'notifications' },
];

const Nav = ({ showWhiteIcon }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const profile = useSelector((store) => store.user.profile);
  const isB2b = useSelector((store) => store.user?.profile?.['B2B'] !== 0);
  const role = useSelector((store) => store.auth.role);
  const [showVideo, setShowVideoFlag] = useState(false);
  const subscription = useSelector((store) => store.user.subscription) || {};
  const { remaining_video_calls = 0 } = subscription;
  const showVideoCallIcon = remaining_video_calls > 0;

  const handleStateChange = ({ isOpen }) => setIsOpen(isOpen);
  const closeMenu = () => setIsOpen(false);

  return (
    <Menu
      isOpen={isOpen}
      onStateChange={handleStateChange}
      customBurgerIcon={<img src={showWhiteIcon ? MenuIconWhite : MenuIcon} alt="" />}
      customCrossIcon={<img src={CloseIcon} alt="" />}
      styles={NavStyles}
      pageWrapId={'mainContent'}
      outerContainerId={'siteWrapper'}
      disableAutoFocus
    >
      <StyledHeading style={{ color: '#fff' }}>{t('yourTools')}</StyledHeading>
      {links.map((link) => {
        if (link.path === '/moodbox' && profile?.moodbox_page_visited) return null;
        return (
          <SidebarNavigateHelper
            link={link}
            key={link.path}
            closeMenu={closeMenu}
            showVideoCallIcon={showVideoCallIcon}
            isB2b={isB2b}
          />
        );
      })}
      {role === 'patient' && isB2b && (
        <StyledLink
          key="trainingVideo"
          onClick={(e) => {
            closeMenu();
            setShowVideoFlag(!showVideo);
          }}
        >
          <StyledIcon>
            <IconPlay />
          </StyledIcon>
          <span>{t('trainingVideo')}</span>
          {showVideo && <TrainingVideo showVideo={showVideo} setShowVideoFlag={setShowVideoFlag} />}
        </StyledLink>
      )}
    </Menu>
  );
};

const NavStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '18px',
    top: '0',
  },
  bmCrossButton: {
    width: '18px',
    top: '26px',
    right: '16px',
  },

  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
    left: 0,
  },
  bmMenu: {
    background: '#513666',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#ffffff',
    padding: '64px 32px 32px',
  },
  bmItem: {
    display: 'block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

const StyledLink = styled(Link)`
  display: flex !important;
  align-items: center;
  font-size: 17px;
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.white};
  margin-bottom: 25px;
  text-decoration: none;
`;
StyledLink.displayName = 'StyledLink';

const StyledHeading = styled.h3`
  margin-top: -40px;
  margin-bottom: 40px;
`;
StyledHeading.displayName = 'StyledHeading';

const StyledIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primaryLight};
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 100%;
  margin-right: ${({ theme }) => theme.spacing};

  svg {
    fill: ${({ theme }) => theme.primary};
    height: 20px;
    width: 20px;
  }
`;
StyledIcon.displayName = 'StyledIcon';

export default Nav;

const initialState = {
    isPartner: ''
};

export default function isPartner(state = initialState, action) {
    switch (action.type) {
        case 'VERIFY_PARTNER_URL':
            return {
                ...state,
                isPartner: action.payload
            }
        default:
            return state;
    }
}
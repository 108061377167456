import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { cancelAppointments } from '../../Actions/chatroom.actions';
import { makeAppointment } from '../../Actions/patient.actions';

import { Button, ChevronIcon, TextBlock, Logo, Avatar, Tooltip } from '../../Components/Atoms';
import { MainSectionWrapper, BottomSectionWrapper, ModalOverlay, Modal } from '../Components/';

import { WelcomeLayout } from '../../Layout';
import notify from '../../Util/notify';

import ScheduleCalendar from './ScheduleCalendar';
import AddToCalendar from 'react-add-to-calendar';

const Schedule = () => {
  const { t } = useTranslation();
  const [slotSuccess, setSlotSuccess] = useState(false);
  const [finished, setFinished] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const patientData = useSelector((store) => store?.user?.profile);
  const therapistInfo = useSelector((store) => store.user.therapistProfile);

  const history = useHistory();
  const dispatch = useDispatch();

  const closeModals = () => {
    setFinished(false);
    setSlotSuccess(false);
    history.push('/chatroom');
  };

  const makeAppointmentAction = () => {
    if (selectedSlot && selectedSlot?.status !== 'booked') {
      setIsLoading(true);
      const { chatroom_id, schedule_id } = selectedSlot;
      dispatch(makeAppointment({ chatroom_id, slot_id: schedule_id, appointment_type: 'message' }))
        .then(() => {
          setSlotSuccess(true);
          setIsLoading(false);
        })
        .catch((err) => {
          notify(t("schedulingFail"), err.response?.data?.message, 'error');
          setIsLoading(false);
        });
    } else setSlotSuccess(true);
  };

  const cancelAppointment = (chatRoomId, appointmentId) => dispatch(cancelAppointments(chatRoomId, appointmentId));

  const formattedTime = () => {
    return moment(selectedSlot?.schedule_time).format('a') === 'am'
      ? moment(selectedSlot?.schedule_time).format('MMMM Do YYYY, hh:mm A')
      : moment(selectedSlot?.schedule_time).format('MMMM Do YYYY, HH:mm');
  };

  const goToChatroom = () => history.push('/');

  useEffect(() => {
    localStorage.setItem('showTutorialVideo', 'true');
  }, []);

  return (
    <WelcomeLayout noPadding noTopBar>
      <section>
        <MainSectionWrapper>
          <Logo />
          <TextBlock type={'primary'} style={{ marginBottom: 0 }}>
            {`${t("schedule1")} ${therapistInfo?.full_name}`}
          </TextBlock>
          <TextBlock type={'secondary'} style={{ width: '100%' }}>
            <ScheduleCalendar selectSlot={setSelectedSlot} cancelAppointment={cancelAppointment} />
          </TextBlock>
          <ActionButtonWrapper>
            <Tooltip
              id="no-schedules"
              place="top"
              text={t("schedule2")}
            >
              <Button onClick={goToChatroom} style={{ fontWeight: 600, color: 'white', marginBottom: '40px' }}>
              {t("skip")}
              </Button>
            </Tooltip>

            <Button
              variant="secondary"
              style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
              onClick={makeAppointmentAction}
              disabled={isLoading || !selectedSlot}
              loading={isLoading}
            >
              {t("confirmSlot")}
              <ChevronIcon direction="right" width="13" height="13" />
            </Button>
          </ActionButtonWrapper>
        </MainSectionWrapper>
      </section>
      <section>
        <BottomSectionWrapper />
      </section>
      <section>
        {slotSuccess && (
          <ModalOverlay>
            <Modal>
              <FlexRow>
                <CloseButton onClick={() => setFinished(true)}>&times;</CloseButton>
              </FlexRow>
              <TextBlock type={'primary'}>
              {t("schedule3")}
              </TextBlock>
              <TextBlock type={'secondary'}>{t("schedule4")}</TextBlock>
              <TherapistImage>
                <BlendColor />
                <Avatar
                  src={therapistInfo?.profile_image || ''}
                  height="80"
                  width="80"
                  className="secondary-border"
                  style={{ border: '1px solid #33c8a0' }}
                />
              </TherapistImage>
              <TextBlock type={'emphasized'} style={{ fontSize: 20 }}>
                {formattedTime()} {t("with")} {therapistInfo?.full_name}
              </TextBlock>
              <TextBlock type={'secondary'}>{t("schedule5")}</TextBlock>
              <ButtonWrapper>
                <AddToCalendar
                  event={{
                    title: `${t("therapyEventith")} ${therapistInfo?.full_name}`,
                    description: `${t("therapyAppointment")} ${therapistInfo?.full_name}`,
                    location: '',
                    startTime: moment(selectedSlot.schedule_time, 'YYYY-MM-DD HH:mm:ss').format(),
                    endTime: moment(selectedSlot.schedule_end_time, 'YYYY-MM-DD HH:mm:ss').format(),
                  }}
                  buttonLabel={t("addToMyCalendar")}
                />
                {/*<Button*/}
                {/*  variant="secondary"*/}
                {/*  style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '10px' }}*/}
                {/*>*/}
                {/*  Add to Google Calendar <ChevronIcon direction="right" width="13" height="13" />*/}
                {/*</Button>*/}
                {/*<Button*/}
                {/*  variant="secondary"*/}
                {/*  style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '10px' }}*/}
                {/*>*/}
                {/*  Add to iCal <ChevronIcon direction="right" width="13" height="13" />*/}
                {/*</Button>*/}
              </ButtonWrapper>
              <BottomSectionWrapper>
                <Button
                  variant="secondary"
                  style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
                  onClick={() => setFinished(true)}
                >
                  {t("noThanks")} <ChevronIcon direction="right" width="13" height="13" />
                </Button>
              </BottomSectionWrapper>
            </Modal>
          </ModalOverlay>
        )}
        {finished && (
          <ModalOverlay>
            <Modal>
              <FlexRow>
                <CloseButton onClick={closeModals}>&times;</CloseButton>
              </FlexRow>
              <TextBlock type="primary">
              {t("modalText1")}{' '}
                <span
                  style={{ textTransform: 'capitalize' }}
                >{`${patientData.first_name} ${patientData.last_name}`}</span>
              </TextBlock>
              <TextBlock type="secondary">
                {`${t("thatsAllWeNeedUntilYouBeginYourChat")} ${therapistInfo?.full_name}.`}{' '}
              </TextBlock>
              <TextBlock type="secondary">
              {t("modalText2")}
                {/* <Link to={`/therapytracker`} style={{ textDecorationColor: '#33c8a0' }}>
                <span style={{ color: '#33c8a0', textDecoration: 'none', fontWeight: 'bold' }}>Therapy Tracker</span>
              </Link>{' '} */}{' '}
                {/* <Link to={`/journal`} style={{ textDecorationColor: '#33c8a0' }}>
                <span style={{ color: '#33c8a0', textDecoration: 'none', fontWeight: 'bold' }}>Journal{' '}</span>
              </Link> */}
              </TextBlock>
              <BottomSectionWrapper>
                <Button
                  variant="secondary"
                  style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
                  onClick={closeModals}
                >
                  {t("goToChatroom")} <ChevronIcon direction="right" width="13" height="13" />
                </Button>
              </BottomSectionWrapper>
            </Modal>
          </ModalOverlay>
        )}
      </section>
    </WelcomeLayout>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .react-add-to-calendar {
    position: relative;
    height: 50px;
  }

  .react-add-to-calendar__button {
    background: rgb(51, 200, 160);
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 15px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    padding: 8px 16px;
    -webkit-text-decoration: none;
    text-decoration: none;
    min-width: 100px;
    color: white;
    line-height: 1;
    white-space: pre;
    vertical-align: middle;
    cursor: pointer;
  }

  .react-add-to-calendar__dropdown {
    position: absolute;
    background: white;
    padding: 20px;
    top: -270px;
    box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
    border-radius: 5px;
    width: 200px;
    left: 50%;
    transform: translateX(-50%);

    li {
      border: 1px solid #bbbbbb;
      padding: 5px 0;
      margin-bottom: 10px;
      border-radius: 5px;

      a {
        text-decoration: none;
        color: #404040;
      }
    }
  }
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row-reverse;
`;

const TherapistImage = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  left: 50%;
  transform: translate(-50%, 0);
`;
const BlendColor = styled.div`
  background: #bbc8f4;
  height: 130px;
  width: 140px;
  left: 0;
  border-radius: 50%;
  mix-blend-mode: color;
  position: absolute;
  top: 0;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  z-index: 99;
  ${'' /* width: 300px; */}
  gap: 20px;
  justify-content: space-between;
`;

export default memo(Schedule);

import React, { useState } from 'react';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { Button } from '../../../Components/Atoms';
import { useDispatch, useSelector } from 'react-redux';
import { closeMoodBoxThxMessage, getProfile, saveMood } from '../../../Actions/patient.actions';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

export const MoodsArray = [
  { id: 0, label: 'devil', emoji: '😈' },
  { id: 1, label: 'crying', emoji: '😢' },
  { id: 2, label: 'angry', emoji: '😡' },
  { id: 3, label: 'neutral', emoji: '😐' },
  { id: 4, label: 'happy', emoji: '🙂' },
];

const MoodChecker = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    currentMood: '',
    showThanksMessage: false,
  });

  const profile = useSelector((store) => store.user?.profile);
  const role = useSelector((store) => store.auth?.role);
  const subscription = useSelector((store) => store.user?.subscription);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (mood) => {
    if (state.currentMood?.label === mood.label) {
      setState({ ...state, currentMood: null });
    } else {
      setState({ ...state, currentMood: mood });
    }
  };

  const handleSubmit = () => {
    if (state.currentMood) {
      dispatch(saveMood(state.currentMood.id + 1)).then(() => {
        setState({ ...state, showThanksMessage: true });
        dispatch(getProfile());
      });
    }
  };

  const closePopUp = () => {
    dispatch(closeMoodBoxThxMessage());
  };

  const goToMoodBox = () => {
    dispatch(closeMoodBoxThxMessage());
    history.push('/moodbox');
  };

  if (role === 'therapist') return null;

  if (profile && profile['B2B'] === 0 && (!subscription || subscription.length === 0)) return null;

  if (profile?.showThxMood && profile && !profile['B2B'])
    return (
      <Wrapper>
        <h3>{t("thankYou")}</h3>
        <p>{t("moodCheckerPara")}</p>
        <Button className="secondary" onClick={goToMoodBox}>
        {t("viewMoodbox")}
        </Button>
        <Button style={{ marginLeft: 10 }} onClick={closePopUp}>
        {t("close")}
        </Button>
      </Wrapper>
    );

  if (!profile?.is_save_today_mood)
    return (
      <Wrapper>
        <h3>{t("moodRating")}</h3>
        <MoodsWrapper>
          {MoodsArray.map((mood) => (
            <MoodButton
              key={mood.id}
              onClick={() => handleClick(mood)}
              className={state.currentMood?.label === mood.label ? 'active' : ''}
            >
              {mood.emoji}
            </MoodButton>
          ))}
        </MoodsWrapper>
        <Button disabled={!state.currentMood} onClick={handleSubmit}>
        {t("submit")}
        </Button>
      </Wrapper>
    );

  return null;
};

const Wrapper = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spacingXl} auto;
  padding: 10px;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.primaryAlpha20};
  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  text-align: center;

  ${({ theme }) => theme.sm`
      width: 100%;
      padding: ${({ theme }) => theme.spacingLg};
   `}
`;

const MoodsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing};
`;

const MoodButton = styled.button`
  font-size: 27px;
  border: 1px solid transparent;
  border-radius: 10px;
  background: transparent;
  padding: 4px 8px;
  cursor: pointer;

  ${({ theme }) => theme.sm`
      padding: 4px ${({ theme }) => theme.spacing};
   `}

  &.active {
    background-color: ${({ theme }) => theme.primaryAlpha10};
    border: 1px solid ${({ theme }) => theme.primaryAlpha20};
  }
`;

export default MoodChecker;

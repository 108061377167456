import React, { useState, useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getActiveChatRooms } from '../../Actions/chatroom.actions';
import { ModalComponent } from '../../Components/Atoms';
import { api } from '../../Util';
import { useTranslation } from 'react-i18next';
import { store } from 'react-notifications-component';

function Teams() {
  const { t } = useTranslation();
  const [loginLink, setLoginLink] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const codeExists = window.location.pathname.includes('teams') && window.location.search.indexOf('code=') > -1;
  const role = JSON.parse(localStorage.loginInfo).role;
  const getNotificationProps = useCallback(
    (message, type) => ({
      title: type === 'danger' ? t("error1") : t("wonderful"),
      message: (
        <div>
          <p>{message}</p>
        </div>
      ),
      type,
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 8000,
        onScreen: true,
      },
    }),
    [],
  );
  const getTeamsToken = useCallback(
    (createTeamsChatFunc) => {
      const code = window.location.search.slice(window.location.search.indexOf('code=') + 5);
      api.teams
        .teamsToken(code)
        .then((data) => {
          if (data.data.status === 'success') {
            localStorage.setItem('teamsTokenData', JSON.stringify({ ...(data?.data || {}) }));
            if (role === 'patient') {
              createTeamsChatFunc();
            } else {
              history.push('/chatroom');
              setTimeout(() => {
                store.addNotification(
                  getNotificationProps(t("therapistRegistrationSuccessful"), 'success'),
                );
              }, 2000);
            }
          } else {
            store.addNotification(
              getNotificationProps(t("userNotRegistered"), 'danger'),
            );
          }
        })
        .catch((error) => {
          store.addNotification(getNotificationProps(error?.message || t("errorOccured"), 'danger'));
        });
    },
    [getNotificationProps, role, history],
  );

  const createTeamsChatFunc = useCallback(
    () =>
      dispatch(getActiveChatRooms(role))
        .then((chatroomData) => {
          api.teams
            .createTeamsChat(chatroomData[0].chatroom_id)
            .then((data) => {
              if (data.data.status === 'success') {
                history.push('/chatroom');
                setTimeout(() => {
                  store.addNotification(getNotificationProps(data?.data?.message, 'success'));
                }, 2000);
              } else {
                if (
                  (data?.data?.message || '').toLowerCase() === t("patientMustBeAuthenticatedWithTeams") ||
                  (data?.data?.message || '').toLowerCase() === t("therapistMustBeAuthenticatedWithTeams")
                ) {
                  getTeamsToken(createTeamsChatFunc);
                } else {
                  store.addNotification(getNotificationProps(data?.data?.message, 'danger'));
                }
              }
            })
            .catch((error) => {
              store.addNotification(getNotificationProps(error?.message || t("errorOccured"), 'danger'));
            });
        })
        .catch((error) => {
          store.addNotification(getNotificationProps(error?.message || t("errorOccured"), 'danger'));
        }),
    [dispatch, getNotificationProps, getTeamsToken, history, role],
  );

  useEffect(() => {
    if (codeExists) {
      role === 'patient' ? createTeamsChatFunc() : getTeamsToken();
    }
  }, [codeExists, history, dispatch, role, getNotificationProps, getTeamsToken, createTeamsChatFunc]);

  useEffect(() => {
    api.teams
      .getAuthCode()
      .then((data) => {
        setLoginLink(data?.data.url);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    loginLink && (
      <ModalComponent
        open
        closeModal={() => {
          history.push('/chatroom');
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a style={{ textAlign: 'center' }} href={loginLink}>
          {t("loginToTeams")}
          </a>
        </div>
      </ModalComponent>
    )
  );
}

export default Teams;

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import api from '../../Util/api';

import { FormGroup, Alert } from '../../Components/Atoms';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import VectorDownIcon from '../../Assets/SignUp/vectorDown.svg';

import { useDispatch } from 'react-redux';
import { toggleSocialInformationRequest, updateEmail, updateProfile } from '../../Actions/patient.actions';

const SocialSignupAdditionalInformation = ({ userType, employeeData, socialData }) => {
    const isEmployee = userType === 'employee';
    const [countriesList, setCountriesList] = useState([]);
    const [responseMessage, setResponseMessage] = useState({ type: '', message: null });
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const getCountriesList = () => {
        api.others
            .getCountriesList()
            .then((response) => {
                if (response.status === 200 && response.data?.data?.length) {
                    setCountriesList(response.data.data);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleOnSubmit = async ({ first_name, last_name, mobile, country, email }, { resetForm, setSubmitting }) => {
        setLoading(true);

        try {
            await dispatch(updateProfile({ mobile, country_id: country, terms_and_conditions: 1 }));
            if (!socialData.email) {
                await dispatch(updateEmail({ email }));
                store.addNotification({
                    title: t("wonderful"),
                    message: t("emailAddressVerification"),
                    type: 'success',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true,
                    },
                });
            }
            resetForm();
            dispatch(toggleSocialInformationRequest());
            history.push('/chatroom');
        } catch (err) {
            const errors = err.response.data.errors || (err.response.data.detail && { detail: [err.response.data.detail] });
            setResponseMessage({
                type: 'danger',
                message: Object.keys(errors).map((key) => (
                    <>
                        <span key={key}>{Object.values(errors[key]).join(' ')}</span>
                        <br />
                    </>
                )),
            });
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };

    const validationSchema = useMemo(() => {
        const validations = {
            mobile: Yup.string().required(t("mobileIsRequired")),
            country: Yup.string().required(t("countryIsRequired")),
        };

        if (!isEmployee) {
            validations.accept_terms = Yup.array().min(1, t("selectCheckboxText"));
        } else {
            validations.department_id = Yup.number().required(t("departmentIsRequired"));
        }

        if (!socialData.email) validations.email = Yup.string().required(t("mobileIsRequired"));

        return Yup.object().shape(validations);
    }, [isEmployee, socialData]);

    const initialValues = useMemo(() => {
        return {
            mobile: '',
            country: '',
            email: '',
        };
    }, []);

    const mobileOnChange = (phone, setFieldValue) => {
        setFieldValue('mobile', phone);
        setPhone(phone);
    };

    useEffect(() => {
        getCountriesList();
    }, []);

    return (
        <Wrapper>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={handleOnSubmit}
            >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        <StyledFormGroup>
                            <FieldWrapper>
                                <label htmlFor="mobile">{t("mobile")}</label>
                                <MobileWrapper>
                                    <PhoneInput
                                        id="mobile"
                                        containerClass={errors.mobile && touched.mobile ? ' is-invalid' : ''}
                                        value={phone}
                                        onChange={(phone) => mobileOnChange(phone, setFieldValue)}
                                        placeholder={t("choseCountryAndMobile")}
                                        enableSearch
                                    />
                                </MobileWrapper>
                                <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                            </FieldWrapper>
                            <FieldWrapper>
                                <label htmlFor="email">{t("country")}</label>
                                <Field
                                    as="select"
                                    id="country"
                                    name="country"
                                    className={'form-control' + (errors.country && touched.country ? ' is-invalid' : '')}
                                    required
                                >
                                    <option value="" hidden>
                                    {t("unitedKingdom")}
                                    </option>
                                    {countriesList &&
                                        countriesList.map((country) => (
                                            <option key={country.id} value={country.id}>
                                                {country.name}
                                            </option>
                                        ))}
                                </Field>
                                <ErrorMessage name="country" component="div" className="invalid-feedback" />
                            </FieldWrapper>
                        </StyledFormGroup>

                        {!socialData.email && (
                            <StyledFormGroup>
                                <FieldWrapper>
                                    <label htmlFor="last_name">{t("email")}</label>
                                    <Field
                                        id="email"
                                        name="email"
                                        type="text"
                                        className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                                    />
                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                </FieldWrapper>
                            </StyledFormGroup>
                        )}

                        {isEmployee ? (
                            <StyledFormGroup>
                                <FieldWrapper style={{ width: '100%' }}>
                                    <label htmlFor="department_id">{t("department")}</label>
                                    <Field
                                        as="select"
                                        id="department_id"
                                        name="department_id"
                                        onChange={({ target: { value } }) => setFieldValue('department_id', +value)}
                                        className={'form-control' + (errors.department_id && touched.department_id ? ' is-invalid' : '')}
                                    >
                                        <option disabled value="" />
                                        {employeeData?.departments.map(({ id, name }) => (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="department_id" component="div" className="invalid-feedback" />
                                </FieldWrapper>
                            </StyledFormGroup>
                        ) : null}

                        {responseMessage.type ? (
                            <StyledAlert className={responseMessage.type}>{responseMessage.message}</StyledAlert>
                        ) : null}

                        <SubmitButtonWrapper>
                            <div>{t("startYourFreeAssessment")}</div>
                            <SignUpButton type="submit">
                                {loading && (
                                    <LoadingDots>
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                    </LoadingDots>
                                )}
                                <span style={{ opacity: loading ? 0 : 1 }}>{t("update")}</span>
                            </SignUpButton>
                        </SubmitButtonWrapper>
                    </Form>
                )}
            </Formik>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const StyledAlert = styled(Alert)`
  border-color: #d2d2d2 !important;
  background: #ffffff !important;
  display: inline-flex;
  border-radius: 2px;
  color: red;
`;
StyledAlert.displayName = 'StyledAlert';

export const FieldWrapper = styled.div`
  width: 48%;

  input {
    margin-top: 5px;
  }

  select {
    margin-top: 5px;
  }

  label {
    font-size: 16px;
    font-weight: 600;
    color: black;
  }
`;

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: initial;
  justify-content: space-between;

  input,
  select {
    width: 100%;
    height: 40px;
    flex-grow: initial;
    border: 1px solid #c4c4c4;
    border-radius: 0;
    font-size: 16px;

    &:focus {
      border: 2px solid #00c8a0;
    }
  }

  input {
    svg {
      path {
        fill: #797979 !important;
      }
    }
  }

  label {
    span {
      border-radius: 0;
      border-color: #c4c4c4;

      a {
        color: black;
      }
    }

    &:hover {
      .checkmark {
        background: #f6f6f6;
      }
    }
  }

  input:checked ~ .checkmark {
    background-color: #00c8a0;
  }

  select {
    padding: 0 8px;
    background: url(${VectorDownIcon}) no-repeat right;
    -webkit-appearance: none;
    background-position-x: calc(100% - 10px);

    &:required {
      &:invalid {
        color: #797979;
      }
    }
  }

  @media screen and (max-width: 455px) {
    flex-wrap: wrap;
    margin-bottom: 0;

    ${FieldWrapper} {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

export const SubmitButtonWrapper = styled.div`
  width: 244px;
  font-size: 17px;
  font-weight: 600;
  color: black;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  margin: 40px auto 0 auto;
`;

const MobileWrapper = styled.div`
  margin-top: 5px;
  .is-invalid {
    input {
      border-color: #ac1c1c !important;
    }
    .flag-dropdown {
      border-color: #ac1c1c !important;
    }
  }
  .react-tel-input {
    .form-control {
      width: 100%;
      border-radius: 0;
      height: 40px;
    }
  }
  .selected-flag {
    border-radius: 0;
  }
  .flag-dropdown {
    border-radius: 0;
  }
  .country-list {
    padding: 0 10px;

    .search {
      display: flex;
      align-items: center;

      input {
        height: 30px;
        width: calc(100% - 30px);
      }
    }
  }
  li {
    width: 100% !important;
  }

  @media screen and (max-width: 400px) {
    .country-list {
      width: 250px;
    }
  }
`;

export const SignUpButton = styled.button`
  position: relative;
  display: inline-flex;
  background: #00c8a0;
  padding: 17px 65px;

  outline: none;

  justify-content: center;
  align-items: center;

  font-size: 18px;
  color: white;
  font-weight: 600;
  border: none;

  cursor: pointer;

  &:hover {
    background: #00b891;
  }
`;

export const LoadingDots = styled.div`
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 20px;

  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  div {
    position: absolute;
    top: 4px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }
`;

SocialSignupAdditionalInformation.propTypes = {
    userType: PropTypes.string,
    heading: PropTypes.string,
    employeeData: PropTypes.shape({
        companyId: PropTypes.string.isRequired,
        departments: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
        ).isRequired,
    }),
};

SocialSignupAdditionalInformation.defaultProps = {
    userType: 'patient',
    heading: 'Create your account',
    employeeData: null,
};

export default SocialSignupAdditionalInformation;

import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

import {
  getPatientProfile,
  getPatientStats,
  getQuestionnaireResult,
  clearPatientStats,
} from '../../Actions/therapist.actions';
import { getNotes, getFullScheduleList } from '../../Actions/chatroom.actions';

import { Button, Checkbox, Heading, Input, ModalComponent, Widget } from '../../Components/Atoms';
import { DashboardLayout } from '../../Layout';
import JourneyHeader from '../TherapyJourney/JourneyHeader';
import Intake from '../TherapyJourney/Intake';
import TherapistAvailability from '../Availability/therapistAvailability';
import { getToLocalSchedulesUser } from '../../Selectors/schedules.selector';
import { store } from 'react-notifications-component';
import { getSchedules } from '../../Util/helper';

const CHART_LABELS = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const PatientProfile = () => {
  const [mood, setMood] = useState('stress');
  const [chartData, setChartData] = useState({ labels: CHART_LABELS, datasets: [] });
  const [showAllCharts, setShowAllCharts] = useState(false);
  const [canRescheduleCall, setCanRescheduleCall] = useState(null);
  const [confirmBookAppointment, setConfirmBookAppointment] = useState(false);
  const [cannotRescheduleCall, setCannotRescheduleCall] = useState(null);
  const [bookedSlots, setBookedSlots] = useState(null);
  const [bookedAppointmentID, setBookedAppointmentID] = useState(null);
  const { chatRoomId, patientId } = useParams();
  const [schedules, setSchedules] = useState([]);
  const [allowedBookingSchedules, setAllowedBookingSchedules] = useState([]);

  const notes = useSelector((store) => store.chatroom.notes);
  const patientStats = useSelector((store) => store.user.patientStats);
  const questionnaire = useSelector((store) => store.user.questionnaireResult);
  const patientProfile = useSelector((store) => store.user.patientProfile);

  const dispatch = useDispatch();
  const history = useHistory();

  const chartOptions = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            max: 5,
            min: 0,
            stepSize: 1,
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const datasetConfig = useMemo(
    () => ({
      label: '',
      fill: false,
      backgroundColor: 'rgba(75,192,192,1)',
      borderColor: '#9d9d9d',
      borderWidth: 3,
      pointRadius: 7,
      pointHoverRadius: 7,
      pointBorderColor: '#ffffff',
    }),
    [],
  );

  const goToNote = (noteId) => history.push(`/t/note/${chatRoomId}/${noteId}`);

  const setPointColors = (values) => {
    return values?.map((v) => {
      return v >= 0 && v <= 3 ? '#E00000' : v > 3 && v <= 7 ? '#f99033' : v > 7 ? '#18ED2D' : '#9d9d9d';
    });
  };

  const getQuestionData = (item) => {
    return {
      labels: CHART_LABELS,
      datasets: [
        {
          ...datasetConfig,
          pointBackgroundColor: setPointColors(item.stats),
          data: item.stats,
        },
      ],
    };
  };
  const moodOnChange = (e) => {
    const value = e.target.value;

    setMood(value);

    const stats = patientStats[patientStats.findIndex((s) => s.tags === value)]?.stats;

    setChartData({
      labels: CHART_LABELS,
      datasets: [
        {
          ...datasetConfig,
          pointBackgroundColor: setPointColors(stats || patientStats[0]?.stats),
          data: stats || patientStats[0]?.stats,
        },
      ],
    });
  };

  const handleCloseModal = () => setConfirmBookAppointment(false);
  const handleClose_canRescheduleCallModal = () => setCanRescheduleCall(false);
  const handleClose_cannotRescheduleCallModal = () => setCannotRescheduleCall(false);
  const handleCloseBookedSlotsModal = () => setBookedSlots(false);

  useEffect(() => {
    if (!chartData.datasets.length && patientStats) {
      const stats = patientStats[patientStats.findIndex((s) => s.tags === 'Stressed')]?.stats;

      setChartData({
        labels: CHART_LABELS,
        datasets: [
          {
            ...datasetConfig,
            pointBackgroundColor: setPointColors(stats || patientStats[0]?.stats),
            data: stats || patientStats[0]?.stats,
          },
        ],
      });
    }
  }, [patientStats, datasetConfig, chartData]);

  useEffect(() => {
    return () => {
      setChartData({ labels: CHART_LABELS, datasets: [] });
      dispatch(clearPatientStats());
    };
  }, [dispatch]);

  const bookedSlotOnClick = (data) => {
    setBookedAppointmentID(data);
  };

  const getRescheduleAllowedDates = async () => {
    const rescheduleAllowedDates = await getFullScheduleList(chatRoomId);

    let localScheduleList = getSchedules(rescheduleAllowedDates);
    setSchedules(localScheduleList);
  };

  const getUpdateAllowedDates = async () => {
    const updateAllowedDates = await getFullScheduleList(chatRoomId, 0, 13);

    let localScheduleList = getSchedules(updateAllowedDates);
    setAllowedBookingSchedules(localScheduleList);
  };

  useEffect(() => {
    getRescheduleAllowedDates();
    getUpdateAllowedDates();
    // dispatch(getFullScheduleList(chatRoomId));
  }, [dispatch, chatRoomId]);

  useEffect(() => {
    dispatch(getPatientProfile(patientId));
    dispatch(getNotes(chatRoomId));
    dispatch(
      getPatientStats(
        patientId,
        moment().startOf('isoWeek').format('YYYY-MM-DD'),
        moment().endOf('isoWeek').format('YYYY-MM-DD'),
      ),
    );
    dispatch(getQuestionnaireResult(chatRoomId));
  }, [patientId, chatRoomId, dispatch]);

  const onBookSuccess = () => {
    window.location.reload();
  };

  return (
    <DashboardLayout>
      <FlexWrap>
        <Heading>Patient Profile</Heading>
        <FlexWrap>
          <Button
            type="primary"
            style={{ height: '35px' }}
            onClick={() => {
              let isCallBooked = false;
              schedules.map((date) => {
                return date.slots.forEach((item) => {
                  if (item.status === 'booked' && +item?.patient_id === +patientId) {
                    isCallBooked = true;
                  }
                });
              });
              if (isCallBooked) {
                setConfirmBookAppointment(true);
              } else {
                store.addNotification({
                  title: 'No call scheduled',
                  message: (
                    <div>
                      <p>No call has been scheduled by the patient yet. </p>
                    </div>
                  ),
                  type: 'info',
                  container: 'top-right',
                  animationIn: ['animate__animated', 'animate__fadeIn'],
                  animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                    duration: 8000,
                    onScreen: true,
                  },
                });
              }
            }}
          >
            Reschedule Call
          </Button>
          <Button type="primary" style={{ height: '35px' }} as={Link} to={`/t/chatroom/${chatRoomId}`}>
            Go to Chat
          </Button>
        </FlexWrap>
      </FlexWrap>
      <StyledWidget>
        <JourneyHeader patientProfile={patientProfile} />
        <hr />
        <Intake notes={notes} goToNote={goToNote} />
      </StyledWidget>

      <StyledWidget>
        <WidgetHeader>
          <h3>Patient Progress</h3>
          {!showAllCharts && !!patientStats?.length && (
            <Input
              onChange={moodOnChange}
              value={mood}
              as="select"
              id="mood"
              name="mood"
              type="text"
              className={'form-control'}
              style={{ maxWidth: '100%' }}
            >
              {patientStats &&
                patientStats?.map((mood) => (
                  <option key={mood.tag || mood.tags} value={mood.tag || mood.tags}>
                    {(mood.tag || mood.tags).charAt(0).toUpperCase() + (mood.tag || mood.tags).slice(1)}
                  </option>
                ))}
            </Input>
          )}
        </WidgetHeader>
        <Checkbox onChange={() => setShowAllCharts(!showAllCharts)} checked={showAllCharts} label={'Show all'} basic />
        {!showAllCharts ? (
          <ChartWrapper>
            <Line data={chartData} options={chartOptions} />
          </ChartWrapper>
        ) : (
          <ChartList>
            {patientStats &&
              patientStats.map((item) => (
                <div>
                  <Heading level={3}>{item.question || item.tag}</Heading>
                  <Line data={getQuestionData(item)} options={chartOptions} />
                </div>
              ))}
          </ChartList>
        )}
      </StyledWidget>

      <StyledWidget>
        <h3>Questionnaire</h3>
        <ConversationWindow>
          {questionnaire?.map((q, index) => {
            return (
              <div key={`quest-${index}`}>
                <BubbleWrapper className="therapist">
                  {q.question.replace('<br><br>', '').replace('<br> <br>', '').replace('<br>', '')}
                </BubbleWrapper>
                <BubbleWrapper className="patient">
                  {q.answers.map((arrayText, index) => (
                    <span key={`index-answers-${index}`}>
                      {arrayText.map((text, index) => {
                        if (arrayText.length > index + 1) {
                          return `${text}, `;
                        } else if (arrayText.length === index + 1) return text;
                        return text;
                      })}
                    </span>
                  ))}
                </BubbleWrapper>
              </div>
            );
          })}
        </ConversationWindow>
      </StyledWidget>
      <ModalComponent open={canRescheduleCall} closeModal={handleClose_canRescheduleCallModal}>
        <TherapistAvailability
          heading={`${
            bookedAppointmentID?.selectedDateTime
              ? `Reschedule the patient's appointment for ${bookedAppointmentID?.selectedDateTime}`
              : ``
          }`}
          slots={allowedBookingSchedules}
          bookedAppointmentID={bookedAppointmentID?.appointment_id}
          onBookSuccess={onBookSuccess}
        />
      </ModalComponent>
      <ModalComponent open={bookedSlots} closeModal={handleCloseBookedSlotsModal}>
        <Heading level={3}>Choose the call you want to reschedule!</Heading>
        <Heading level={6}>{`Rescheduling is only available between ${moment()
          .subtract(3, 'd')
          .utc()
          .format('YYYY-MM-DD')} to ${moment().add(3, 'd').utc().format('YYYY-MM-DD')} `}</Heading>

        <Wrapper>
          {schedules.map((dates) => {
            return (
              <Wrapper>
                <HoursWrapper>
                  {dates.slots.map((slot) => {
                    let timeSlot = (
                      <div>
                        <SlotWrapper key={slot.schedule_id}>
                          {slot.status === 'booked' && +slot.patient_id === +patientId && (
                            <Slot
                              bookedAppointmentID={slot.appointment_id === bookedAppointmentID?.appointment_id}
                              isVideo={slot.appointment_type === 'video'}
                              name={slot.schedule_id}
                              onClick={() => {
                                bookedSlotOnClick({
                                  ...slot,
                                  selectedDateTime: `${moment(dates.schedule_date).format('Do MMM')} -
                                    ${moment(slot.schedule_time).format('HH:mm')}`,
                                });
                              }}
                            >
                              {moment(dates.schedule_date).format('Do MMM')} -{' '}
                              {moment(slot.schedule_time).format('HH:mm')}
                            </Slot>
                          )}
                        </SlotWrapper>
                      </div>
                    );
                    return timeSlot;
                  })}
                </HoursWrapper>
              </Wrapper>
            );
          })}
        </Wrapper>
        <ModalActions>
          <Button
            onClick={() => {
              setCanRescheduleCall(true);
              setBookedSlots(false);
            }}
            disabled={!bookedAppointmentID?.appointment_id}
          >
            Confirm
          </Button>
        </ModalActions>
      </ModalComponent>
      <ModalComponent open={cannotRescheduleCall} closeModal={handleClose_cannotRescheduleCallModal}>
        <Heading level={3}>There are no booked appointments!</Heading>
      </ModalComponent>
      <ModalComponent open={confirmBookAppointment} closeModal={handleCloseModal}>
        <Heading level={3}>Are you sure you want to book the appointment?</Heading>
        <div>
          Please keep in mind the difference in time-zone. It is advised to confirm the appointment time with the
          patient before booking.
        </div>
        <div>Click on the link below to check the time zones!</div>

        <CustomLink href="https://dateful.com/time-zone-converter" target="_blank" rel="noreferrer">
          <div>https://dateful.com/time-zone-converter</div>
        </CustomLink>

        <ModalActions>
          <Button
            onClick={() => {
              // isBooked();
              setConfirmBookAppointment(false);
              setBookedSlots(true);
            }}
          >
            Book Appointment
          </Button>
        </ModalActions>
      </ModalComponent>
    </DashboardLayout>
  );
};

const CustomLink = styled.a`
  color: blue;
  text-decoration: underline;
`;

const Slot = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 130px;
  height: 25px;
  color: white;
  background: #1a5549;
  border: none;

  border-radius: 5px;
  cursor: pointer;
  background: ${({ bookedAppointmentID }) => (bookedAppointmentID ? '#00c8a0' : '#1a5549')};

  // &:focus {
  //   background-color: #00c8a0;
  // }

  &:hover {
    background: #00c8a0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px;
`;

const HoursWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const SlotWrapper = styled.div`
  position: relative;
`;

const ModalActions = styled.div`
  margin-top: ${({ theme }) => theme.spacing};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  gap: ${({ theme }) => theme.spacingSm};
  justify-content: center;

  ${({ theme }) => theme.md`
    justify-content: flex-end;
  `};
`;
ModalActions.displayName = 'ModalActions';

const StyledWidget = styled(Widget)`
  margin-bottom: ${({ theme }) => theme.spacingMd};
`;
const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  select {
    max-width: 200px;
  }
`;

const ChartList = styled.div`
  h3 {
    text-align: center;
  }

  ${({ theme }) => theme.md`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${theme.spacing};
  `};
`;

const ChartWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacingMd};
`;

const FlexWrap = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

const ConversationWindow = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
`;

const BubbleWrapper = styled.div`
  position: relative;
  align-self: flex-start;
  flex-wrap: wrap;
  max-width: 90%;

  font-size: 14px;
  border-radius: 8px;
  margin-top: ${({ theme }) => theme.spacingSm};
  padding: ${({ theme }) => theme.spacingSm};

  ${({ theme }) => theme.md`
    font-size: 15px;
    border-radius: 12px;
    margin-top: ${({ theme }) => theme.spacingMd};
    padding: ${({ theme }) => theme.spacing};
  `}

  &.therapist {
    background-color: ${({ theme }) => theme.primaryLight};
  }
  &.patient {
    background-color: ${({ theme }) => theme.secondaryLight};
  }
  &.followUp {
    margin-top: 3px;
  }
`;

export default PatientProfile;

import axios from 'axios';
import { store } from '../storeNew';
import { unauthorizedLogout } from '../Actions/auth.actions';
import config from '../Config';

// import { genericLanguageMapping, setlanguageCode } from '..';

var host = window?.location?.host?.toLowerCase();

export var isExuslabsPartner = host === 'exuslabs.supportroom.com';
export var isBluehrsolutionsPartner = host === 'bluehrsolutions.supportroom.com';
// export const isBluehrsolutionsPartner = true;

const blueHostLanguages = {
  ar: 'ar',
  en: 'en',
};

const genericLanguageMapping = {
  en: 'english',
  sr: 'serbian',
  sh: 'serbian',
  el: 'greek',
  ar: 'arabic',
};

const setlanguageCode = () => {
  try {
    let lang = localStorage.getItem('lang');
    if (lang) {
      return lang;
    }
    if (isExuslabsPartner) {
      return 'en';
    }
    let urlPath = window.location.pathname.split('/')[1];
    if (Object.keys(genericLanguageMapping).includes(urlPath) && urlPath !== 'en') {
      localStorage.setItem('lang', urlPath);
      return urlPath;
    }
    let browserLang = (navigator.language || navigator.userLanguage)?.slice(0, 2);
    if (genericLanguageMapping[browserLang]) {
      if (isBluehrsolutionsPartner) {
        if (blueHostLanguages[browserLang]) {
          return browserLang;
        } else {
          return 'en';
        }
      }
      return browserLang;
    }
    return 'en';
  } catch (error) {
    return 'en';
  }
};

export const axiosPost = axios.create({
  baseURL: 'https://supportroom.com/wp-json/wp/v2/',
});

export const axiosVideoPost = axios.create({
  baseURL: 'https://supportroom.com/wp-json/v2/',
});

export const publicAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
});

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;
axios.defaults.headers.common.language = genericLanguageMapping[setlanguageCode()];
axios.defaults.headers.common.sourcecompany = config.sourcecompany;

if (localStorage.getItem('loginInfo')) {
  const token = JSON.parse(localStorage.getItem('loginInfo')).access_token;
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
} else {
  axios.defaults.headers.common.Authorization = 'Bearer ';
  axios.defaults.headers.common.Accept = 'application/json';
}

axios.interceptors.request.use(
  (config) => config,
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefresh = (token) => {
  refreshSubscribers.forEach((cb, index) => {
    cb(token);
    if (index + 1 === refreshSubscribers.length && !isRefreshing) refreshSubscribers = [];
  });
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response: { status, data } = { status: 0 } } = error;
    const originalRequest = config;

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
        const refreshToken = loginInfo?.refresh_token;
        axios
          .post('refresh_token', { refresh_token: refreshToken })
          .then(({ data }) => {
            if (data.status === 'error') {
              store.dispatch(unauthorizedLogout());
              return null;
            }

            isRefreshing = false;
            originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
            localStorage.setItem('loginInfo', JSON.stringify({ ...loginInfo, ...data }));

            axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;

            onRefresh(data.access_token);
          })
          .catch(() => {
            store.dispatch(unauthorizedLogout());
            return Promise.reject(error);
          });
      }

      return new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          // replace the expired token and retry
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  },
);

import React, { memo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button, ChevronIcon, TextBlock, Logo, Loading, CustomRadio, Tooltip } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';
import Avatar from '../../Assets/avatar.png';
import { ReactComponent as Previous } from '../../Assets/previous.svg';
import { ReactComponent as Next } from '../../Assets/next.svg';
import { BottomSectionWrapper, MainSectionWrapper } from '../Components/';
import { api, useDeviceType } from '../../Util/';
import { getActiveChatRooms } from '../../Actions/chatroom.actions';
import EmergencyBtn from '../../Components/Atoms/EmergencyBtn';
import { useTranslation } from 'react-i18next';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ReactResizeDetector from 'react-resize-detector';

const Therapists = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [supportType, setSupportType] = useState('');
  const [allSupportType, setAllSupportType] = useState([]);
  const [therapists, setTherapists] = useState([]);
  const [apiStatus, setApiStatus] = useState('');
  const [therapistId, setTherapistId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isProfessionalTypeLoading, setIsProfessionalTypeLoading] = useState(true);
  const [subTypeId, setSubTypeId] = useState();
  const [subType, setSubType] = useState(false);
  const rooms = useSelector((store) => store.chatroom?.rooms);
  const dispatch = useDispatch();
  const isMobile = useDeviceType();

  const selectTherapist = (e) => {
    setTherapistId(parseInt(e.target.value, 10));
  };

  const assignTherapist = async () => {
    try {
      setIsLoading(true);
      const result = await api.welcome.createChatroom(therapistId);
      if (result.data.status === 'success') {
        history.push('chatroom');
        // history.push(`/welcome/schedule?therapist_id=${therapistId}&chatroom_id=${result.data.data.id}`);
      } else if (result.data.status === 'error') {
        setIsLoading(false);
        store.addNotification({
          title: t('failedToChooseTherapist'),
          message: (
            <div>
              <p>{result.data.message}</p>
            </div>
          ),
          type: 'danger',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 8000,
            onScreen: true,
          },
        });

        if (rooms.length > 0) {
          history.push('chatroom');

          // history.push(`/welcome/schedule?therapist_id=${therapistId}&chatroom_id=${rooms[0]?.chatroom_id}`);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      store.addNotification({
        title: t('failedToChooseTherapist'),
        message: (
          <div>
            <p>{t('failedToChooseTherapistTryAgain')}</p>
          </div>
        ),
        type: 'danger',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 8000,
          onScreen: true,
        },
      });
    }
  };

  useEffect(() => {
    setIsProfessionalTypeLoading(true);
    api.patient
      .getProfessionalTypes()
      .then((res) => {
        if (res.data.status === 'success') {
          setAllSupportType(res.data.data);
        }
        setIsProfessionalTypeLoading(false);
      })
      .catch(() => setIsProfessionalTypeLoading(false));
  }, []);

  useEffect(() => {
    if (!subTypeId) return;
    setIsProfessionalTypeLoading(true);
    api.patient
      .getProfessionalType(subTypeId)
      .then((res) => {
        if (res.data.status === 'success') {
          setAllSupportType(res.data.data);
        }
        setIsProfessionalTypeLoading(false);
      })
      .catch(() => setIsProfessionalTypeLoading(false));
  }, [subTypeId]);

  // therapist_type
  useEffect(() => {
    if (!supportType) return;
    setIsLoading(true);
    api.patient
      .matchedTherapists(supportType)
      .then((res) => {
        if (res.data.status === 'success') {
          setTherapists(res.data.data);
          setApiStatus('success');
          setSubType(false);
        }
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [supportType]);

  useEffect(() => {
    dispatch(getActiveChatRooms()).then((res) => {
      // if (res?.length > 0 && res[0].therapist_id) history.push('/chatroom');
    });
  }, [dispatch, history]);

  useEffect(() => {
    if (therapists.length === 1) {
      setTherapistId(therapists[0].therapist_id);
    }
  }, [therapists]);

  const viewTherapistProfile = (therapistId, ooh) =>
    history.push(`/welcome/therapist-profile/${therapistId}?ooh=${ooh}`);
  const isBootsPartner = window.location.host === 'boots.supportroom.com';

  const showSubTypePage = (data, id) => {
    setSubType(data);
    setSubTypeId(id);
  };

  if (!apiStatus && subType === false) {
    return (
      <WelcomeLayout noPadding noTopBar>
        <section>
          {supportType && !apiStatus ? <Loading /> : null}

          <MainSectionWrapper isMobile={isMobile}>
            {isProfessionalTypeLoading && <Loading />}
            <Logo height={isMobile ? 75 : 100} width={isMobile ? 75 : 100} />
            <QuestionWrapper style={{ marginTop: 20 }}>
              <Question isMobile={isMobile}>{t('kindOfSupport')}</Question>
              {/* {quizdata.data?.headline && <Headline isMobile={isMobile}>{quizdata.data?.headline}</Headline>} */}
              {/* <OptionNote isMobile={isMobile}>You can change this later</OptionNote> */}
              {!isProfessionalTypeLoading && (
                <AnswerWrapper>
                  {allSupportType?.map(({ name, id, sub_type }) => {
                    return (
                      <AnswerOption
                        className={`answerOption ${supportType === name ? 'active' : ''}`}
                        onClick={() => (sub_type?.length ? showSubTypePage(true, id) : setSupportType(id))}
                      >
                        {name}
                      </AnswerOption>
                    );
                  })}
                </AnswerWrapper>
              )}
              {!isProfessionalTypeLoading && !allSupportType?.length && (
                <div>
                  {t('no')} {t('professional')} {t('available')}
                </div>
              )}
            </QuestionWrapper>
          </MainSectionWrapper>
        </section>
      </WelcomeLayout>
    );
  }

  if (subType) {
    return (
      <WelcomeLayout noPadding noTopBar>
        <section>
          {supportType && !apiStatus ? <Loading /> : null}

          <MainSectionWrapper isMobile={isMobile}>
            {isProfessionalTypeLoading && <Loading />}
            <Logo height={isMobile ? 75 : 100} width={isMobile ? 75 : 100} />
            <QuestionWrapper style={{ marginTop: 20 }}>
              <Question isMobile={isMobile}>{t('kindOfSubSupport')}</Question>
              {/* {quizdata.data?.headline && <Headline isMobile={isMobile}>{quizdata.data?.headline}</Headline>} */}
              {/* <OptionNote isMobile={isMobile}>You can change this later</OptionNote> */}
              {!isProfessionalTypeLoading && (
                <AnswerWrapper>
                  {allSupportType[0]?.sub_type?.map(({ name, id }) => (
                    <AnswerOption
                      className={`answerOption ${supportType === name ? 'active' : ''}`}
                      onClick={() => setSupportType(id)}
                    >
                      {name}
                    </AnswerOption>
                  ))}
                </AnswerWrapper>
              )}
              {!isProfessionalTypeLoading && !allSupportType?.length && (
                <div>
                  {t('no')} {t('professional')} {t('available')}
                </div>
              )}
            </QuestionWrapper>
          </MainSectionWrapper>
        </section>
      </WelcomeLayout>
    );
  }
  return (
    <ReactResizeDetector handleHeight={true} handleWidth={true} refreshMode="throttle" refreshRate={200}>
      {({ height, width, targetRef }) => {
        return (
          <div style={{ height: '100%' }} ref={targetRef}>
            <WelcomeLayout noPadding noTopBar>
              <section>
                <MainSectionWrapper isMobile={isMobile}>
                  {isLoading && <Loading />}
                  <Logo height={isMobile ? 75 : 100} width={isMobile ? 75 : 100} />
                  <TextBlock type={'primary'}>
                    {isBootsPartner ? t('whomToChat') : t('professionalWillSupport')}
                  </TextBlock>

                  <TherapistsWrapper
                    style={
                      window.screen.width <= 767
                        ? { maxWidth: `${window.screen.width - 32}px`, overflowX: 'auto' }
                        : !(therapists.length > 6 && !isMobile)
                        ? { overflowX: 'auto', maxWidth: `${window.screen.width - 200}px` }
                        : { width: '80%' }
                    }
                  >
                    {therapists.length > 6 && !isMobile ? (
                      <CarouselProvider
                        naturalSlideWidth={80}
                        naturalSlideHeight={135}
                        style={{ position: 'relative' }}
                        totalSlides={therapists.length}
                        visibleSlides={window.screen.width < 1170 ? 4 : 6}
                      >
                        <Slider>
                          {therapists.map((therapist, index) => (
                            <Slide index={index}>
                              <TherapistWrapper key={therapist.therapist_id}>
                                <TherapistImage>
                                  <BlendColor />
                                  <BeforeLoadTemplate />
                                  <StyledImage
                                    width="100%"
                                    height="100%"
                                    src={therapist.therapist_profile || Avatar}
                                    alt="therapist"
                                    effect="blur"
                                  />
                                </TherapistImage>
                                <TherapistDetails>
                                  <Tooltip id="profileIcon" text={t('viewProfile')} place="bottom">
                                    <TherapistName
                                      onClick={() =>
                                        viewTherapistProfile(therapist.therapist_id, therapist.out_of_hours)
                                      }
                                    >
                                      {therapist.first_name}
                                    </TherapistName>
                                  </Tooltip>
                                  <div>
                                    {therapist.out_of_hours ? (
                                      <div>
                                        <HoursText>{t('outOfHours')}</HoursText>
                                        <Hours>{t('5To10')}</Hours>
                                      </div>
                                    ) : (
                                      <div>
                                        <HoursText>{t('peakTimes')}</HoursText>
                                        <Hours>{t('9To5')}</Hours>
                                      </div>
                                    )}
                                  </div>
                                  <CheckboxWrapper>
                                    <CustomRadio
                                      value={therapist.therapist_id}
                                      id={therapist.therapist_id}
                                      checked={therapist.therapist_id === therapistId}
                                      name="therapist"
                                      onChange={selectTherapist}
                                      variant="cyan"
                                    />
                                  </CheckboxWrapper>
                                </TherapistDetails>
                              </TherapistWrapper>
                            </Slide>
                          ))}
                        </Slider>

                        <BackButton>
                          <Icon as={Previous} />
                        </BackButton>
                        <NextButton>
                          <Icon as={Next} />
                        </NextButton>
                      </CarouselProvider>
                    ) : (
                      <React.Fragment>
                        {therapists.map((therapist, index) => (
                          <TherapistWrapper key={therapist.therapist_id}>
                            <TherapistImage>
                              <BlendColor />
                              <BeforeLoadTemplate />
                              <StyledImage
                                width="100%"
                                height="100%"
                                src={therapist.therapist_profile || Avatar}
                                alt="therapist"
                                effect="blur"
                              />
                            </TherapistImage>
                            <TherapistDetails>
                              <Tooltip id="profileIcon" text={t('viewProfile')} place="bottom">
                                <TherapistName
                                  onClick={() => viewTherapistProfile(therapist.therapist_id, therapist.out_of_hours)}
                                >
                                  {therapist.first_name}
                                </TherapistName>
                              </Tooltip>
                              <div>
                                {therapist.out_of_hours ? (
                                  <div>
                                    <HoursText>{t('outOfHours')}</HoursText>
                                    <Hours>{t('5To10')}</Hours>
                                  </div>
                                ) : (
                                  <div>
                                    <HoursText>{t('peakTimes')}</HoursText>
                                    <Hours>{t('9To5')}</Hours>
                                  </div>
                                )}
                              </div>
                              <CheckboxWrapper>
                                <CustomRadio
                                  value={therapist.therapist_id}
                                  id={therapist.therapist_id}
                                  checked={therapist.therapist_id === therapistId}
                                  name="therapist"
                                  onChange={selectTherapist}
                                  variant="cyan"
                                />
                              </CheckboxWrapper>
                            </TherapistDetails>
                          </TherapistWrapper>
                        ))}
                      </React.Fragment>
                    )}

                    {!isLoading && therapists.length === 0 && (
                      <div>
                        {t('no')} {allSupportType?.find((type) => type?.id === supportType)?.name || t('Professional')}{' '}
                        {t('available')}
                      </div>
                    )}
                  </TherapistsWrapper>
                  {!!therapists.length && (
                    <TextBlock type={'secondary'}>
                      {isBootsPartner ? t('bootsCondition1') : t('bootsCondition2')}
                      <TextBlock type={'emphasized'}>
                        {isBootsPartner ? t('bootsCondition3') : t('bootsCondition4')}
                      </TextBlock>
                    </TextBlock>
                  )}
                  <ButtonWrapper isMobile={isMobile}>
                    {therapistId ? (
                      <Button
                        variant="secondary"
                        style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
                        // disabled={!therapistId}
                        onClick={() => assignTherapist()}
                      >
                        {t('next')}
                        <ChevronIcon direction="right" width="13" height="13" />
                      </Button>
                    ) : (
                      // <Tooltip
                      //   id="therapist"
                      //   text={`${t('tooltipText1')} ${supportType.charAt(0).toLowerCase() + supportType.slice(1)} ${t(
                      //     'tooltipText2',
                      //   )}`}
                      //   place="top"
                      // >
                      <Button
                        variant="secondary"
                        style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
                        onClick={() => therapistId && assignTherapist()}
                      >
                        {t('next')}
                        <ChevronIcon direction="right" width="13" height="13" />
                      </Button>
                      // </Tooltip>
                    )}
                  </ButtonWrapper>
                </MainSectionWrapper>
              </section>
              <section>
                <BottomSectionWrapper>
                  <EmergencyBtn />
                </BottomSectionWrapper>
              </section>
            </WelcomeLayout>
          </div>
        );
      }}
    </ReactResizeDetector>
  );
};

const AnswerOption = styled.div`
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  font-size: 14px;
  padding: 5px 8px;
  background-color: #c9fff1;
  color: ${({ theme }) => theme.greyDark};
  border: 2px solid transparent;
  font-family: 'Montserrat', sans-serif;
  ${({ disable }) =>
    disable &&
    css`
      background-color: ${({ theme }) => theme.greyMedium};
      pointer-events: none;
    `}

  ${({ theme }) => theme.md`
    font-size: 15px;
  `}

  &.active {
    color: ${({ theme }) => theme.white};
    background-color: #33c8a0;
  }
`;

const AnswerWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  margin-top: 20px;
`;

const Icon = styled.svg`
  font-weight: 600;
`;

const BackButton = styled(ButtonBack)`
  position: absolute;
  top: 55px;
  left: -120px;
  background-color: transparent;
  padding: 15px 0px 15px 10px;
  border: none;
`;

const NextButton = styled(ButtonNext)`
  position: absolute;
  top: 50px;
  right: -120px;
  background-color: transparent;
  border: none;
  padding: 15px 5px 15px 5px;
`;

const OptionNote = styled.div`
  color: ${({ theme }) => theme.cyan};
  margin-bottom: 20px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  font-size: ${({ isMobile }) => (isMobile ? '15px' : '16px')};
`;

const QuestionWrapper = styled.div`
  max-width: 850px;
`;
const Question = styled.div`
  font-size: ${({ isMobile }) => (isMobile ? '20px' : 'x-large')};
  color: #491f69;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
`;

const HoursText = styled.div`
  font-weight: 600;
  font-size: 13px;
  color: #363636;
  white-space: nowrap;
`;
const Hours = styled.div`
  font-weight: 600;
  font-size: 11px;
  color: #6f6f6f;
  line-height: 8px;
`;

const TherapistsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-bottom: 10px;

  .carousel__inner-slide {
    padding-top: 20px;
`;

const TherapistWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TherapistImage = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 125px;
  height: 125px;
  @media (max-width: 640px) {
    width: 100px;
    height: 100px;
  }
`;
const BlendColor = styled.div`
  ${'' /* background: #183b454a; */}
  height: 125px;
  width: 125px;
  border-radius: 50%;
  mix-blend-mode: color;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;
const TherapistDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const BeforeLoadTemplate = styled.div`
  position: absolute;
  border-radius: 100px;
  box-shadow: 0 1px 2px rgb(0, 0, 0, 16%);
  border: 1px solid ${({ theme }) => theme.primary};
  width: 125px;
  height: 125px;
  background-color: #ebebeb;
  @media (max-width: 640px) {
    width: 100px;
    height: 100px;
  }
`;

const StyledImage = styled(LazyLoadImage)`
  border-radius: 100px;
  box-shadow: 0 1px 2px rgb(0, 0, 0, 16%);
  border: 1px solid ${({ theme }) => theme.primary};
  width: 125px;
  height: 125px;
  object-fit: contain;
  @media (max-width: 640px) {
    width: 100px;
    height: 100px;
  }
`;
const TherapistName = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
  // height: 50px;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
`;
const ButtonWrapper = styled.div`
  position: ${({ isMobile }) => (isMobile ? 'absolute' : 'inherit')};
  bottom: 0;
  z-index: 99;
`;
const CheckboxWrapper = styled.div`
  display: flex;
  padding: 20px;
  margin-bottom: 10px;
  justify-content: center;
  margin-left: 10px;
`;

export default memo(Therapists);

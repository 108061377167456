import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { WelcomeLayout } from '../../Layout';
import { Avatar, Heading, Loading } from '../../Components/Atoms';
import CalendarComponent from '../../Components/Atoms/Calendar/Calendar';
import { getPublicTherapistProfile } from '../../Actions/therapist.actions';
import { MainSectionWrapper, BottomSectionWrapper } from '../Components/';
import AvatarImage from '../../Assets/avatar.png';
import { useDeviceType } from '../../Util';
import { useTranslation } from 'react-i18next';
import { getPatientCalendarEvents } from '../../Util/calendar';
import { getToLocalSchedulesChatroom } from '../../Selectors/schedules.selector';

const TherapistProfile = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [availability, setAvailability] = useState([]);
  const dispatch = useDispatch();
  const therapistProfile = useSelector((store) => store.user?.therapistProfile);
  const { userId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const ooh = query.get('ooh');
  const getTherapistProfile = useCallback(() => dispatch(getPublicTherapistProfile(userId)), [dispatch, userId]);
  const isMobile = useDeviceType();
  const scheduleList = useSelector((store) => getToLocalSchedulesChatroom(store));

  useEffect(() => {
    setIsLoading(true);
    if (userId) {
      getTherapistProfile();
    }
  }, [userId, getTherapistProfile]);

  useEffect(() => {
    if (scheduleList && scheduleList.length) {
      const [availability] = getPatientCalendarEvents(scheduleList);
      if (availability.length) setAvailability(availability);
    }
  }, [scheduleList]);

  return (
    <WelcomeLayout noPadding noTopBar>
      <section>
        <MainSectionWrapper>
          {isLoading && !Object.keys(therapistProfile).length ? (
            <Loading />
          ) : (
            <ProfileWrapper>
              <TherapistAvatar>
                <TherapistImage isMobile={isMobile}>
                  <BlendColor isMobile={isMobile} />
                  <Avatar
                    width={isMobile ? 100 : 200}
                    height={isMobile ? 100 : 200}
                    src={therapistProfile?.profile_image || AvatarImage}
                  />
                </TherapistImage>
                <TherapistName>
                  <Heading level={4} text={therapistProfile?.full_name} />
                </TherapistName>
                {ooh === 'true' ? (
                  <div>
                    <HoursText>{t('outOfHours')}</HoursText>
                    <Hours>{t('5To10')}</Hours>
                  </div>
                ) : (
                  <div>
                    <HoursText>{t('peakTimes')}</HoursText>
                    <Hours>{t('9To5')}</Hours>
                  </div>
                )}
              </TherapistAvatar>
              <TherapistDetails>
                {therapistProfile?.exp_summary && (
                  <ProfileSection>
                    <Heading level={4} text={t('experienceSummary')} />
                    <p>{therapistProfile.exp_summary}</p>
                  </ProfileSection>
                )}

                {therapistProfile?.about_me && (
                  <ProfileSection>
                    <Heading level={4} text={t('aboutMe')} />

                    {therapistProfile?.about_me.split('\n').map((item, i) => {
                      return <p key={i}>{item}</p>;
                    })}
                  </ProfileSection>
                )}

                {therapistProfile?.specialisms?.length && (
                  <ProfileSection>
                    <Heading level={4} text={t('therapySpecialisms')} />
                    <ListWrapper>
                      {therapistProfile?.specialisms.map((item) => (
                        <ListItem key={item.id}>{item.specialism_name}</ListItem>
                      ))}
                    </ListWrapper>
                  </ProfileSection>
                )}

                {therapistProfile?.tools && (
                  <ProfileSection>
                    <Heading level={4} text={t('therapyTools')} />
                    <ListWrapper>
                      {therapistProfile.tools.map((item) => (
                        <React.Fragment key={item.id}>
                          {item.tool_name && <ListItem>{item.tool_name}</ListItem>}
                        </React.Fragment>
                      ))}
                    </ListWrapper>
                  </ProfileSection>
                )}
                <ProfileSection>
                  <Heading level={4} text={t('availability')} />
                  <CalendarComponent
                    events={availability}
                    type="availability"
                    selectable={false}
                    style={{ height: '700px'}}
                    defaultView="week"
                    views={false}
                    showMultiDayTimes={true}
                  />
                </ProfileSection>
              </TherapistDetails>
            </ProfileWrapper>
          )}
        </MainSectionWrapper>
        <BottomSectionWrapper />
      </section>
    </WelcomeLayout>
  );
};

const HoursText = styled.div`
  font-weight: 600;
  font-size: 13px;
  color: #363636;
  white-space: nowrap;
`;
const Hours = styled.div`
  font-weight: 600;
  font-size: 11px;
  color: #6f6f6f;
  line-height: 8px;
`;

const ProfileWrapper = styled.section`
  text-align: left;
  width: 75%;
  ${({ theme }) => theme.md`
    display: flex;
    gap:2em;
  `}
`;

const TherapistAvatar = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.2;
`;

const TherapistName = styled.div`
  grid-area: name;
  text-align: center;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.md`
        text-align: left;
    `}
`;
//
// const TherapistActions = styled.div`
//   grid-area: actions;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 70px;
//   a {
//     font-weight: 600;
//     font-size: 16px;
//     color: #fff !important;
//     width: 200px;
//     background: #33c8a0 !important;
//     margin: 12px 0;
//     height: 50px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// `;

const TherapistDetails = styled.div`
  grid-area: details;
  flex: 0.8;
`;
const ProfileSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingMd};
  p {
    color: ${({ theme }) => theme.greyMedium};
    &::first-letter {
      text-transform: capitalize;
    }
  }
`;
const ListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;
const ListItem = styled.li`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacingSm};
  margin-bottom: ${({ theme }) => theme.spacingSm};
  background: ${({ theme }) => theme.cyanAlpha20};
  color: ${({ theme }) => theme.cyan};
  font-weight: normal;
  border-radius: 5em;
  padding: 4px ${({ theme }) => theme.spacing};
`;

const TherapistImage = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: ${({ isMobile }) => (isMobile ? '100px' : '200px')};
  height: ${({ isMobile }) => (isMobile ? '100px' : '200px')};
`;
const BlendColor = styled.div`
  background: #bbc8f4;
  left: 50%;
  border-radius: 50%;
  mix-blend-mode: color;
  position: absolute;
  top: 50%;
  width: ${({ isMobile }) => (isMobile ? '100px' : '200px')};
  height: ${({ isMobile }) => (isMobile ? '100px' : '200px')};
  transform: translate(-50%, -50%);
`;

export default TherapistProfile;

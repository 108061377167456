import React, { memo, useEffect, useState } from "react";
import { socket } from "../../../socket";

const Typing = (props) => {
    const { message } = props;
    const [isTyping, setIsTyping] = useState(false)

    useEffect(() => {
        let id;
        socket.on("typing", (data) => {
            setIsTyping(prev => {
                clearTimeout(id);
                id = setTimeout(() => {
                    setIsTyping(false)
                }, 1500)
                return true;
            });
        });
    }, [])

    return (
        <p
            style={{
                fontSize:12,
                color:'#000',
                padding:0,
                margin:0,
                lineHeight:'12px',
                height:12,
                marginBottom:10
            }}
        >{isTyping ? message : ''}</p>
    )
}
export default memo(Typing);
import {
  Login,
  Logout,
  SignupConfirm,
  // Signup,
  Home,
  Questionnaire,
  Chatroom,
  TherapistProfile,
  Account,
  // Teams,
  MoodBox,
  Analytics,
  JournalNew,
  JournalEdit,
  Journal,
  Contact,
  ReferFriend,
  Checkout,
  CheckoutSuccess,
  CheckoutError,
  TherapistAssignment,
  TherapyTracker,
  Calendar,
  // Help,
  SavedMessages,
  SharedFiles,
  Notifications,
  WellBeing,
  WellBeingPost,
  GymPass,
  AdditionalSupport,
} from '../Patient';
import Discounts from '../Patient/Discounts';
import DiscountCategories from '../Patient/Discounts/categories';
import DiscountCategoriesSection from '../Patient/Discounts/discountCategoriesSection';
import EmployeeBenefits from '../Patient/EmployeeBenefits';

export default [
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  { path: '/gympass', component: GymPass },
  { path: '/signup/complete', component: SignupConfirm },
  { path: '/signup', component: Login },
  { path: '/checkout', component: Checkout, isPrivate: true },
  { path: '/checkout/success', component: CheckoutSuccess, isPrivate: true },
  { path: '/checkout/error', component: CheckoutError, isPrivate: true },
  { path: '/therapist-assignment', component: TherapistAssignment, isPrivate: true },
  { path: '/home', component: Home },
  // { path: '/help', component: Help }, // This route has moved to the welcome route.
  { path: '/questionnaire', component: Questionnaire, isPrivate: true },
  { path: '/chatroom', component: Chatroom, isPrivate: true },
  {
    path: '/therapistprofile',
    component: TherapistProfile,
    isPrivate: true,
  },
  { path: '/account/:id*', component: Account, isPrivate: true },
  { path: '/account', component: Account, isPrivate: true },
  // { path: '/teams', component: Teams, isPrivate: true },
  { path: '/moodbox', component: MoodBox, isPrivate: true },
  { path: '/analytics', component: Analytics, isPrivate: true },
  { path: '/journal/new', component: JournalNew, isPrivate: true },
  { path: '/journal/edit/:id*', component: JournalEdit, isPrivate: true },
  { path: '/journal/:id*', component: Journal, isPrivate: true },
  { path: '/journal', component: Journal, isPrivate: true },
  { path: '/contact', component: Contact, isPrivate: true },
  { path: '/referfriend', component: ReferFriend, isPrivate: true },
  { path: '/therapytracker', component: TherapyTracker, isPrivate: true },
  { path: '/calendar', component: Calendar, isPrivate: true },
  { path: '/saved-messages', component: SavedMessages, isPrivate: true },
  { path: '/shared-files', component: SharedFiles, isPrivate: true },
  { path: '/notifications', component: Notifications, isPrivate: true },
  { path: '/well-being', component: WellBeing, isPrivate: true },
  { path: '/well-being/:id', component: WellBeingPost, isPrivate: true },
  { path: '/additional-support', component: AdditionalSupport, isPrivate: true },
  { path: '/employee-benefits', component: EmployeeBenefits, isPrivate: true },
  { path: '/discounts', component: Discounts, isPrivate: true },
  { path: '/discounts/categories', component: DiscountCategories, isPrivate: true },
  { path: `/discounts/categories/:id`, component: DiscountCategoriesSection, isPrivate: true },
];

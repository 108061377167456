import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { GoogleLogin } from 'react-google-login';
import { store } from 'react-notifications-component';

import { isPartner } from '../../Actions/auth.actions';

import { SignUpButton, SocialIcon } from './signUpComponent';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GoogleIcon } from '../../Assets/SignUp/google.svg';

const GOOGLE_CLIENT_ID = '1021337303053-tnrluksl1836u5q7uuquis5r1g2vrkol.apps.googleusercontent.com';

const GoogleInitComponent = ({ buttonText, initAuth }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDisabled, setDisabled] = useState(false);
  const [isPartnerState, setIsPartnerState] = useState(null);
  const responseGoogle = useCallback(
    (data) => {
      if (
        data?.error === 'idpiframe_initialization_failed' &&
        data?.details === 'Cookies are not enabled in current environment.'
      ) {
        setDisabled(true);
        store.addNotification({
          title: t("GoogleSignupDisabled"),
          message: (
            <div>
              <p>{t("GoogleSignUpDisabledInIncognitoMode")}</p>
            </div>
          ),
          type: 'danger',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 8000,
            onScreen: true,
          },
        });
      }
      return initAuth(data?.profileObj, 'google');
    },
    [initAuth],
  );

  useEffect(() => {
    dispatch(isPartner(window.location.host)).then((res) => {
      if (res.data.status === 'success' && res.data?.data?.is_partner) {
        setIsPartnerState(true);
      } else setIsPartnerState(false);
    });
  }, [dispatch]);

  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      render={({ onClick, disabled }) => (
        <SignUpButton onClick={onClick} disabled={disabled}>
          <SocialIcon as={GoogleIcon} />
          <span>{buttonText}</span>
        </SignUpButton>
      )}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      disabled={isDisabled}
      cookiePolicy={'single_host_origin'}
    />
  );
};

GoogleInitComponent.propTypes = {
  buttonText: PropTypes.string.isRequired,
};

export default memo(GoogleInitComponent);

import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { useWindowSize } from '../../../Hooks';

import {
  getMessages,
  sendMessage,
  setVideoRoomError,
  setVideoRoom,
  destroyVideoRoom,
  readUnreadMessages,
  getUnreadMessages,
  addNewMessages,
} from '../../../Actions/chatroom.actions';
import api from '../../../Util/api';

import Room from './Room';
import Window from './Window';
import UploadFile from './UploadFile';
import RecordAudio from './RecordAudio';
import ChatToolMobilePopup from './chatToolMobilePopup';
import { Button, Input } from '../../Atoms';
import { EmojiPicker } from '../index';
import { getProfile } from '../../../Actions/patient.actions';
import { simplifySchedule } from '../../../Util/helper';
import { eligibleForVideRoomCreation } from './Header';
import { useTranslation } from 'react-i18next';

import VdoRecording from '../../VdoRecording/VdoRecording';
import { isConnected, socket } from '../../../socket';
import _ from 'lodash';
import Typing from './Typing';

const INITIAL_PAGE_LIMIT = 50;
export const MAX_PASSED_TIME_FOR_JOINING = -25;
export const MAX_TIME_BEFORE_JOINING = 5;

const ChatWindow = ({
  chatRoomID,
  messages,
  unreadMessages,
  pagination,
  videoRoomName,
  videoRoomStatus,
  role,
  scheduleList,
  profile,
  rooms,
}) => {
  const [messageText, setMessageText] = useState('');
  const patientProfile = useSelector((store) => store.user?.profile);
  const isMoodRatingNotSaved = patientProfile?.is_save_today_mood === 0;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const viewportWidth = useWindowSize().width;

  const roomStatus = useMemo(() => {
    return rooms && rooms[0]?.chatroom_status;
  }, [rooms]);

  const { secondMemberId, secondMemberName } = useMemo(() => {
    const room = rooms?.find((room) => room.chatroom_id === chatRoomID);
    if (room?.chatroom_id) {
      if (role === 'patient') {
        return {
          secondMemberId: room?.therapist_id,
          secondMemberName: room?.therapist_full_name
        }
      } else {
        return {
          secondMemberId: room?.patient_id,
          secondMemberName: room?.patient_full_name
        }
      }
    }
    return null
  }, [rooms, chatRoomID]);

  const chatroomUnreadMessages = useMemo(() => {
    return unreadMessages?.messages.filter(({ chatroom_id }) => chatroom_id === chatRoomID) || [];
  }, [unreadMessages, chatRoomID]);

  const messageInputRef = useRef(null);

  const checkVideoRoomInterval = useRef(0);
  let videoRoomCheckInterval = useRef(10000);

  const checkEligibleInterval = useRef(0);

  const pageLimit = useRef(INITIAL_PAGE_LIMIT);

  const emojiPickerRef = useRef(null);

  const triggerTypingEvent = () => {
    socket.emit('typing', { roomName: `${chatRoomID}`, userName: `${secondMemberId}` })
  }

  const sendEvent = useCallback(_.throttle(() => triggerTypingEvent(), 700), [secondMemberId])

  const messageTextOnChange = (e) => {
    setMessageText(e.target.value);
    sendEvent()
  }

  const inputEmoji = (emoji) => {
    const caretPosition = messageInputRef.current.selectionStart;
    const isBetween = messageText.length > caretPosition;
    setMessageText((messageText) => {
      if (isBetween) return messageText.slice(0, caretPosition) + emoji.native + messageText.slice(caretPosition);
      else return messageText + emoji.native;
    });
    emojiPickerRef.current.close();
    messageInputRef.current.focus();
  };

  const getMessageHistory = useCallback(() => {
    dispatch(getMessages(chatRoomID, pageLimit.current));
    dispatch(getUnreadMessages());
  }, [chatRoomID, dispatch]);


  const getMoreMessages = useCallback(() => {
    const { messages, total_messages } = pagination;
    if (messages < total_messages) {
      const newPageLimit = pageLimit.current + INITIAL_PAGE_LIMIT;
      pageLimit.current = newPageLimit;
      return dispatch(getMessages(chatRoomID, newPageLimit));
    }

    return Promise.resolve(null);
  }, [chatRoomID, pageLimit, pagination, dispatch]);

  const handleSubmitMessage = (event) => {
    event.preventDefault();
    if (messageText) {
      const message = {
        id: new Date().toISOString(),
        message: messageText,
        read_status: 0,
        sent_at: new Date().toISOString(),
        sent_by: profile?.first_name,
        sent_by_role: role,
        type: "text"
      }
      socket.emit('newMessage', { message: message, roomName: `${chatRoomID}`, userName: `${secondMemberId}` });
      dispatch(addNewMessages(message))
      dispatch(sendMessage(chatRoomID, messageText)).then((res) => {
        if (res?.status === 'error' && res?.translation_id === 405) {
          history.push('/account/subscription/manage');
        }
        // else dispatch(getMessages(chatRoomID, pageLimit.current));
        if (res?.status === 'success' && !profile.first_message && role === 'patient') dispatch(getProfile());
      });
      setMessageText('');
    }
  };

  const onSendFile = () => {
    const message = {
      type: "file"
    }
    socket.emit('newMessage', { message: message, roomName: `${chatRoomID}`, userName: `${secondMemberId}` });
  };

  const checkVideoRoomStatus = useCallback(
    (id) => {
      id &&
        api
          .videoRoomRequest(`/rooms/SR-${id}`, 'GET')
          .then(function (response) {
            dispatch(setVideoRoom(response.data));
          })
          .catch(function (error) {
            if (error.response?.data?.error === 'not-found') {
              if (role === 'therapist') {
                return dispatch(destroyVideoRoom(chatRoomID));
              }
              dispatch(setVideoRoomError(error.response.data));
            }
          });
    },
    [role, dispatch],
  );

  // Checking each 10s for video room availability that was created by Therapist
  const setIntervalForVideoRoom = useCallback(() => {
    if (role === 'patient' && !videoRoomName) {
      if (checkVideoRoomInterval.current) clearInterval(checkVideoRoomInterval.current);
      checkVideoRoomInterval.current = setInterval(() => {
        const isEligible = eligibleForVideRoomCreation(scheduleList, profile, profile?.id);
        if (!isEligible) return;
        checkVideoRoomStatus(chatRoomID);
      }, videoRoomCheckInterval.current);
    }
  }, [role, chatRoomID, videoRoomName, checkVideoRoomStatus, scheduleList?.length]);

  useEffect(() => {
    let checkRoomActive = setInterval(() => {
      checkVideoRoomStatus(chatRoomID);
    }, 60 * 1000);
    return () => {
      clearInterval(checkRoomActive);
    };
  }, [chatRoomID, videoRoomStatus]);

  /* -------------------------------- Page Load ------------------------------- */

  // Interval Creation for checking Patient videoRoom availability.
  useEffect(() => {
    if (role === 'patient') {
      checkVideoRoomStatus(chatRoomID);
      setIntervalForVideoRoom();
    }
  }, [role, chatRoomID, setIntervalForVideoRoom, checkVideoRoomStatus]);

  useEffect(() => {
    getMessageHistory();

    api.videoRoomRequest('/rooms', 'GET');
  }, [getMessageHistory]);


  // Read the chatroom unread messages if there are any
  useEffect(() => {
    if (chatroomUnreadMessages.length) {
      dispatch(readUnreadMessages(chatroomUnreadMessages.map(({ message_id }) => message_id)));
    }
  }, [dispatch, chatroomUnreadMessages]);

  // Component didUnmount actions
  useEffect(() => {
    return () => {
      if (role === 'therapist') {
        clearInterval(checkEligibleInterval.current);
      }
      if (role === 'patient') {
        if (checkVideoRoomInterval.current) clearInterval(checkVideoRoomInterval.current);
      }
    };
  }, [chatRoomID, role, videoRoomStatus, dispatch]);

  useEffect(() => {
    if (chatRoomID && isConnected && secondMemberId) {
      socket.emit('joinRoom', { roomName: `${chatRoomID}`, userName: `${secondMemberId}` });
      socket.on("newMessage", (data) => {
        if (data?.message?.type === 'file') {
          getMessageHistory()
        } else {
          dispatch(addNewMessages(data?.message))
        }
      });
    }
    return () => {
      socket.removeAllListeners();
    }
  }, [chatRoomID, isConnected, secondMemberId])

  return (
    <ChatWindowStyled>
      {videoRoomStatus ? (
        <Room name={videoRoomName} />
      ) : (
        <>
          <Window
            ChatData={messages}
            messageLength={messages?.length}
            roomStatus={roomStatus}
            getMoreMessages={getMoreMessages}
            pagination={pagination}
          />
          <div
            style={
              isMoodRatingNotSaved
                ? { pointerEvents: 'none', opacity: 0.5, backgroundColor: '#fff' }
                : window.screen.width > 767
                  ? { backgroundColor: '#fff' }
                  : { position: 'fixed', bottom: 0, margin: '0 16px', right: 0, left: 0, backgroundColor: '#fff' }
            }
          >
            <ChatForm
              disabled={!(rooms?.length && rooms[0]?.chatroom_status === 'approved')}
              onSubmit={handleSubmitMessage}
            >
              {viewportWidth < 450 && <ChatToolMobilePopup chatRoomID={chatRoomID} pageLimit={pageLimit.current} />}
              {viewportWidth > 450 && <UploadFile chatRoomID={chatRoomID} pageLimit={pageLimit.current} onSendFile={onSendFile} />}
              <MessageWrapper>
                <Input
                  type="text"
                  placeholder={t('message')}
                  value={messageText}
                  onChange={messageTextOnChange}
                  ref={messageInputRef}
                  rows={1}
                />
                <EmojiPicker
                  onSelect={inputEmoji}
                  style={{ position: 'absolute', top: '10px', right: '8px' }}
                  ref={emojiPickerRef}
                />
              </MessageWrapper>
              <ChatFormRightSide>
                {viewportWidth > 450 && (
                  <RecordAudio
                    style={{ marginTop: '4px', width: '30px' }}
                    chatRoomID={chatRoomID}
                    pageLimit={pageLimit.current}
                  />
                )}
                {viewportWidth > 450 && <VdoRecording chatRoomID={chatRoomID} pageLimit={pageLimit.current} />}
                <Button
                  disabled={!messageText}
                  variant={`${viewportWidth < 450 ? 'small' : ''} primary`}
                  type={'submit'}
                >
                  {t('send')}
                </Button>
              </ChatFormRightSide>
              <div></div>
              <Typing
                  message={`${secondMemberName} is typing...`}
              />
            </ChatForm>
          </div>
        </>
      )}
    </ChatWindowStyled>
  );
};

const mapStateToProps = (store) => ({
  messages: store.chatroom?.messages?.data,
  unreadMessages: store.chatroom?.unreadMessages,
  pagination: store.chatroom?.messages?.pagination,
  chatRoomID: store.chatroom.chatRoomID,
  videoRoomName: store.chatroom.videoRoom.name,
  videoRoomStatus: store.chatroom.videoRoom.active,
  role: store.auth?.role,
  scheduleList: simplifySchedule(store.user?.scheduleList),
  profile: store.user?.profile,
  subscription: store.user?.subscription,
  rooms: store.chatroom?.rooms,
});

const ChatWindowStyled = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
`;

const ChatForm = styled.form`
  margin-top: ${({ theme }) => theme.spacing};
  border-top: 1px solid ${({ theme }) => theme.primaryMedium};
  padding-bottom:0px !important;
  display: grid;
  grid-template-columns: auto 1fr auto;

  ${({ theme }) => theme.sm`
      padding: ${({ theme }) => theme.spacingSm} 0;
      .upload-file-component {
        svg {
          width: 40px;
        }
      }
   `}

  ${({ theme }) => theme.md`
      padding: ${({ theme }) => theme.spacing};
   `}

  svg {
    width: 30px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const ChatFormRightSide = styled.div`
  display: flex;
  align-items: stretch;
  margin-left: ${({ theme }) => theme.spacingSm};
`;

const InputWrapper = styled.textarea`
  resize: none;
  min-height: 0px;
  padding: 8px 40px 8px 8px;
`;

const ModalActions = styled.div`
  margin-top: ${({ theme }) => theme.spacing};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  gap: ${({ theme }) => theme.spacingSm};
  justify-content: center;
  text-align: center;

  ${({ theme }) => theme.md`
    justify-content: flex-end;
  `};
`;
ModalActions.displayName = 'ModalActions';

const MessageWrapper = styled.div`
  position: relative;
`;

export default memo(connect(mapStateToProps)(ChatWindow));

import React, { useCallback, useEffect, useState } from 'react';
import { store } from 'react-notifications-component';

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [err, setError] = useState(null);

  const startRecording = () => {
    setRecorder(null);
    setIsRecording(true);
  };

  const stopRecording = useCallback(() => {
    setIsRecording(false);
    recorder &&
      recorder.stream &&
      recorder.stream.getAudioTracks().forEach(function (track) {
        track.stop();
      });
  }, [recorder]);

  const deleteFile = () => {
    setAudioFile(null);
    setAudioURL('');
  };

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, (err) => {
          if (err) {
            setError(err);
            stopRecording();
            store.addNotification({
              title: 'Permission for recording failed!',
              message: (
                <div>
                  <p>{err}</p>
                </div>
              ),
              type: 'danger',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 9000,
                onScreen: true,
              },
            });
          }
        });
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = (e) => {
      setAudioURL(URL.createObjectURL(e.data));
      const blob = new Blob([e.data], {
        type: 'audio/mp3',
      });
      setAudioFile(blob);
    };

    recorder.addEventListener('dataavailable', handleData);
    return () => recorder.removeEventListener('dataavailable', handleData);
  }, [recorder, isRecording, stopRecording]);

  return { audioFile, audioURL, isRecording, startRecording, stopRecording, deleteFile, err };
};

async function requestRecorder() {
  try {
    const stream = await navigator?.mediaDevices?.getUserMedia({ audio: true });

    return new MediaRecorder(stream);
  } catch (err) {
    return Promise.reject(err?.message);
  }
}
export default useRecorder;

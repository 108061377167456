import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { SignUpButton, SocialIcon } from './signUpComponent';

import { ReactComponent as FacebookIcon } from '../../Assets/SignUp/facebook.svg';

const FACEBOOK_APP_ID = 173734607757017;

const FacebookInitComponent = ({ buttonText, initAuth }) => {
  const responseFacebook = useCallback((data) => initAuth(data, 'facebook'), [initAuth]);

  return (
    <FacebookLogin
      appId={FACEBOOK_APP_ID}
      callback={responseFacebook}
      fields="name,email,picture"
      render={({ onClick }) => (
        <SignUpButton onClick={onClick}>
          <SocialIcon as={FacebookIcon} />
          <span>{buttonText}</span>
        </SignUpButton>
      )}
    />
  );
};

FacebookInitComponent.propTypes = {
  buttonText: PropTypes.string.isRequired,
};

export default memo(FacebookInitComponent);

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './Assets/Locales/en/translation.json';
import translationSR from './Assets/Locales/sr/translation.json';
// import translationDE from './Assets/Locales/de/translation.json';
import translationAR from './Assets/Locales/ar/translation.json';
import translationEL from './Assets/Locales/el/translation.json';
// import { setlanguageCode } from '.';

const availableLanguages = ['en', 'sr', 'el', 'ar', 'sh'];

const fallbackLng = ['en'];

export const languageMapping = {
  en: ['English', 'Serbian', 'Greek', 'Arabic'],
  sr: ['English', 'srpski', 'grčki', 'arapski'],
  sh: ['English', 'srpski', 'grčki', 'arapski'],
  el: ['English', 'Σέρβος', 'Ελληνικά', 'αραβικός'],
  ar: ['English', 'الصربية', 'اليونانية', 'عربي'],
};

export const resources = {
  en: { translation: translationEN },
  sr: { translation: translationSR },
  el: { translation: translationEL },
  ar: { translation: translationAR },
  sh: { translation: translationSR },
  // de: { translation: translationDE },
};

var host = window?.location?.host?.toLowerCase();

export var isExuslabsPartner = host === 'exuslabs.supportroom.com';
export var isBluehrsolutionsPartner = host === 'bluehrsolutions.supportroom.com';
// export const isBluehrsolutionsPartner = true;
const blueHostLanguages = {
  ar: 'ar',
  en: 'en',
};

const genericLanguageMapping = {
  en: 'english',
  sr: 'serbian',
  sh: 'serbian',
  el: 'greek',
  ar: 'arabic',
};

const setlanguageCode = () => {
  try {
    let lang = localStorage.getItem('lang');
    if (lang) {
      return lang;
    }
    if (isExuslabsPartner) {
      return 'en';
    }
    let urlPath = window.location.pathname.split('/')[1];
    if (Object.keys(genericLanguageMapping).includes(urlPath) && urlPath !== 'en') {
      localStorage.setItem('lang', urlPath);
      return urlPath;
    }
    let browserLang = (navigator.language || navigator.userLanguage)?.slice(0, 2);
    if (genericLanguageMapping[browserLang]) {
      if (isBluehrsolutionsPartner) {
        if (blueHostLanguages[browserLang]) {
          return browserLang;
        } else {
          return 'en';
        }
      }
      return browserLang;
    }
    return 'en';
  } catch (error) {
    return 'en';
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    lng: setlanguageCode() || 'en',
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

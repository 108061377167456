import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { GoogleLogin } from 'react-google-login';
import { store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import { SignUpButton, SocialIcon, IconWrapper, ButtonTextWrapper } from './signUpButton';

import { ReactComponent as GoogleIcon } from '../../Assets/SignUp/google.svg';

const GOOGLE_CLIENT_ID = '1021337303053-tnrluksl1836u5q7uuquis5r1g2vrkol.apps.googleusercontent.com';

const GoogleInitComponent = ({ buttonText, initAuth, isEnterprise }) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false)
  const responseGoogle = useCallback(
    (data) => {
      if (data?.error === 'idpiframe_initialization_failed' && !isEnterprise && data?.details !== 'Cookies are not enabled in current environment.') {
        setIsDisabled(true)
        store.addNotification({
          title: t("googleSignupDisabled"),
          message: (
            <div>
              <p>{t("notAuthorisedToUseGoogleLogin")}</p>
            </div>
          ),
          type: 'danger',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 8000,
            onScreen: true,
          },
        });
      }

      return initAuth(data?.profileObj, 'google');
    },
    [initAuth, isEnterprise],
  );

  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      render={({ onClick }) => (
        <SignUpButton onClick={onClick}>
          <IconWrapper><SocialIcon as={GoogleIcon} /></IconWrapper>
          <ButtonTextWrapper>{buttonText}</ButtonTextWrapper>
        </SignUpButton>
      )}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      disabled={isDisabled}
      cookiePolicy={'single_host_origin'}
    />
  );
};

GoogleInitComponent.propTypes = {
  buttonText: PropTypes.string.isRequired,
};

export default memo(GoogleInitComponent);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from '../../../Assets/avatar.png';

const StyledImage = styled.img`
  object-fit: cover;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 100%;
  box-shadow: 0 0 20px rgba(73, 31, 105, 0.1);
`;

const Avatar = (props) => {
  const { src, alt, className, width } = props;

  return <StyledImage src={src} alt={alt} width={width} height={width} className={className} />;
};

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
Avatar.defaultProps = {
  src: Image,
  alt: '',
  className: '',
  width: 90,
};

export default Avatar;

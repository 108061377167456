import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Radio, ChevronIcon } from '../../Atoms';
import { store } from 'react-notifications-component';
import { useDeviceType } from '../../../Util';
import { useTranslation } from 'react-i18next';

const isBootsPartner = window.location.host === 'boots.supportroom.com';
const isPcPartner = window.location.host === 'pc.supportroom.com';

const packageFeatures = (t) => ({
  standard: [
    t('UnlimitedMessagesVoiceNotes'),
    isBootsPartner ? t('ongoingSupportFromTherapist') : t('unlimitedClientTherapyJourney'),
    t('clientWellbeingTracker'),
    t('journal'),
    t('wellbeingAnalyticsTracker'),
    t('wellbeingResourceCentre'),
  ],
  'mid-tier': [
    t('UnlimitedMessagesVoiceNotes'),
    'Video sessions',
    isBootsPartner ? t('ongoingSupportFromTherapist') : t('unlimitedClientTherapyJourney'),
    t('clientWellbeingTracker'),
    t('journal'),
    t('wellbeingAnalyticsTracker'),
    t('wellbeingResourceCentre'),
  ],
  premium: [
    t('UnlimitedMessagesVoiceNotes'),
    'Video sessions',
    isBootsPartner ? t('ongoingSupportFromTherapist') : t('unlimitedClientTherapyJourney'),
    t('clientWellbeingTracker'),
    t('journal'),
    t('wellbeingAnalyticsTracker'),
    t('wellbeingResourceCentre'),
  ],
});

// {isPartner && <ListItem>Support</ListItem>}

const NewPaymentPlan = ({
  category,
  subscription,
  onSubmit,
  onSelectPlan,
  selectedPlan,
  expandAll,
  setExpandAll,
  onSubmitVoucher,
  currency,
  isPartner,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [voucherCode, setVoucherCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlanName, setSelectedPlanName] = useState('');
  const [morInfo, setMorInfo] = useState(false);
  const isMobile = useDeviceType();
  const handleSelectVariant = useCallback(
    (planId, name) => {
      setSelectedVariant(+planId);
      onSelectPlan({ name, planId });
      setSelectedPlanName(name);
    },
    [onSelectPlan],
  );

  const isPartnerBoots = isPartner && window.location.host === 'boots.supportroom.com';

  const handleContinue = () => {
    if (subscription.cancelled && subscription.plan_status === 'active') {
      showActiveSubscription();
    } else {
      let selectedPlanId;
      if (selectedVariant && selectedPlan?.plan_name === category?.package_name) {
        selectedPlanId = selectedVariant;
      } else if (category.package_plans.length === 5) {
        const weekIndex = category.package_plans.findIndex((plan) => plan.plan_interval === 'week');
        selectedPlanId = category.package_plans[weekIndex]?.plan_id;
      } else selectedPlanId = category?.package_plans[0].plan_id;
      setIsLoading(true);
      onSubmit(selectedPlanId)
        .then(() => {
          setIsLoading(false);
          setSelectedVariant(null);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const submitVoucher = () => {
    if (subscription.cancelled && subscription.plan_status === 'active') {
      showActiveSubscription();
    } else {
      if (voucherCode.trim().length) {
        onSubmitVoucher(voucherCode);
      }
    }
  };

  const showActiveSubscription = () => {
    store.addNotification({
      title: t('subscriptionFailed'),
      message: t('isActiveSubscription'),
      type: 'warning',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };

  /**
  To get the price and show in agaist the currency
  As default we are showing the weekly price
  */
  const getPrice = (plans = []) => {
    if (plans.length) {
      const weekPlan = plans.filter((plan) => plan.plan_interval === 'week');
      if (weekPlan?.length > 0) return weekPlan[0].price;
      return plans[0].price;
    }
    return '00.00';
  };

  const getDisplayPrice = (selectedPlan) => {
    const package_plans = category?.package_plans?.find((plan) => plan?.plan_id === selectedPlan?.planId);
    return package_plans?.price;
  };

  const displayPrice = getDisplayPrice(selectedPlan) || getPrice(category?.package_plans);

  const moreInfoOnClick = () => {
    setMorInfo((state) => !state);
  };

  return (
    <>
      {category.plan !== 'businessPlan' ? (
        <PlanContainer>
          <FirstRow>
            <PlanFirstCol>
              <PlanDetailsWrapper>
                <PlanTitle>{category.package_type}</PlanTitle>
                <PlanDescription>
                  {!morInfo ? (
                    <span
                      onClick={() => moreInfoOnClick()}
                      style={{ textDecoration: 'underline', fontSize: '12px', cursor: 'pointer' }}
                    >
                      {t('moreInfo')}
                    </span>
                  ) : category.package_type === 'standard' ? (
                    <span>{t('unlimitedMessaging')}</span>
                  ) : category.package_type === 'premium' ? (
                    <span>{isPartnerBoots ? t('4VideoSessPerMonth') : t('1VideoSessPerWeek')}</span>
                  ) : category.package_type === 'mid-tier' ? (
                    <span>{t('2VideoSessPerMonth')}</span>
                  ) : (
                    <span>{t('unlimitedMessaging')}</span>
                  )}
                </PlanDescription>
                {/* <PlanDescription>
                  {(category.package_type !== 'standard' && !isPartner) && '(Video - 30mins sessions)'}
                </PlanDescription> */}
              </PlanDetailsWrapper>
            </PlanFirstCol>
            <PlanSecondCol>
              <PlanPriceWrapper>
                <PriceWrapper>
                  <PriceFirstRow isMobile={isMobile}>
                    {`${currency}  ${displayPrice}`}
                    {/* {category.currency} {category.price} */}
                  </PriceFirstRow>
                  {/* <PriceSecondRow>Monthly Weekly</PriceSecondRow> */}
                </PriceWrapper>
                <ArrowWrapper>
                  <ChevronIcon
                    direction={isExpanded ? 'up' : 'down'}
                    color="white"
                    height="15"
                    width="15"
                    onClick={() => (isExpanded ? setIsExpanded(false) : setIsExpanded(true))}
                  />
                </ArrowWrapper>
              </PlanPriceWrapper>
            </PlanSecondCol>
          </FirstRow>
          <Hrline></Hrline>
          {/* {isExpanded && <Collapsible>Details</Collapsible>} */}
          {isExpanded && (
            <PlanVariantsWrapper>
              {category.package_plans.map(
                ({ currency, plan_id, plan_period, price, plan_interval, plan_name, has_trial }) => (
                  <div key={plan_id}>
                    <PlanVariant>
                      {/* {plan_interval === 'week' ? ( */}
                      <PlanVariantCol1 className="inline-plan">
                        <PlanDetails>
                          <span style={{ textTransform: 'capitalize' }}>
                            <b>{`${plan_period} ${plan_interval}${plan_period > 1 ? t('s') : ''}`}</b>
                          </span>
                          <span>{!!has_trial && t('1WeekFreeTrial')}</span>
                        </PlanDetails>
                        <span>
                          {' '}
                          {+price} {currency}
                        </span>
                      </PlanVariantCol1>

                      <PlanVariantCol2>
                        <Radio
                          name={`plan-${plan_id}`}
                          id={plan_id}
                          onChange={() => handleSelectVariant(plan_id, plan_name)}
                          checked={selectedVariant === plan_id && selectedPlan.name === plan_name}
                          value={plan_id}
                        />
                      </PlanVariantCol2>
                    </PlanVariant>
                    <VariantHrline />
                  </div>
                ),
              )}

              {!!category.package_plans.length && (
                <PlanFeatures>
                  <PlanVariantCol1>
                    <b>{t('features')}</b>
                  </PlanVariantCol1>
                  <ListWrapper>
                    {packageFeatures(t)[category.package_type].map((feature, index) => (
                      <ListItem key={index}>{feature}</ListItem>
                    ))}
                    {isPcPartner && <ListItem>{t('videoSessionsAvailable')}</ListItem>}
                  </ListWrapper>
                </PlanFeatures>
              )}
              {subscription?.is_paid && (
                <ButtonWrapper>
                  <Button
                    onClick={handleContinue}
                    variant="primary"
                    loading={isLoading}
                    disabled={selectedPlan?.name !== selectedPlanName}
                  >
                    {subscription?.is_paid
                      ? selectedVariant && selectedPlan?.name === selectedPlanName
                        ? t('changePlan')
                        : t('chooseAPlanToChange')
                      : selectedVariant && selectedPlan?.name === selectedPlanName
                      ? t('continue')
                      : category.plans.length === 5
                      ? t('payWeekly')
                      : t('payMonthly')}
                  </Button>
                </ButtonWrapper>
              )}
            </PlanVariantsWrapper>
          )}
        </PlanContainer>
      ) : (
        <PlanContainer>
          <FirstRow>
            <PlanFirstCol>
              <PlanDetailsWrapper>
                <PlanTitle>
                  {isPartner ? t('partner') : t('business')} {t('pass')}
                </PlanTitle>
                {!isPartner && !morInfo ? (
                  <span
                    onClick={() => moreInfoOnClick()}
                    style={{
                      textDecoration: 'underline',
                      color: 'white',
                      textAlign: 'left',
                      fontSize: '12px',
                      cursor: 'pointer',
                    }}
                  >
                    {t('moreInfo')}
                  </span>
                ) : (
                  <PlanDescription>{t('planDescription')}</PlanDescription>
                )}
              </PlanDetailsWrapper>
            </PlanFirstCol>
            <PlanSecondCol>
              <PlanPriceWrapper>
                <PriceWrapper>
                  <PriceFirstRow isMobile={isMobile}>{t('unlock')}</PriceFirstRow>
                  <PriceSecondRow></PriceSecondRow>
                </PriceWrapper>
                <ArrowWrapper>
                  <ChevronIcon
                    direction={isExpanded ? 'up' : 'down'}
                    color="white"
                    height="15"
                    width="15"
                    onClick={() => (isExpanded ? setIsExpanded(false) : setIsExpanded(true))}
                  />
                </ArrowWrapper>
              </PlanPriceWrapper>
            </PlanSecondCol>
          </FirstRow>
          <Hrline></Hrline>
          {isExpanded && (
            <PlanVariantsWrapper>
              <div>
                <PlanVariant>
                  <PlanVariantCol1 className="voucherInput">
                    <PlanFirstCol noBorder>
                      <span>
                        <b>{isPartner ? t('planFirstCol1') : t('planFirstCol2')}</b>
                      </span>
                    </PlanFirstCol>
                    <PlanSecondCol voucherInput>
                      <input
                        type="text"
                        placeholder={t('voucherCode')}
                        onChange={(e) => setVoucherCode(e.target.value)}
                      />
                    </PlanSecondCol>
                  </PlanVariantCol1>
                </PlanVariant>
                <VariantHrline />
              </div>
              <ButtonWrapper>
                <Button onClick={submitVoucher} variant="primary" disabled={!voucherCode?.trim()}>
                  {t('submitVoucher')}
                </Button>
              </ButtonWrapper>
            </PlanVariantsWrapper>
          )}
        </PlanContainer>
      )}
    </>
  );
};

const PlanVariantsWrapper = styled.div`
  padding: 20px;
  // background: #33c8a0;
  color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: linear-gradient(270deg, rgba(73, 31, 105, 1) 0%, rgba(51, 200, 160, 1) 100%);
`;
PlanVariantsWrapper.displayName = 'PlanVariantsWrapper';

const PlanVariant = styled.div`
  // min-width: 400px;
  width: 500px;
  margin-bottom: ${({ theme }) => theme.spacing};
  display: flex;
  align-items: center;
  @media (max-width: 640px) {
    width: 80vw;
  }
`;
PlanVariant.displayName = 'PlanVariant';

const PlanVariantCol1 = styled.div`
  &.inline-plan {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  flex: 1;
  text-align: left;
  &.voucherInput {
    display: flex;

    input {
      font-size: 16px;
      width: 50%;
      border: none;
    }
  }
`;
const PlanVariantCol2 = styled.div`
  margin-left: 20px;
`;
const DiscountValue = styled.span`
  color: #fff;
`;
DiscountValue.displayName = 'DiscountValue';

const PlanContainer = styled.div`
  display: flex;
  background: rgb(73, 31, 105);
  background: linear-gradient(270deg, rgba(73, 31, 105, 1) 0%, rgba(51, 200, 160, 1) 100%);
  border-radius: 5px;
  margin-bottom: 10px;
  flex-direction: column;
`;
const PlanFirstCol = styled.div`
  flex: 1.5;
  border-right: ${({ noBorder }) => (!noBorder ? '2px solid white' : '')};
  padding: 15px;
  ${({ noBorder }) =>
    noBorder &&
    `
    display:flex;
    justify-content:center;
  `}
`;
const PlanSecondCol = styled.div`
  flex: 1;
  padding: ${({ voucherInput }) => (!voucherInput ? '15px' : '5px')};
  display: flex;
`;
const PlanDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const PlanTitle = styled.div`
  color: white;
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  text-transform: capitalize;
`;
const PlanDescription = styled.div`
  color: white;
  // font-weight: bold;
  font-size: 13px;
  text-align: left;
`;
const PlanPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
const PriceWrapper = styled.div`
  flex: 4;
  flex-direction: column;
  display: flex;
  justify-content: center;
`;
const PriceFirstRow = styled.div`
  color: white;
  font-weight: bold;
  font-size: ${({ isMobile }) => (isMobile ? '16px' : '22px')};
`;
const PriceSecondRow = styled.div`
  color: white;
  font-weight: bold;
  font-size: 12px;
`;
const ArrowWrapper = styled.div`
  flex: 1;
  color: white;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
`;
const FirstRow = styled.div`
  display: flex;
`;
const Hrline = styled.div`
  border-top: 1px solid white;
`;
const VariantHrline = styled.div`
  border-top: 1px solid #35b79a;
  margin-bottom: 15px;
  position: relative;
`;
const ButtonWrapper = styled.div`
  margin-top: ${({ voucherSubmit }) => (!voucherSubmit ? '40px' : '20px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PlanFeatures = styled.div``;
const ListWrapper = styled.ul`
  list-style: none;
  text-align: left;
`;
const ListItem = styled.li`
  position: relative;
  margin-left: 12px;
  // text-transform: capitalize;
  &:before {
    content: '-';
    position: absolute;
    color: #fff;
    left: -10px;
  }
`;
const PlanDetails = styled.span`
  display: flex;
  flex-direction: column;
`;
NewPaymentPlan.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    plans: PropTypes.array,
  }).isRequired,
  disabled: PropTypes.bool,
  // planSelected: PropTypes.bool.isRequired,
  onSelectPlan: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(NewPaymentPlan);

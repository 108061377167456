import React, { useEffect, lazy } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import ReactNotification from 'react-notifications-component';

import { mapStorageToRedux } from './Actions/auth.actions';

import { StoreProvider } from './Store';
import Routes from './Routes';

import { CommonTheme, PatientTheme } from './Styles/theme';
// import { useTeams } from 'msteams-react-base-component';
import { setTeamsContext } from './Actions/teams.action';
import * as msTeams from '@microsoft/teams-js';
import { Loading } from './Components/Atoms';
import { setLanguage } from './Actions/language.action';
// import { setlanguageCode } from '.';

var host = window?.location?.host?.toLowerCase();

export var isExuslabsPartner = host === 'exuslabs.supportroom.com';
export var isBluehrsolutionsPartner = host === 'bluehrsolutions.supportroom.com';
// export const isBluehrsolutionsPartner = true;

export var blueHostLanguages = {
  ar: 'ar',
  en: 'en',
};

export var genericLanguageMapping = {
  en: 'english',
  sr: 'serbian',
  sh: 'serbian',
  el: 'greek',
  ar: 'arabic',
};

var setlanguageCode = () => {
  try {
    let lang = localStorage.getItem('lang');
    if (lang) {
      return lang;
    }
    if (isExuslabsPartner) {
      return 'en';
    }
    let urlPath = window.location.pathname.split('/')[1];
    if (Object.keys(genericLanguageMapping).includes(urlPath) && urlPath !== 'en') {
      localStorage.setItem('lang', urlPath);
      return urlPath;
    }
    let browserLang = (navigator.language || navigator.userLanguage)?.slice(0, 2);
    if (genericLanguageMapping[browserLang]) {
      if (isBluehrsolutionsPartner) {
        if (blueHostLanguages[browserLang]) {
          return browserLang;
        } else {
          return 'en';
        }
      }
      return browserLang;
    }
    return 'en';
  } catch (error) {
    return 'en';
  }
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const App = ({ loggedIn }) => {
  // const [{ context }] = useTeams({});

  const dispatch = useDispatch();
  const selectedLanguage = useSelector((store) => store.language.selectedLanguage);

  useEffect(() => {
    msTeams.initialize();
    msTeams.getContext((context) => {
      dispatch(setTeamsContext(context));
      msTeams.appInitialization.notifySuccess();
    });
  }, []);

  // useEffect(() => {
  // if (context) {
  //   msTeams.appInitialization.notifySuccess();
  //   dispatch(setTeamsContext(context));
  // }
  // }, [context, dispatch]);
  // Media Recorder Polyfill for unsupported browser
  // Lazy load file only for unsupported browser
  const AudioRecorderPolyfill = lazy(() => import('./polyfill'));

  useEffect(() => {
    const authLocalStorageInfo = localStorage.getItem('loginInfo');
    if (authLocalStorageInfo && !loggedIn) {
      dispatch(mapStorageToRedux());
    }
  }, [loggedIn, dispatch]);

  useEffect(() => {
    const selectedLang = setlanguageCode();

    dispatch(setLanguage(selectedLang));
  }, []);

  return (
    <StoreProvider>
      <React.Suspense fallback={<></>}>{!window.MediaRecorder && <AudioRecorderPolyfill />}</React.Suspense>
      <ThemeProvider theme={CommonTheme}>
        <ThemeProvider theme={PatientTheme}>
          <BrowserRouter basename={`/${setlanguageCode()}`}>
            <ReactNotification />
            <ScrollToTop />
            {!selectedLanguage && <Loading />}
            <Routes />
          </BrowserRouter>
        </ThemeProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

const mapStateToProps = (store) => ({
  loggedIn: store.auth.loggedIn,
});

export default connect(mapStateToProps)(App);

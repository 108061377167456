import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import DailyIframe from '@daily-co/daily-js';

import { leaveVideoRoom } from '../../../Actions/chatroom.actions';

import config from './config.js';

const Room = ({ videoRoom: { name } }) => {
  const dispatch = useDispatch();

  const handleLeaveChat = useCallback(() => {
    dispatch(leaveVideoRoom());
  }, [dispatch]);

  const createFrameAndJoinRoom = useCallback(() => {
    let callFrame = DailyIframe.createFrame(document.getElementById('frame'), {
      showLeaveButton: true,
      showFullscreenButton: true,
      iframeStyle: {
        position: 'absolute',
        border: '0',
        width: '100%',
        height: '56.25%', // 16:9 ratio
        top: '0',
        left: '0',
        padding: '1em',
      },
    });

    console.log('Joining ' + name);
    console.log('https://' + config.DAILY_SUBDOMAIN + '.daily.co/' + name);
    callFrame.join({
      url: 'https://' + config.DAILY_SUBDOMAIN + '.daily.co/' + name,
    });
    callFrame.on('left-meeting', (event) => {
      handleLeaveChat();
    });
  }, [name, handleLeaveChat]);

  useEffect(() => {
    name && createFrameAndJoinRoom();
  }, [name, createFrameAndJoinRoom]);

  return <div id="frame" />;
};

const mapStateToProps = (store) => ({
  chatRoomID: store.chatroom.chatRoomID,
  videoRoom: store.chatroom.videoRoom,
});

export default connect(mapStateToProps)(Room);

import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';

import * as moment from 'moment';

// Utils
import { getDaysInAMonth, getWeekOfMonth, getCalendarHours } from '../../Util/';

import { getScheduleList } from '../../Actions/chatroom.actions';
import { getPublicTherapistProfile } from '../../Actions/therapist.actions';
import { getToLocalSchedulesUser } from '../../Selectors/schedules.selector';
import { Loading } from '../../Components/Atoms';
import { useTranslation } from 'react-i18next';

// import { Button, Tooltip } from '../../Components/Atoms';

const monthOptions = (t) => [
  { value: '1', label: t('JAN') },
  { value: '2', label: t("FEB") },
  { value: '3', label: t("MAR") },
  { value: '4', label: t("APR") },
  { value: '5', label: t("MAY") },
  { value: '6', label: t("JUN") },
  { value: '7', label: t("JUL") },
  { value: '8', label: t("AUG") },
  { value: '9', label: t("SEPT") },
  { value: '10', label: t("OCT") },
  { value: '11', label: t("NOV") },
  { value: '12', label: t("DEC") },
];
const dayOptions= (t) =>[
  { value: '0', label: t("MORNING") },
  { value: '1', label: t("AFTERNOON") },
  { value: '2', label: t("EVENING") },
];
const weekOptions = (t) => [
  { value: '1', label: t("WEEK1") },
  { value: '2', label: t("WEEK2") },
  { value: '3', label: t("WEEK3") },
  { value: '4', label: t("WEEK4") },
  { value: '5', label: t("WEEK5") },
  { value: '6', label: t("WEEK6") },
];
const weekNames = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const ScheduleCalendar = ({ selectSlot, cancelAppointment }) => {
  const [month, setMonth] = useState(moment().month() + 1);
  const [partOfDay, setPartOfDay] = useState(0);
  const [week, setWeek] = useState(getWeekOfMonth(moment()));
  const [scheduleOfTheWeek, setScheduleOfTheWeek] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [bookedAlready, setBookedAlready] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const chatroomId = query.get('chatroom_id');
  const therapistId = query.get('therapist_id');

  const dispatch = useDispatch();
  // const history = useHistory();

  const schedules = useSelector((store) => getToLocalSchedulesUser(store));
  const patientProfile = useSelector((store) => store.user.profile);

  useEffect(() => {
    (async () => {
      if (chatroomId) await dispatch(getScheduleList(chatroomId));
      if (therapistId) await dispatch(getPublicTherapistProfile(therapistId));
      setIsLoading(false);
  })();
  }, [dispatch, chatroomId, therapistId]);

  let weeks = useMemo(() => getDaysInAMonth(+moment().format('YYYY'), month - 1, 'YYYY-MM-DD'), [month]);
  let hours = useMemo(() => getCalendarHours(partOfDay), [partOfDay]);

  const monthOnChange = (event) => {
    setMonth(+event?.value);
    setWeek(1);
  };

  const weekOnChange = (event) => setWeek(+event?.value);

  const partOfDayOnChange = (event) => setPartOfDay(+event?.value);

  const sliceFullDateToDay = (date) => {
    const dateLength = date.length;
    return date.slice(dateLength - 2, dateLength);
  };

  useEffect(() => {
    const parseSchedules = () => {
      const currentWeek = weeks[week - 1];
      const schedule = {};
      Object.values(schedules).forEach((date) => {
        if (currentWeek?.includes(date.schedule_date)) {
          schedule[date.schedule_date] = date.slots;
        }
      });
      setScheduleOfTheWeek(schedule);
    };
    parseSchedules();
  }, [week, weeks, schedules]);

  useEffect(() => {
    let upcomingSlot = [];
    schedules.forEach((schedule) => {
      schedule.slots.forEach((slot) => {
        if (slot?.status === 'booked' && slot?.patient_id === patientProfile?.id) {
          setBookedAlready(true);
          setSelectedSlot(slot);
          selectSlot(slot);
        }
        if (slot?.status === "available" && getUpcomingSlot(slot?.schedule_end_time)) {
          upcomingSlot.push(slot);
        }
      });
    });
    if (upcomingSlot && upcomingSlot.length) {
      whichShift(upcomingSlot?.[0]?.schedule_time);
      setWeek(getWeekOfMonth(moment(new Date(upcomingSlot?.[0]?.schedule_time))));
    }
  }, [schedules, patientProfile, selectSlot]);

  const getUpcomingSlot = (day) => {
    const provideDate = new Date(day).getTime();
    const presentDate = new Date().getTime();
    if (provideDate > presentDate) {
      return true
    }
    return false
  }

  const getScheduleStatus = (calendarAppointment) => {
    const existingSchedules = scheduleOfTheWeek[calendarAppointment.day];
    return existingSchedules?.find(
      (schedule) =>
        moment(schedule.schedule_time).format('HH:mm') === calendarAppointment.hour &&
        moment(schedule.schedule_time).isSameOrAfter(moment().format('YYYY-MM-DD HH:mm:ss')),
    );
  };

  const scheduleOnClick = (schedule) => {
    if (schedule && schedule.status === 'available' && !bookedAlready) {
      setSelectedSlot(schedule);
      selectSlot(schedule);
    }
  };

  const cancelSchedule = (e, schedule) => {
    e.stopPropagation();
    if (schedule.status === 'booked') {
      cancelAppointment(chatroomId, schedule.appointment_id).then(() => {
        setBookedAlready(false);
        setSelectedSlot(undefined);
        selectSlot(undefined);
      });
    } else {
      setSelectedSlot(undefined);
      selectSlot(undefined);
    }
  };

  const whichShift = (day) => {
    const hours = new Date(day).getHours();
    if (hours >= 6 && hours < 12) {
      setPartOfDay(0);
    } else if (hours >= 12 && hours < 18) {
      setPartOfDay(1);
    } else {
      setPartOfDay(2);
    }
  }
  // const goToChatroom = () => history.push('/');
  if (isLoading) {
    return <Loading />
  }

  return (
    <Wrapper>
      <FilterWrapper>
        <StyledSelect
          className="calendar-selector-container calendar-selector-month"
          classNamePrefix="calendar-selector"
          options={monthOptions(t).filter(({ value }) => Number(value) >= (new Date().getMonth() + 1))}
          value={monthOptions(t)[month - 1]}
          isSearchable={false}
          onChange={monthOnChange}
        />
        <StyledSelect
          className="calendar-selector-container calendar-selector-day"
          classNamePrefix="calendar-selector"
          options={dayOptions(t)}
          value={dayOptions(t)[partOfDay]}
          isSearchable={false}
          onChange={partOfDayOnChange}
        />
        <StyledSelect
          className="calendar-selector-container calendar-selector-week"
          classNamePrefix="calendar-selector"
          options={weekOptions(t).filter((option, index) => index < weeks?.length)}
          value={weekOptions(t)[week - 1]}
          isSearchable={false}
          onChange={weekOnChange}
        />
      </FilterWrapper>
      {scheduleOfTheWeek && (
        <CalendarWrapper>
          <WeekDays id='numberOfDays'>
            {weeks?.length
              ? weeks[week - 1].map((day, index) => (
                <Day
                  key={`${month}-${day}`}>
                  <DayName>{weekNames[index]}</DayName>
                  <DayNumber>{sliceFullDateToDay(day)}</DayNumber>
                </Day>
              ))
              : null}
          </WeekDays>
          <HoursWrapper>
            {hours.map((row, index) => (
              <HoursRow
                key={index}>
                {row.map((hour, index) => {
                  const schedule = getScheduleStatus({ day: weeks[week - 1][index], hour });
                  const isSelected =
                    (selectedSlot && schedule && selectedSlot?.schedule_id === schedule?.schedule_id) ||
                    (schedule?.status === 'booked' && schedule?.patient_id === patientProfile?.id);
                  return (
                    <Hour
                      selected={isSelected}
                      schedule={schedule}
                      key={index}
                      onClick={() => scheduleOnClick(schedule)}
                      bookedAlready={bookedAlready}
                    >
                      {isSelected && <CancelSchedule onClick={(e) => cancelSchedule(e, schedule)}>x</CancelSchedule>}
                      {hour}
                    </Hour>
                  );
                })}
              </HoursRow>
            ))}
          </HoursWrapper>
        </CalendarWrapper>
      )}
      {/* <Tooltip
        id="no-schedules"
        place="top"
        text="Your therapists will be notified you have matched with them and will respond to you as soon as possible"
      >
        <Button onClick={goToChatroom} style={{ marginTop: 30 }}>
          skip
        </Button>
      </Tooltip> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;
const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const StyledSelect = styled(Select)`
  width: 30%;

  .calendar-selector__control {
    border: none;
    box-shadow: none;
  }
  .calendar-selector__indicator-separator {
    display: none;
  }
  .calendar-selector__value-container {
    padding: 0;
    height: 30px;

    .calendar-selector__single-value {
      color: #57bf9a;
      font-weight: 600;
      font-size: 13px;
    }
  }
  .calendar-selector__indicators {
    width: 15px;
    .calendar-selector__dropdown-indicator {
      padding: 0;

      svg {
        width: 17px;
      }
    }
  }
`;
const CalendarWrapper = styled.div``;

const WeekDays = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Day = styled.div`
  width: 13%;
  background: #ededed;
  padding: 4px;
  border-radius: 3px;

  font-size: 12px;
`;
const DayName = styled.span`
  display: block;
`;
const DayNumber = styled.span``;

const HoursWrapper = styled.div`
  margin-top: 8px;
`;
const HoursRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;
const CancelSchedule = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  line-height: 13px;
  font-size: 12px;
  color: white;
  background-color: red;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: #bb0101;
  }
`;
const Hour = styled(Day)`
  position: relative;
  font-size: 12px;
  padding: 10px 0;
  background: #fafafa;
  cursor: not-allowed;

  ${({ schedule, bookedAlready }) =>
    schedule &&
    schedule.status !== 'booked' &&
    css`
      background: #dbf5ec;
      cursor: pointer;

      &:hover {
        background: #56bf9a;
        color: white;
        font-weight: bold;
      }
    `}

  ${({ bookedAlready, schedule }) =>
    bookedAlready &&
    schedule?.status === 'available' &&
    css`
      cursor: not-allowed;

      &:hover {
        background: #b9ded1;
        color: white;
        font-weight: bold;
      }
    `}

  ${({ selected }) =>
    selected &&
    css`
      font-weight: bold;
      color: white;
      background: #56bf9a;
    `};
`;

export default ScheduleCalendar;

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../Components/Atoms/Modal/Modal';
import cloneDeep from 'lodash/cloneDeep';

import { getActiveChatRooms } from '../../../Actions/therapist.actions';
import { switchTherapist } from '../../../Actions/chatroom.actions';

import { Button, FormGroup, Label, ProgressBar } from '../../../Components/Atoms';
import { ReactComponent as Flag } from '../../../Assets/Icons/Flag.svg';

import { ReactComponent as MessagesIcon } from '../../../Assets/Icons/Messages.svg';
import { ReactComponent as UserIcon } from '../../../Assets/Icons/User.svg';
import Tooltip from '../../../Components/Atoms/Tooltip/Tooltip';
import Select from '../../../Components/Atoms/Select/Select';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { api } from '../../../Util';
import { store } from 'react-notifications-component';

const PatientList = ({ limit, showUnreadMessages, segregatePatientStatus, showActivePatients }) => {
  const [patientToSwitch, setPatientToSwitch] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [sortByField, setSortByField] = useState('last_message_date');
  const [companyFilterText, setCompanyFilterText] = useState('');
  const [open, setOpen] = useState(false);
  const [arrData, setArrData] = useState([]);
  const [concerningIssuePendingPatients, setConcerningIssuePendingpatients] = useState([]);
  const chatRooms = useSelector((store) => {
    return store.user.chatRooms;
  });
  const unreadMessages = useSelector((store) => store.chatroom.unreadMessages);
  const dispatch = useDispatch();

  const unreadMessageMap = useMemo(() => {
    const unreadMessageMap = new Map();

    if (chatRooms)
      chatRooms.forEach(({ chatroom_id, concerning_issue, patient_full_name }) => {
        if (concerning_issue) {
          setConcerningIssuePendingpatients((prevState) => [...prevState, patient_full_name]);
        }
        unreadMessageMap.set(chatroom_id, { count: 0, timestamp: 0 });
      });
    if (unreadMessages)
      unreadMessages.messages.forEach(({ chatroom_id, sent_at, read_status }) => {
        const data = unreadMessageMap.get(chatroom_id);
        if (data) {
          unreadMessageMap.set(chatroom_id, {
            count: read_status === 0 ? data.count + 1 : data.count,
            timestamp: data.timestamp < new Date(sent_at).getTime() ? new Date(sent_at).getTime() : data.timestamp,
          });
        }
      });

    return unreadMessageMap;
  }, [chatRooms, unreadMessages]);

  const sortByUnread = useMemo(() => {
    return [...unreadMessageMap.values()].some(({ count }) => count > 0);
  }, [unreadMessageMap]);

  let sortedChatRooms = useMemo(() => {
    let sortedList = [];
    if (!sortByUnread || !showUnreadMessages) {
      sortedList = chatRooms;
    }
    sortedList = cloneDeep(chatRooms)?.sort((a, b) => {
      return unreadMessageMap.get(b.chatroom_id).timestamp - unreadMessageMap.get(a.chatroom_id).timestamp;
    });

    if (sortByField === 'last_message_date') {
      sortedList = chatRooms?.sort(({ last_message_date }, { last_message_date: last_message_date2 }) =>
        !last_message_date ? 1 : !last_message_date2 ? -1 : new Date(last_message_date2) - new Date(last_message_date),
      );
    } else if (sortByField === 'company_name') {
      sortedList = chatRooms?.sort(({ company_name: company_name1 }, { company_name: company_name2 }) =>
        !company_name1 ? 1 : !company_name2 ? -1 : company_name1.localeCompare(company_name2),
      );
    }

    if (companyFilterText !== '') {
      sortedList = sortedList.filter(
        (patientDetails) =>
          patientDetails.company_name?.toLowerCase().includes(companyFilterText?.toLowerCase()) ||
          patientDetails.patient_full_name?.toLowerCase().includes(companyFilterText?.toLowerCase()),
      );
    }

    if (segregatePatientStatus) {
      if (showActivePatients) {
        return sortedList?.filter((elem) => elem.user_status === 'active');
      } else {
        return sortedList?.filter((elem) => elem.user_status === 'inactive');
      }
    }

    return sortedList;
  }, [chatRooms, unreadMessageMap, showUnreadMessages, sortByUnread, sortByField, companyFilterText]);

  const openSwitchTherapistModal = (patient) => {
    setPatientToSwitch(patient);
    setModalState(true);
  };

  const switchTherapistAction = () => {
    dispatch(switchTherapist({ chatroom_id: patientToSwitch.chatroom_id })).then(() => {
      setModalState(false);
      dispatch(getActiveChatRooms());
      setPatientToSwitch(null);
    });
  };

  const closeModal = () => setModalState(false);

  useEffect(() => {
    dispatch(getActiveChatRooms());
  }, [dispatch]);

  const onFinish = (values, { resetForm }) => {
    console.log(values, 'values--');

    api.chatroom.sendBulkMessage({ patient_ids: arrData, message: values.message });
    resetForm();
  };

  const handleCheckbox = (e, id, isAll) => {
    if (isAll) {
      if (e.target.checked) {
        const updatedData = sortedChatRooms.map((patient) => patient.patient_id);
        return setArrData(updatedData);
      } else {
        return setArrData([]);
      }
    }

    if (e.target.checked) {
      const updatedArrData = [...arrData, id];
      setArrData(updatedArrData);
    } else {
      const filteredData = arrData.filter((patientId) => patientId !== id);
      setArrData(filteredData);
    }
  };

  const final_Arr = (item) => {
    return item.filter((i) => item.filter((j) => i === j).length === 1);
  };
  const updated_Arr = final_Arr(arrData);

  console.log('updated_Arr: ', updated_Arr);

  return (
    <>
      <ModalComponent open={modalState}>
        <div>
          <h3>Are you sure to switch Therapist for {patientToSwitch?.patient_full_name}</h3>
          <div style={{ marginTop: 20 }}>
            <Button style={{ marginRight: 10 }} variant="danger" onClick={switchTherapistAction}>
              Switch
            </Button>
            <Button onClick={closeModal}>Close</Button>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent open={open} closeModal={() => setOpen(false)} showCloseButton={true}>
        <Formik
          initialValues={{
            message: '',
          }}
          onSubmit={onFinish}
        >
          {({ errors, touched }) => (
            <Form>
              <FormGroup>
                <Label htmlFor="message">Message:</Label>
                <TextareaWrapper>
                  <Field
                    rows={5}
                    id="message"
                    name="message"
                    as={StyledTextarea}
                    className={errors.message && touched.message ? ' is-invalid' : ''}
                    placeholder="Message"
                  />
                </TextareaWrapper>
                <ErrorMessage name="message" component="div" className="invalid-feedback" />
              </FormGroup>
              <Button type="submit">Send</Button>
            </Form>
          )}
        </Formik>
      </ModalComponent>
      <SortFilterWrap>
        <SortAndFilterWrap>
          <StyledLabel htmlFor="sortId">Sort By : </StyledLabel>
          <Select
            id="sortId"
            styles={{
              control: (base) => ({ ...base, width: window.screen.width > 767 ? 190 : 105, height: 35 }),
              valueContainer: () => ({ padding: '0px 8px' }),
            }}
            defaultValue={{ label: 'Last Chat', value: 'last_message_date' }}
            menuPlacement="auto"
            menuPosition="fixed"
            options={[
              { label: 'Last Chat', value: 'last_message_date' },
              { label: 'Company Name', value: 'company_name' },
            ]}
            placeholder="Sort By"
            onChange={(e) => setSortByField(e.value)}
          />
        </SortAndFilterWrap>
        <Button
          type="primary"
          style={{ height: '40px' }}
          onClick={() => {
            setOpen(true);
          }}
        >
          Send Bulk Messages
        </Button>
        <SortAndFilterWrap>
          <StyledLabel htmlFor="companyFilter">Search By Patient Name or Company : </StyledLabel>
          <StyledInput
            type="text"
            id="companyFilter"
            value={companyFilterText}
            placeholder="Patient or Company name"
            onChange={(e) => setCompanyFilterText(e.target.value)}
          />
        </SortAndFilterWrap>
      </SortFilterWrap>
      <table>
        <thead>
          <tr>
            <th>
              <input
                style={{ height: 18, width: 18, accentColor: '#00C8A0' }}
                type="checkbox"
                id={`patient-checkbox-all`}
                name={'all'}
                value={'all'}
                onChange={(e) => handleCheckbox(e, null, true)}
              />
            </th>

            <th>Name</th>
            <th>Company Name</th>
            <th>Journey Progress</th>
            {/* <th style={{ textAlign: 'center' }}>Mood</th> */}
            <th style={{ textAlign: 'center' }}>Risk Level</th>
            {showUnreadMessages && <th style={{ textAlign: 'center' }}>Unread Messages</th>}
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {sortedChatRooms?.length ? (
            sortedChatRooms.slice(0, limit).map((patient, index) => (
              <tr key={patient.patient_id}>
                <td>
                  <input
                    style={{ height: 18, width: 18, accentColor: '#00C8A0' }}
                    type="checkbox"
                    id={`patient-checkbox-${index}`}
                    name={patient.patient_id}
                    value={patient.patient_id}
                    checked={arrData.includes(patient.patient_id)}
                    onChange={(e) => handleCheckbox(e, patient.patient_id)}
                  />
                </td>
                <td>
                  {patient.concerning_issue ? (
                    <Tooltip
                      id="concerning-issues"
                      text="Please fill out concerning issues for the patient"
                      place="top"
                    >
                      <Link
                        style={{
                          padding: 8,
                          border: '1px solid white',
                          color: 'white',
                          backgroundColor: 'rgb(0 200 160)',
                          fontWeight: 700,
                          borderRadius: 4,
                          textDecoration: 'none',
                          display: 'inline-flex',
                          alignItems: 'center',
                        }}
                        to={`/t/patient-profile/${patient.chatroom_id}/${patient.patient_id}`}
                      >
                        <UserIconSvg className="icon" style={{ fill: 'white' }} />{' '}
                        <span>{patient.patient_full_name}</span>
                      </Link>
                    </Tooltip>
                  ) : (
                    <Link
                      style={{
                        padding: 8,
                        border: '1px solid #1A5549',
                        color: '#1A5549',
                        fontWeight: 700,
                        borderRadius: 4,
                        textDecoration: 'none',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                      to={`/t/patient-profile/${patient.chatroom_id}/${patient.patient_id}`}
                    >
                      <UserIconSvg className="icon" /> <span>{patient.patient_full_name}</span>
                    </Link>
                  )}
                </td>
                <td>{patient.company_name}</td>
                <td>
                  <Tooltip
                    id={`Progress-${patient.patient_id}`}
                    text="Track & manage your patients therapy progress"
                    place="top"
                  >
                    <ProgressBar roomID={patient.chatroom_id} />
                  </Tooltip>
                </td>
                {/* <td style={{ textAlign: 'center' }}><MoodIndicator mood="happy" /></td> */}
                <td style={{ textAlign: 'center' }}>
                  <FlagIcon flag={patient?.flag}>
                    <Tooltip
                      id={`Flag-${patient.patient_id}`}
                      text="Flag if your client is at risk. Green is ok & red is at risk"
                      place="top"
                    >
                      <Flag />
                    </Tooltip>
                  </FlagIcon>
                </td>
                {showUnreadMessages && (
                  <td style={{ textAlign: 'center' }}>
                    {unreadMessageMap.has(patient.chatroom_id) && unreadMessageMap.get(patient.chatroom_id).count ? (
                      <MessageCount>{unreadMessageMap.get(patient.chatroom_id).count}</MessageCount>
                    ) : null}
                  </td>
                )}
                <td>
                  <Button as={Link} to={`/t/chatroom/${patient.chatroom_id}`} className="small ghost">
                    <ChatIcon className="icon" /> <span>Chat</span>
                  </Button>
                </td>
                <td>
                  <Button className="small ghost" onClick={() => openSwitchTherapistModal(patient)}>
                    <span>Switch Therapist</span>
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No search results found!</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

const SortFilterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SortAndFilterWrap = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  @media screen and (max-width: 767px) {
    align-items: inherit;
  }
`;

const StyledLabel = styled.label`
  margin-right: 15px;
  /* width: 120px; */
  @media screen and (max-width: 767px) {
    width: 68px;
  }
`;

const StyledInput = styled.input`
  height: 36px;
  font-size: 14px;
  @media screen and (max-width: 767px) {
    max-width: 105px;
    font-size: 11px;
  }
`;

const FlagIcon = styled.span`
  svg path {
    fill: ${({ flag, theme }) => {
      if (flag === 'amber') return theme.statusWarning;
      else if (flag === 'red') return theme.statusDanger;
      else return theme.statusSuccess;
    }};
  }
`;

const MessageCount = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  background-color: ${({ theme }) => theme.primaryMedium};
  color: ${({ theme }) => theme.white};
  border-radius: 100%;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontNormal};
`;
MessageCount.displayName = 'MessageCount';

const ChatIcon = styled(MessagesIcon)`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.primaryMedium};
`;
ChatIcon.displayName = 'ChatIcon';

const UserIconSvg = styled(UserIcon)`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.primaryMedium};
  margin-right: 5px;
`;
ChatIcon.displayName = 'ChatIcon';

PatientList.propTypes = {
  limit: PropTypes.number,
  showUnreadMessages: PropTypes.bool,
};

const TextareaWrapper = styled.div`
  textarea {
    ::placeholder {
      font-size: 16px;
    }
  }
`;

const StyledTextarea = styled.textarea`
  resize: none;
`;
StyledTextarea.displayName = 'StyledTextarea';
export default PatientList;

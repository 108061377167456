import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from './storeNew';

import './i18nextConf';

import './Util/axiosConfig';

import './Styles/index.scss';
import 'reactjs-popup/dist/index.css';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

import LogRocket from 'logrocket';
LogRocket.init('ylmno5/supportroom');

// Browser Notification and Audio - CONFIG
// export const notificationPermissionResult = 'Notification' in window ? Notification?.permission : null;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorkerRegistration.register();

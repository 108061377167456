import React from 'react';
import { Switch, useHistory } from 'react-router-dom';
import * as msTeams from '@microsoft/teams-js';

import Route from './Route';
// import * as Patient from '../Patient';
import PatientRoutes from './PatientRoutes';
import TherapistRoutes from './TherapistRoutes';
import EnterpriseRoutes from './EnterpriseRoutes';
import ForgottenPassword from '../Neutral/ForgottenPassword';
import ResetPassword from '../Neutral/ResetPassword';
import VerifyEmail from '../Neutral/VerifyEmail';
import Welcome from '../Welcome';
import WelcomeRoutes from './WelcomeRoutes';
import Login from '../Welcome/Signin';
import Privacypolicy from '../Neutral/Privacypolicy';
import Terms from '../Neutral/Terms';
import Teams from '../Teams';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../ErrorFallback';
import { isNixy } from '../Config';
import { Login as TherapistLogin } from '../Therapist';
import NixyWelcomeRoutes from './NixyWelcomeRoutes';
import NixyTherapistRoutes from './NixyTherapistRoutes';
export default function Routes() {
  const history = useHistory();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        history.push('/logout');
      }}
    >
      <Switch>
        {isNixy ? null
          : PatientRoutes.map((route) => (
            <Route
              key={route.path}
              exact
              path={route.path}
              isPrivate={route.isPrivate}
              role={'patient'}
              component={route.component}
            />
          ))
        }

        {(isNixy ? NixyTherapistRoutes : TherapistRoutes).map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            isPrivate={route.isPrivate}
            role={'therapist'}
            component={route.component}
          />
        ))}

        {EnterpriseRoutes.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            isPrivate={route.isPrivate}
            role={'company'}
            component={route.component}
          />
        ))}

        {(isNixy ? NixyWelcomeRoutes : WelcomeRoutes).map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            isPrivate={route.isPrivate}
            role={'patient'}
            component={route.component}
          />
        ))}

        <Route path="/forgotten-password/:role" component={ForgottenPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/privacy-policy" component={Privacypolicy} />
        <Route exact path="/terms-and-conditions" component={Terms} />
        <Route exact path="/verify-email" component={VerifyEmail} />
        <Route exact path="/update-email" component={VerifyEmail} />
        {/* <Route exact path="/" component={Patient.Login} /> */}
        <Route exact path="/teams" component={Teams} />

        {/* ------------------------------ DEFAULT ROUTE ----------------------------- */}
        {isNixy ? null : <Route exact path="/" component={checkInTeams() ? Teams : Welcome} />}
        <Route component={isNixy ? TherapistLogin : Login} />
      </Switch>
    </ErrorBoundary>
  );
}

const checkInTeams = () => {
  // eslint-disable-next-line dot-notation
  const microsoftTeamsLib = msTeams || window['microsoftTeams'];

  if (!microsoftTeamsLib) {
    return false; // the Microsoft Teams library is for some reason not loaded
  }

  if (
    (window.parent === window.self && window.nativeInterface) ||
    window.name === 'embedded-page-container' ||
    window.name === 'extension-tab-frame'
  ) {
    return true;
  }
  return false;
};
import { api } from './';

export const checkEnterprise = () => {
  /**
   * This will check if the url belongs to a enterprise or not
   */
  const enterpriseURL = [
    'mysupportroom.com',
    'alpha.supportroom.com',
    // 'localhost:3000',
    // 'partner-test.supportroom.com',
    // 'new.supportroom.com'
  ];
  const result = enterpriseURL.includes(window.location.host);
  return result;
};

export const checkPartner = () => async (dispatch) => {
  const domain = window.location.host;
  // const domain = 'partner-test.supportroom.com';
  await api.welcome.verifyPartner(domain)
    .then(response => {
      const status = response?.data?.data?.is_partner;
      dispatch({ type: 'VERIFY_PARTNER_URL', payload: !!status })
    })
}


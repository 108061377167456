import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { store } from 'react-notifications-component';

import { useTranslation } from 'react-i18next';
import api from '../../Util/api';

import SignupPage from '../../Patient/Login';

const EmployeeSignup = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const companyId = query.get('company_unique_id');
  const invitationCode = query.get('invitation_password');
  const [departments, setDepartments] = useState([]);

  const redirectToLogin = useCallback(() => history.push('/login'), [history]);

  useEffect(() => {
    if (!companyId) redirectToLogin();
  }, [companyId, redirectToLogin]);

  useEffect(() => {
    api.company.confirmInvitation({ invitation_password: invitationCode, company_unique_id: companyId }).catch(() => {
      store.addNotification({
        title: 'Error!',
        message: (
          <div>
            <p>{t("invalidB2BEnterpriseCode")}</p>
          </div>
        ),
        type: 'danger',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 8000,
          onScreen: true,
        },
      });
      redirectToLogin();
    });
  }, [invitationCode, companyId, redirectToLogin]);

  useEffect(() => {
    api.company
      .getCompanyDepartments(companyId)
      .then((response) => {
        if (response.status === 200) {
          setDepartments(response.data?.data || []);
        }
      })
      .catch((err) => {
        console.error(err.response.data.message);
      });
  }, [companyId]);

  return <SignupPage registerEnterprise departments={departments} />;
};

export default EmployeeSignup;

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import {
  createVideoRoom,
  destroyVideoRoom,
  launchVideoRoom,
  setVideoRoom,
  subtractVideoFromPatient,
} from '../../../Actions/chatroom.actions';
import { Button, Heading } from '../../Atoms';
import VideoGreen from '../../../Assets/Icons/VideoGreen.svg';
import Toolbox from '../../../Assets/Icons/Toolbox.svg';
import { slide as Menu } from 'react-burger-menu';
import ChevronRight from '../../../Assets/Icons/ChevronRight.svg';
import ChevronLeft from '../../../Assets/Icons/ChevronLeft.svg';
import CloseIcon from '../../../Assets/Icons/ClosePurple.svg';
import { PatientList, Sidebar } from '../../../Therapist/Chatroom/Components';
import { ReactComponent as Flag } from '../../../Assets/Icons/Flag.svg';
import moment from 'moment';
import { MAX_PASSED_TIME_FOR_JOINING, MAX_TIME_BEFORE_JOINING } from './ChatWindow';
import { simplifySchedule } from '../../../Util/helper';
import { api } from '../../../Util';
import { Link } from 'react-router-dom';

export const eligibleForVideRoomCreation = (scheduleList, profile, patient_id) => {
  if (!scheduleList || scheduleList.length === 0) return false;

  const currentDate = moment().format('YYYY-MM-DD');
  let isEligible = false;

  const todaySchedules = scheduleList.filter((day) => day.schedule_date === currentDate);
  if (todaySchedules.length && profile) {
    todaySchedules[0].slots.forEach((slot) => {
      if (slot.status === 'booked' && slot?.patient_id === patient_id) {
        const momentSlotTime = slot.local_schedule_date_time;

        const milliseconds = momentSlotTime.diff(moment());
        const diff = moment.duration(milliseconds);
        const minutes = diff.asMinutes().toFixed(0);

        if (MAX_PASSED_TIME_FOR_JOINING < parseInt(minutes) && parseInt(minutes) < MAX_TIME_BEFORE_JOINING) {
          isEligible = true;
        }
      }
    });
  }

  return isEligible;
};

const Header = ({
  chatroomAvailable,
  chatRoomName,
  handleOnInfoClick,
  handleFlagPatient,
  viewportWidth,
  rooms,
  chatRoomID,
  scheduleList,
  profile,
}) => {
  const [rightMenuOpen, setRightMenuOpen] = useState(false);
  const [canCreateVideoRoom, setCanCreateVideoRoom] = useState(true);
  const [loadingCount, setLoadingCount] = useState(0);

  const dispatch = useDispatch();

  const startVideoRoom = () => {
    api
      .videoRoomRequest(`/rooms/SR-${chatRoomID}`, 'GET')
      .then(function (response) {
        dispatch(setVideoRoom(response.data));

        dispatch(launchVideoRoom());
      })
      .catch(function (error) {
        if (error.response?.data?.error === 'not-found') {
          dispatch(destroyVideoRoom(chatRoomID));
          setLoadingCount((prevState) => prevState + 1);
          dispatch(createVideoRoom(chatRoomID)).then(() => {
            dispatch(launchVideoRoom());
            setLoadingCount((prevState) => prevState - 1);
            dispatch(subtractVideoFromPatient(chatRoomID));
          });
        }
      });
  };

  useEffect(() => {
    setCanCreateVideoRoom(eligibleForVideRoomCreation(scheduleList, profile, roomData.patient_id));
    let canCreateRoomID = setInterval(() => {
      setCanCreateVideoRoom(eligibleForVideRoomCreation(scheduleList, profile, roomData.patient_id));
    }, 20 * 1000);
    return () => {
      clearInterval(canCreateRoomID);
    };
  }, [scheduleList, profile]);

  const roomData = useMemo(() => {
    const index = rooms.findIndex((room) => room.chatroom_id === chatRoomID);
    if (index > -1) return rooms[index];
    return null;
  }, [rooms, chatRoomID]);

  const handleFlagClick = () => {
    setRightMenuOpen(false);
    handleFlagPatient();
  };

  const handleRightMenuOpen = useCallback(({ isOpen }) => {
    setRightMenuOpen(isOpen);
  }, []);

  return (
    <ChatHeader>
      {viewportWidth < 768 && (
        <Menu
          customBurgerIcon={<img src={ChevronRight} alt="" />}
          customCrossIcon={<img src={ChevronLeft} alt="" />}
          styles={PatientListStyles}
          pageWrapId={'mainContent'}
          outerContainerId={'chatWrapper'}
          disableAutoFocus
        >
          <PatientList mobile />
        </Menu>
      )}

      <Link
        style={{
          marginRight: 'auto',
        }}
        to={`/t/patient-profile/${roomData.chatroom_id}/${roomData.patient_id}`}
      >
        <StyledHeading level={3} text={roomData?.patient_full_name} />
      </Link>

      {viewportWidth < 768 ? (
        <>
          {canCreateVideoRoom && (
            <VideoChatButton className={`${viewportWidth > 450 ? 'ghost small' : ''}`} onClick={startVideoRoom}>
              <img src={VideoGreen} alt="" />
              {viewportWidth > 450 && ' Start Video'}
            </VideoChatButton>
          )}
          {roomData?.type && (
            <FlagPatientButton
              className={`${viewportWidth > 450 ? 'ghost small' : ''}`}
              // flag={roomData?.flag}
              onClick={handleFlagClick}
            >
              {roomData?.type === 'B2B' ? roomData?.company_name : roomData?.type}
            </FlagPatientButton>
          )}

          <FlagPatientButton
            className={`${viewportWidth > 450 ? 'ghost small' : ''}`}
            flag={roomData?.flag}
            onClick={handleFlagClick}
          >
            <SvgIcon as={Flag} />
            {viewportWidth > 450 && 'Flag Patient'}
          </FlagPatientButton>
          <Menu
            isOpen={rightMenuOpen}
            onStateChange={handleRightMenuOpen}
            right
            customBurgerIcon={<img src={Toolbox} alt="" />}
            customCrossIcon={<img src={CloseIcon} alt="" />}
            styles={ToolboxStyles}
            pageWrapId={'mainContent'}
            outerContainerId={'chatWrapper'}
            disableAutoFocus
          >
            <Sidebar chatroomAvailable={chatroomAvailable} />
          </Menu>
        </>
      ) : (
        <>
          {canCreateVideoRoom && (
            <VideoChatButton className="ghost small" onClick={startVideoRoom}>
              <img src={VideoGreen} alt="" /> Start Video
            </VideoChatButton>
          )}

          {roomData?.type && (
            <FlagPatientButton
              className={`${viewportWidth > 450 ? 'ghost small' : ''}`}
              // flag={roomData?.flag}
              // onClick={handleFlagClick}
            >
              {roomData?.type === 'B2B' ? roomData?.company_name : roomData?.type}
            </FlagPatientButton>
          )}

          <FlagPatientButton flag={roomData?.flag} className="ghost small" onClick={handleFlagPatient}>
            <SvgIcon as={Flag} />
            Flag Patient
          </FlagPatientButton>
          <ShowSidebarButton onClick={handleOnInfoClick}>
            <img src={Toolbox} alt="" />
          </ShowSidebarButton>
        </>
      )}
    </ChatHeader>
  );
};

const PatientListStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '10px',
    top: '0',
    marginRight: '16px',
  },
  bmCrossButton: {
    width: '18px',
    top: '26px',
    right: '16px',
  },

  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
    left: 0,
  },
  bmMenu: {
    background: '#ffffff',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#ffffff',
    padding: '64px 32px 32px',
  },
  bmItem: {
    display: 'block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

const ToolboxStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '24px',
    top: '0',
    zIndex: 999,
  },
  bmCrossButton: {
    width: '18px',
    top: '26px',
    right: '16px',
  },

  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
    right: 0,
  },
  bmMenu: {
    background: '#ffffff',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#491F69',
    padding: '64px 32px 32px',
  },
  bmItem: {
    display: 'block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};
const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryMedium};
  padding: 0 16px;

  ${({ theme }) => theme.sm`
      height: 60px;
      padding: ${({ theme }) => theme.spacingMd} ${({ theme }) => theme.spacing};
   `}
`;

const StyledHeading = styled(Heading)`
  margin-right: auto;
`;

const VideoChatButton = styled(Button)`
  border-color: transparent;
  color: ${({ theme }) => theme.primaryMedium} !important;
  margin-right: ${({ theme }) => theme.spacingSm};
  padding: 4px 4px;
  font-size: 12px;
  min-width: auto;
  background: transparent;
  ${({ theme }) => theme.sm`
    border-color: ${({ theme }) => theme.primaryMedium} !important;
   `}
  img {
    vertical-align: middle;
    width: 24px;
    ${({ theme }) => theme.sm`
    width: 18px;
   `}
  }
`;

const SvgIcon = styled.svg``;

const FlagPatientButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacingSm};
  color: ${({ theme }) => theme.primaryMedium} !important;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 4px 4px;
  min-width: auto;
  background: transparent;
  ${({ theme }) => theme.sm`
    border-color: ${({ theme }) => theme.primaryMedium} !important;
   `}

  svg {
    margin: auto;
    width: 20px;
    height: 20px;
    ${({ theme }) => theme.sm`
    //  margin-right: 10px;
     height: 14px
   `}

    path {
      fill: ${({ theme }) => theme.primaryMedium};
    }
  }

  ${({ flag }) => {
    if (flag === 'amber')
      return css`
        svg {
          path {
            fill: ${({ theme }) => theme.statusWarning};
          }
        }
      `;
    else if (flag === 'red')
      return css`
        svg {
          path {
            fill: ${({ theme }) => theme.statusDanger};
          }
        }
      `;
  }}
`;
const ShowSidebarButton = styled.button`
  border: 0;
  border-radius: 5em;
  width: 30px;
  height: 30px;
  background: none;
  padding: 0;
  margin-right: ${({ theme }) => theme.spacingSm};
  cursor: pointer;
`;

const mapStateToProps = (store) => ({
  scheduleList: simplifySchedule(store.user?.scheduleList),
  profile: store.user?.profile,
});

export default memo(connect(mapStateToProps)(Header));

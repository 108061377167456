import React, { memo, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Styled from 'styled-components';
import { WelcomeLayout } from '../Layout';
import { checkPartner } from '../Util';
import { useWindowSize } from '../Hooks';
import { TextBlock, Button, Loading, ChevronIcon } from '../Components/Atoms/';
import { BottomSectionWrapper, MainSectionWrapper } from './Components/';
import AdamImage from '../Assets/SignUp/quotes/panagiotis_karagiannis.jpg';
import EmergencyBtn from '../Components/Atoms/EmergencyBtn';
import { useTranslation } from 'react-i18next';

const Welcome = () => {
  const socialRequestPopupStatus = useSelector((store) => store.auth.socialRequestPopupStatus);
  const LoginInfo = localStorage.getItem('loginInfo');
  const LoggedIn = !!LoginInfo;
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const isMobile = useMemo(() => width < 768, [width]);
  const redirectDomains = ['mysupportroom.com', 'new.supportroom.com', 'localhost:3000'];
  const verifyPartner = useCallback(() => dispatch(checkPartner()), [dispatch]);
  const isPartnerCheck = useSelector((store) => store.isPartner?.isPartner);

  const { t } = useTranslation();

  const therapistMeta = {
    image: AdamImage,
    name: 'Panagiotis Karagiannis',
    description: t('certifiedTherapist'),
    content: t('therapistContent'),
  };

  // if (LoggedIn && !socialRequestPopupStatus) history.push('/chatroom');

  useEffect(() => {
    if (LoggedIn && !socialRequestPopupStatus) {
      history.push('/chatroom');
    }
  }, [socialRequestPopupStatus, LoggedIn, history]);

  useEffect(() => {
    if (isPartnerCheck === '' || isPartnerCheck === 'undefined') verifyPartner();
  }, [isPartnerCheck, verifyPartner]);

  // if (typeof isPartnerCheck !== 'string' && !isPartnerCheck && !redirectDomains.includes(window.location.host))
  //   history.push('/welcome/login');

  const renderButton = () => {
    if (isPartnerCheck === '' || isPartnerCheck === 'undefined') return;
    if (isPartnerCheck) {
      return (
        <ButtonGuestWrapper isMobile={isMobile}>
          <Button
            variant="secondary"
            style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
            onClick={() => history.push('/welcome/c/questionnaire')}
          >
            {t('letsGo')}
            <ChevronIcon direction="right" width="13" height="13" />
          </Button>
        </ButtonGuestWrapper>
      );
    }
    return (
      <ButtoWrapper isMobile={isMobile}>
        <Button
          variant="secondary"
          style={{ fontWeight: 600, color: 'white', background: '#33c8a0' }}
          onClick={() => history.push('/welcome/e/invite')}
        >
          {t('employeeSignupBtn')}
        </Button>
        <Button
          variant="secondary"
          style={{ fontWeight: 600, color: 'white', background: '#33c8a0' }}
          onClick={() => history.push('/welcome/c/questionnaire')}
        >
          {t('consumerSignupBtn')}
        </Button>
      </ButtoWrapper>
    );
  };
  return (
    <WelcomeLayout noPadding noTopBar>
      {(isPartnerCheck === '' || isPartnerCheck === 'undefined') && <Loading />}
      <section>
        <MainSectionWrapper isMobile={isMobile} noPadding>
          <WelcomeContainer isMobile={isMobile}>
            <TextBlock
              type={'primary'}
              padding={'0px'}
              style={{
                color: 'rgb(51, 200, 160)',
                marginBottom: 5,
                // textAlign: isMobile ? 'left' : '',
                fontWeight: isMobile ? '600' : 'initial',
                fontSize: `${isMobile ? '18px' : '25px'}`,
              }}
            >
              {t('firstStep')} <span style={{ color: '#491f69' }}>{t('happiness')}</span> {t('today')}
            </TextBlock>
            {/* <CarouselWithDot /> */}
            <Description className="main-description">
              {!isMobile && (
                <TextBlock
                  type={'secondary'}
                  style={{ fontSize: isMobile && '13.5px', textAlign: isMobile ? 'left' : 'center' }}
                >
                  {t('therapyJourney')}
                </TextBlock>
              )}
              {!isPartnerCheck && !isMobile ? (
                <TextBlock
                  type={'secondary'}
                  style={{ fontSize: isMobile && '13.5px', textAlign: isMobile ? 'left' : 'center' }}
                >
                  <>
                    {t('toHelp')} <span style={{ color: '#33c8a0', fontWeight: 'bold' }}> {t('freeSupport')}</span>{' '}
                    {t('therapySubscription')}
                  </>
                </TextBlock>
              ) : null}
            </Description>
            <ImageWrapper isMobile={isMobile}>
              <TherapistImage isMobile={isMobile}>
                <BlendColor />
                <Image isMobile={isMobile} src={therapistMeta.image} />
              </TherapistImage>
              <TherapistDescription>
                <TherapistName>{therapistMeta.name}</TherapistName>
                <TherapistCertification>{therapistMeta.description}</TherapistCertification>
              </TherapistDescription>
            </ImageWrapper>
            <TherapistComment isMobile={isMobile}>
              <TextBlock type={'secondary'} style={{ fontSize: isMobile && '14px' }}>
                <span className="first-quote"></span>
                {!isMobile && t('myName')} {t('myDesc')} {isMobile && t('qna')}
                <span className="quote">&rdquo;</span>
              </TextBlock>
              {!isMobile && (
                <TextBlock type={'secondary'} style={{ fontSize: isMobile && '14px' }}>
                  <span className="quote">&ldquo;</span>
                  {t('qna')}
                  <span className="quote">&rdquo;</span>
                </TextBlock>
              )}
            </TherapistComment>
          </WelcomeContainer>
          {renderButton()}
        </MainSectionWrapper>
      </section>
      <section>
        <BottomSectionWrapper>
          <EmergencyBtn />
        </BottomSectionWrapper>
      </section>
    </WelcomeLayout>
  );
};

const WelcomeContainer = Styled.div`
  max-width:800px;
  display:flex;
  flex-direction:column;
  gap:${({ isMobile }) => (isMobile ? '0' : '1em')};
  margin-bottom: 0px;
`;

const ButtonGuestWrapper = Styled.div`
  position:${({ isMobile }) => isMobile && 'absolute'};
  bottom:0;
  z-index:99;
`;

const ButtoWrapper = Styled.div`
  margin-bottom: 20px;
  padding-top: 5px;
  button {
    margin: 0 20px 8px;
    padding: 10px;
    font-size:${({ isMobile }) => (isMobile ? '1rem' : '1.1rem')};
    border-radius: 12px;
  }
`;
const Description = Styled.div`
   &.main-description{
     div{
       &:first-child{
         line-height:1.5em;
         margin-bottom:0;
       }
       &:nth-child(2n-2){
         margin:0;
       }
     }
  }
`;
const ImageWrapper = Styled.div`
  overflow: hidden;
  margin-bottom: ${({ isMobile }) => (isMobile ? '5px' : '16px')};
  display:flex;
  align-items:center;
  justify-content: center;

  flex-wrap:wrap;
`;

const TherapistDescription = Styled.div`
    width: 100%;
    margin-top: 10px
`;

const TherapistName = Styled.p`
  font-weight: bold;
  font-size:medium;
  margin-bottom:0!important;
`;

const TherapistCertification = Styled.p`
  margin:0!important;
`;

const Image = Styled.img`
  width: ${({ isMobile }) => (isMobile ? '110px' : '130px')};
  border-radius: 50%;
  box-shadow: 0px 1px 2px rgb(0, 0, 0, 25%);
  border: .5px solid ${({ theme }) => theme.primary}
  margin-bottom: 20px
`;

const TherapistComment = Styled.div`
  margin-top:15px!important;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  font-height: 14px;
  line-height: 20px;

  div{
      max-width: ${({ isMobile }) => (isMobile ? '100%' : '550px')};
      // font-size:14px;
      text-align:left;
      border-radius:8px;
      margin-bottom:15px!important;
      background: ${({ theme }) => theme.primaryAlpha};
      padding:15px;
      .first-quote{
        position:relative;
        &::before{
            content: "“";
            font-size: 5em;
            font-weight: bold;
            position: absolute;
            top: -5px;
            left: -18px;
            opacity: .6;
            z-index:99;
        }
      }
      .quote{
        font-size:20px;
        font-weight:bold;
      }
    }
`;

const TherapistImage = Styled.div`
  position:relative;
  overflow: hidden;
  border-radius:50%;
  width: ${({ isMobile }) => (isMobile ? '100px' : '130px')};
  height: ${({ isMobile }) => (isMobile ? '100px' : '130px')};
`;
const BlendColor = Styled.div`
    background: #BBC8F4;
    height: 150px;
    width: 150px;
    left: 0;
    mix-blend-mode: color;
    position: absolute;
    top: 0;
`;

export default memo(Welcome);

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { updateProfile } from '../../../Actions/therapist.actions';
import { certificationBody } from '../../../Constants/other';
import Button from '../../Atoms/Button/Button';

import { FormGroup, Label } from '../../Atoms';

const TherapistQualificationsForm = ({ onSubmitDone, initialValues, children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const handleOnSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();

    values.insruance_certificate &&
      typeof values.insruance_certificate !== 'string' &&
      formData.append('insruance_certificate', values.insruance_certificate, values.insruance_certificate.name);
    values.certification &&
      typeof values.certification !== 'string' &&
      formData.append('certification', values.certification, values.certification.name);
    values.govt_identity &&
      typeof values.govt_identity !== 'string' &&
      formData.append('govt_identity', values.govt_identity, values.govt_identity.name);
    // typeof values.work_with_children === 'string' && formData.append('work_with_children', values.work_with_children ? '1' : '0');
    values.children_certificate &&
      typeof values.children_certificate !== 'string' &&
      formData.append('children_certificate', values.children_certificate, values.children_certificate.name);
    values.certification_body && formData.append('certification_body', values.certification_body);
    values.license_no && formData.append('license_no', values.license_no);

    try {
      const { message } = await dispatch(updateProfile(formData));
      onSubmitDone(message);
      setErrorMessage(null);
    } catch (err) {
      setErrorMessage(err?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    certification_body: Yup.string().required(t("therapyCertificationBodyRequired")),
    license_no: Yup.string().required(t("licenceNumberRequired")),
  });

  const certification = useRef();
  const governement = useRef();
  const insurance = useRef();
  const dbsCertificate = useRef();

  const handleClick = (e, type) => {
    e.preventDefault();
    if (type === 'certification') certification.current.click();
    if (type === 'govt_identity') governement.current.click();
    if (type === 'insruance_certificate') insurance.current.click();
    if (type === 'children_certificate') dbsCertificate.current.click();
  };

  const handleChange = (event, setFieldValue, type) => {
    event.preventDefault();
    setFieldValue(type, event.currentTarget.files[0]);
  };

  return (
    <Formik
      initialValues={
        initialValues || {
          insruance_certificate: null,
          certification: null,
          govt_identity: null,
          work_with_children: false,
          children_certificate: null,
          certification_body: '',
          license_no: '',
        }
      }
      enableReinitialize={!!initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form>
          <FormGroup>
            <Label htmlFor="certification_body">{t("therapyCertificationBody")}</Label>
            <Field
              as="select"
              id="certification_body"
              name="certification_body"
              type="text"
              className={
                'form-control' + (errors.certification_body && touched.certification_body ? ' is-invalid' : '')
              }
            >
              <option disabled value="" label={t("pleaseSelect")} />
              {certificationBody.map(({ value, name }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </Field>
            <ErrorMessage name="certification_body" component="div" className="invalid-feedback" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="license_no">
              <span style={{ position: 'relative', display: 'inline' }}>
              {t("licenceNumber")}<Mandatory>*</Mandatory>
              </span>
            </Label>
            <Field
              type="text"
              id="license_no"
              name="license_no"
              className={'form-control' + (errors.license_no && touched.license_no ? ' is-invalid' : '')}
            />
            <ErrorMessage name="license_no" component="div" className="invalid-feedback" />
          </FormGroup>
          <hr />
          <p>
          {t("therapistQual1")}
            <br />
            {t("therapistQual2")}
          </p>
          <FormGroup>
            <CustomFileInput>
              <label htmlFor="certification">
                <h3 style={{ position: 'relative', display: 'inline' }}>
                  {t("certification")} <Mandatory>*</Mandatory>
                </h3>
                <p>
                {t("therapistQual3")}
                </p>
              </label>
              <FileUploadWrapper>
                <LoadFile onClick={(e) => handleClick(e, 'certification')}>{t("chooseFile")}</LoadFile>
                <input
                  id="certification"
                  name="certification"
                  type="file"
                  ref={certification}
                  style={{ display: 'none' }}
                  onChange={(e) => handleChange(e, setFieldValue, 'certification')}
                />
                <FileUploadText>
                  {values?.certification
                    ? values?.certification?.name
                      ? values?.certification?.name
                      : t("fileUploaded")
                    : t("noFile")}
                </FileUploadText>
              </FileUploadWrapper>
            </CustomFileInput>
            <ErrorMessage name="certification" component="div" className="invalid-feedback" />
          </FormGroup>

          <FormGroup>
            <CustomFileInput>
              <label htmlFor="govt_identity">
                <h3 style={{ position: 'relative', display: 'inline' }}>
                {t("governmentID")} <Mandatory>*</Mandatory>
                </h3>
                <p>{t("therapistQual4")}</p>
              </label>
              <FileUploadWrapper>
                <LoadFile onClick={(e) => handleClick(e, 'govt_identity')}>{t("chooseFile")}</LoadFile>
                <input
                  id="govt_identity"
                  name="govt_identity"
                  type="file"
                  ref={governement}
                  style={{ display: 'none' }}
                  onChange={(e) => handleChange(e, setFieldValue, 'govt_identity')}
                />
                <FileUploadText>
                  {values?.govt_identity
                    ? values?.govt_identity?.name
                      ? values?.govt_identity?.name
                      : t("fileUploaded")
                    : t("noFile")}
                </FileUploadText>
              </FileUploadWrapper>
            </CustomFileInput>
            <ErrorMessage name="govt_identity" component="div" className="invalid-feedback" />
          </FormGroup>

          <FormGroup>
            <CustomFileInput>
              <label htmlFor="insruance_certificate">
                <h3 style={{ position: 'relative', display: 'inline' }}>
                {t("indemnityInsurance")} <Mandatory>*</Mandatory>
                </h3>
                <p>{t("obtained")}</p>
              </label>
              <FileUploadWrapper>
                <LoadFile onClick={(e) => handleClick(e, 'insruance_certificate')}>{t("chooseFile")}</LoadFile>
                <input
                  id="insruance_certificate"
                  name="insruance_certificate"
                  type="file"
                  ref={insurance}
                  style={{ display: 'none' }}
                  onChange={(e) => handleChange(e, setFieldValue, 'insruance_certificate')}
                />
                <FileUploadText>
                  {values?.insruance_certificate
                    ? values?.insruance_certificate?.name
                      ? values?.insruance_certificate?.name
                      : t("fileUploaded")
                    : t("noFile")}
                </FileUploadText>
              </FileUploadWrapper>
            </CustomFileInput>
            <ErrorMessage name="insruance_certificate" component="div" className="invalid-feedback" />
          </FormGroup>

          {/*<FormGroup>*/}
          {/*  <Checkbox*/}
          {/*    label="Do you offer therapy to children & adolescents?"*/}
          {/*    name="work_with_children"*/}
          {/*    className={*/}
          {/*      'form-control' +*/}
          {/*      (errors.work_with_children && touched.work_with_children ? ' is-invalid' : '')*/}
          {/*    }*/}
          {/*  />*/}
          {/*  <ErrorMessage name="work_with_children" component="div" className="invalid-feedback" />*/}
          {/*</FormGroup>*/}

          <FormGroup>
            <CustomFileInput>
              <label htmlFor="children_certificate">
                <h3 style={{ position: 'relative', display: 'inline' }}>
                  {t("therapistQual6")} <Mandatory>*</Mandatory>
                </h3>
                <p>
                {t("therapistQual5")}
                </p>
              </label>
              <FileUploadWrapper>
                <LoadFile onClick={(e) => handleClick(e, 'children_certificate')}>{t("chooseFile")}</LoadFile>
                <input
                  id="children_certificate"
                  name="children_certificate"
                  type="file"
                  ref={dbsCertificate}
                  style={{ display: 'none' }}
                  onChange={(e) => handleChange(e, setFieldValue, 'children_certificate')}
                />
                <FileUploadText>
                  {values?.children_certificate
                    ? values?.children_certificate?.name
                      ? values?.children_certificate?.name
                      :t("fileUploaded")
                    : t("noFile")}
                </FileUploadText>
              </FileUploadWrapper>
            </CustomFileInput>
            <ErrorMessage name="children_certificate" component="div" className="invalid-feedback" />
          </FormGroup>

          {errorMessage && (
            <ErrorUploadingMessage>
              <div style={{ fontWeight: 700 }}>{errorMessage.message}</div>
              {Object.keys(errorMessage.errors).map((key) => (
                <div>
                  {key.replace('_', ' ')}: {errorMessage.errors[key][0]}
                </div>
              ))}
            </ErrorUploadingMessage>
          )}

          <ButtonWrapper>
            <Button
              disabled={
                !values?.children_certificate ||
                !values?.insruance_certificate ||
                !values?.govt_identity ||
                !values?.certification ||
                !values.license_no ||
                loading
              }
              variant="primary"
              type="submit"
              loading={loading}
            >
              {t("continue")}
            </Button>
          </ButtonWrapper>
          <div>{children}</div>
        </Form>
      )}
    </Formik>
  );
};

const Mandatory = styled.span`
  position: absolute;
  top: -10px;
  right: -10px;
  color: red;
`;

const ButtonWrapper = styled.div`
  margin: ${({ theme }) => theme.spacingXl} 0;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 32px;
  }
`;
ButtonWrapper.displayName = 'ButtonWrapper';

const LoadFile = styled(Button)``;
const FileUploadText = styled.span`
  position: absolute;
  bottom: 8px;
  right: -4px;
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileUploadWrapper = styled.div`
  position: relative;
`;

const ErrorUploadingMessage = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.statusWarning};
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const CustomFileInput = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing};
  align-items: center;

  ${({ theme }) => theme.md`
    grid-template-columns: auto 220px;
  `};

  input {
    border: 0;
    background: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
    font-size: 15px;
  }
`;

TherapistQualificationsForm.propTypes = {
  onSubmitDone: PropTypes.func,
};

export default TherapistQualificationsForm;

import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Button, FormGroup, Label, Loading, ModalComponent, Tooltip, Container } from '../../Components/Atoms';
import { Collapse, Input } from 'antd';
import 'antd/dist/antd.css';
import { api } from '../../Util';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { store } from 'react-notifications-component';
import SRLogo from '../../Assets/Icons/SRLogo.png';
import { ReactComponent as IconChat } from '../../Assets/Icons/Chat.svg';

import { Nav, PatientMenu } from '../../../src/Components/Molecules';
import { ReactComponent as NotificationIcon } from '../../Assets/Icons/Notification.svg';

import { useWindowSize } from '../../Hooks';
import { useSelector } from 'react-redux';
import UrgentAlert from '../../Components/Molecules/Chatroom/UrgentAlert';
import { NotificationIndicator } from '../../../src/Components/Atoms';
import { ModalOverlay } from '../../Welcome/Components';
import { useTranslation } from 'react-i18next';

const AdditionalSupport = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { Panel } = Collapse;
  const subscription = useSelector((store) => store.user.subscription);
  const profile = useSelector((store) => store.user?.profile);
  const rooms = useSelector((store) => store.chatroom.rooms);
  const notifications = useSelector((store) => store.user.notifications);

  const [currentTab, setCurrentTab] = useState(null);
  const [isLoading, setIsLoading] = useState(0);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [benefits, setbenefits] = useState([]);
  const [faq, setFaq] = useState([]);
  const [initialFaq, setInitialFaq] = useState([]);
  const [currIndex, setCurrIndex] = useState(0);

  const { width } = useWindowSize();

  const isMobile = useMemo(() => width < 768, [width]);
  const isRoomActive = useMemo(() => {
    return (rooms && rooms[0]?.chatroom_status) === 'approved';
  }, [rooms]);

  const onFinish = async (values, { resetForm }) => {
    try {
      setFormSubmitLoading(true);
      await api.patient.benefitMessage(currentTab, values.message);
      resetForm();

      store.addNotification({
        title: 'Email Sent!',
        message: (
          <div>
            <p>{t("emailSentMsg")}</p>
          </div>
        ),
        type: 'success',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 4000,
          onScreen: true,
        },
      });
      setFormSubmitLoading(false);
      setOpen(false);
    } catch (error) {
      error?.response?.data?.errors?.message.forEach((msg) => {
        store.addNotification({
          title: 'Error!',
          message: (
            <div>
              <p>{msg}</p>
            </div>
          ),
          type: 'danger',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 4000,
            onScreen: true,
          },
        });
      });
      setFormSubmitLoading(false);
    }
  };

  useEffect(() => {
    const getFaq = async (id) => {
      setIsLoading((prevState) => prevState + 1);

      const response_faq = await api.patient.categoryFaq(id);
      setFaq((prevState) => ({ ...prevState, [id]: response_faq?.data?.data }));
      setInitialFaq((prevState) => ({ ...prevState, [id]: response_faq?.data?.data }));

      setIsLoading((prevState) => prevState - 1);
    };

    const getBenefits = async () => {
      setIsLoading((prevState) => prevState + 1);
      const response_benefit = await api.patient.benefitCategories();
      setbenefits(response_benefit?.data?.data.map((el) => el));
      setCurrentTab(response_benefit?.data?.data?.[0]?.id);
      response_benefit?.data?.data.map(async (el) => await getFaq(el.id));
      setIsLoading((prevState) => prevState - 1);
    };

    getBenefits();
  }, []);

  const onSearch = (e) => {
    let searchText = e.target.value;
    setCurrIndex(0);

    if (!searchText) return setFaq(initialFaq);
    const updatedData = { ...initialFaq };
    const filteredData = {};
    for (let categoryId in updatedData) {
      const filteredCategoryData = updatedData[categoryId].filter((elem) => {
        return (
          elem.question?.toLowerCase().includes(searchText.toLowerCase()) ||
          elem.answer?.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      filteredData[categoryId] = filteredCategoryData;
    }
    setFaq(filteredData);
  };

  const handleNext = () => {
    setCurrIndex(currIndex + 8);
  };

  const handlePrev = () => {
    setCurrIndex(currIndex - 8);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <ModalOverlay>
          <Loading styles={{ position: 'fixed', backgroundColor: 'rgba(255,255,255,0.2)' }} />
        </ModalOverlay>
      </div>
    );
  }

  return (
    <>
      {formSubmitLoading ? <Loading styles={{ position: 'fixed', backgroundColor: 'rgba(255,255,255,0.2)' }} /> : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <main style={{ overflow: 'auto' }}>
          <Cover>
            <StyledHeader>
              <Container>
                <HeaderContent>
                  <HeaderLeft>
                    {subscription?.plan_status === 'active' || (profile && profile['B2B']) ? <Nav showWhiteIcon /> : ''}
                    {(subscription?.plan_status === 'active' || (profile && profile['B2B'])) &&
                    location.pathname !== '/chatroom' ? (
                      <ChatIcon as={styledChatIcon} onClick={() => history.push('/')} path={{ fill: '#fff' }} />
                    ) : null}
                  </HeaderLeft>

                  <LogoLink to="/chatroom" isMobile={isMobile}>
                    <img src={SRLogo} alt="" style={{ height: isMobile ? '35px' : '55px' }} />
                  </LogoLink>

                  <HeaderRight>
                    {!isMobile && isRoomActive ? (
                      <UrgentAlertWrapper>
                        <UrgentAlert />
                      </UrgentAlertWrapper>
                    ) : null}
                    {subscription?.plan_status === 'active' || (profile && profile['B2B']) ? (
                      <StyledButtonLink to={'/notifications'}>
                        <Tooltip
                          id="notificationIcon"
                          text={t("viewNotifications/Announcements")}
                          place="bottom"
                          offset={{ bottom: 10 }}
                        >
                          <StyledNotificationIcon />
                        </Tooltip>
                        <NotificationIndicator count={notifications?.total_unread_count || 0} style={{ top: 28 }} />
                      </StyledButtonLink>
                    ) : null}
                    <PatientMenu showWhiteIcon />
                  </HeaderRight>
                </HeaderContent>
              </Container>
            </StyledHeader>
            <div style={{ margin: '10px 0px' }}>
              <TitleWrapper>{t("additionalSupport")}</TitleWrapper>
            </div>
            {profile?.faq_booking && (
              <ButtonWrapper>
                <Button
                  style={{ fontSize: '12px', padding: '12px 40px' }}
                  variant="medium primary"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {t("bookAnAppointment")}
                </Button>
                <div>
                  <StyledInput placeholder={t("search")} allowClear onChange={onSearch} />
                </div>
              </ButtonWrapper>
            )}
            <TabFormGroup>
              <TabWrapper>
                {benefits.map((tab, i) => (
                  <Tab
                    key={i}
                    onClick={() => {
                      setCurrIndex(0);
                      setCurrentTab(tab.id);
                    }}
                    active={tab.id === currentTab}
                  >
                    {tab.name}
                  </Tab>
                ))}
              </TabWrapper>
            </TabFormGroup>
            <svg
              class="fusion-hills-opacity-candy"
              fill="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="100%"
              viewBox="0 -0.5 1024 182"
              preserveAspectRatio="none"
            >
              <path
                class="st0"
                style={{ opacity: '0.12' }}
                d="M0 182.086h1024V41.593c-28.058-21.504-60.109-37.581-97.075-37.581-112.845 0-198.144 93.798-289.792 93.798S437.658 6.777 351.846 6.777s-142.234 82.125-238.49 82.125c-63.078 0-75.776-31.744-113.357-53.658L0 182.086z"
              ></path>
              <path
                class="st1"
                style={{ opacity: '0.18' }}
                d="M1024 181.062v-75.878c-39.731 15.872-80.794 27.341-117.658 25.805-110.387-4.506-191.795-109.773-325.53-116.224-109.158-5.12-344.166 120.115-429.466 166.298H1024v-.001z"
              ></path>
              <path
                class="st2"
                style={{ opacity: '0.24' }}
                d="M0 182.086h1024V90.028C966.451 59.103 907.059 16.3 824.115 15.071 690.278 13.023 665.19 102.93 482.099 102.93S202.138-1.62 74.24.019C46.49.326 21.811 4.217 0 9.849v172.237z"
              ></path>
              <path
                class="st3"
                style={{ opacity: '0.3' }}
                d="M0 182.086h1024V80.505c-37.171 19.558-80.691 35.328-139.571 36.25-151.142 2.355-141.619-28.57-298.496-29.184s-138.854 47.002-305.459 43.725C132.813 128.428 91.238 44.563 0 28.179v153.907z"
              ></path>
              <path
                class="st4"
                d="M0 182.086h1024v-77.312c-49.05 20.07-120.525 42.394-193.229 42.086-128.922-.512-159.846-72.294-255.795-72.294-89.088 0-134.656 80.179-245.043 82.022S169.063 99.346 49.971 97.401C32.768 97.094 16.077 99.244 0 103.135v78.951z"
              ></path>
            </svg>
          </Cover>
          <Container>
            <Wrapper isMobile={isMobile}>
              <MainContentContainer isMobile={isMobile}>
                {benefits?.length ? (
                  benefits.map((tab, i) => {
                    return tab.id === currentTab ? (
                      <div key={tab.id}>
                        {
                          <StyledCollapse>
                            {faq[tab.id]?.length ? (
                              faq[tab.id].slice(currIndex, currIndex + 8)?.map((element, index) => (
                                <Panel key={element.question + index} header={element.question}>
                                  {element.answer}
                                </Panel>
                              ))
                            ) : (
                              <div style={{ fontSize: 16, marginTop: 40 }}>{t("noResultsFound")}</div>
                            )}
                          </StyledCollapse>
                        }
                      </div>
                    ) : null;
                  })
                ) : (
                  <h2 style={{ alignSelf: 'center', color: '#212934' }}>
                  {t("additionSupportDescription")}
                  </h2>
                )}
                {faq[currentTab]?.length > 8 && (
                  <Pagination isMobile={isMobile}>
                    <Button variant="medium primary" disabled={currIndex === 0} onClick={handlePrev}>
                      {t("Previous")}
                    </Button>
                    <Button
                      variant="medium primary"
                      disabled={currIndex + 8 >= faq[currentTab]?.length}
                      onClick={handleNext}
                    >
                      {t("next")}
                    </Button>
                  </Pagination>
                )}
              </MainContentContainer>
            </Wrapper>
          </Container>
        </main>
      </div>
      <ModalComponent open={open} closeModal={() => setOpen(false)} showCloseButton={true}>
        <Formik
          initialValues={{
            subject: '',
            message: '',
          }}
          onSubmit={onFinish}
        >
          {({ errors, touched }) => (
            <Form>
              <FormGroup>
                <Label htmlFor="subject">{t("modalSubject")}</Label>
                <Field
                  type="text"
                  id="subject"
                  name="subject"
                  className={errors.subject && touched.subject ? ' is-invalid' : ''}
                />
                <ErrorMessage name="subject" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="message">{t("modalMessage")}</Label>
                <TextareaWrapper>
                  <Field
                    rows={5}
                    id="message"
                    name="message"
                    as={StyledTextarea}
                    className={errors.message && touched.message ? ' is-invalid' : ''}
                    placeholder={t("messageTooltip")}
                  />
                </TextareaWrapper>
                <ErrorMessage name="message" component="div" className="invalid-feedback" />
              </FormGroup>
              <Button type="submit">{t("send")}</Button>
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  );
};

export default AdditionalSupport;

const StyledNotificationIcon = styled(NotificationIcon)`
  fill: #fff !important;
`;

const StyledCollapse = styled(Collapse)`
  border: none;
  background-color: transparent;
  .ant-collapse-item {
    background-color: ${({ theme }) => theme.primaryLight};
    margin: 5px 0;
    border-radius: 15px !important;
    padding: 5px 0;

    .ant-collapse-header {
      font-weight: 500;
      font-size: 16px;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        font-family: Montserrat, sans-serif;
        white-space: pre-line;
      }
    }
  }

  label {
    font-size: 16px !important;
    font-weight: normal !important;
    padding: 0 !important;
  }
`;

const LogoLink = styled(Link)`
  width: ${({ isMobile }) => (isMobile ? '200px' : '300px')};
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
`;

const styledChatIcon = styled(IconChat)`
  path {
    fill: #fff !important;
  }
`;

const TitleWrapper = styled.h1`
  margin: 0;
  color: #ffffff;
  letter-spacing: 1.2px;
`;

const Tab = styled.button`
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  color: white;
  border-radius: 18px;
  cursor: pointer;
  opacity: 1;
  background-color: ${({ theme }) => theme.secondaryMedium};
  border: 0;
  outline: 0;
  padding: 10px;

  ${({ active }) =>
    active &&
    `
    background-color: #491F69;
    opacity: 1;
  `}
  &:hover {
    background-color: #491f69;
    opacity: 1;
  }

  ${({ theme }) => theme.md`
  font-size: 14px;
  padding: 10px 40px;
  `}

  ${({ theme }) => theme.lg`
  font-size: 14px;
  padding: 10px 40px;
  `}
`;

const Cover = styled.div`
  background: rgb(73, 31, 105);
  background: linear-gradient(
    180deg,
    rgba(73, 31, 105, 0.9136029411764706) 0%,
    rgba(51, 200, 160, 0.9220063025210083) 100%
  );
  width: 100%;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const TabWrapper = styled.div`
  display: flex;
  margin-top: 20px;

  border: none;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  justify-content: center;
  ${({ theme }) => theme.md`
    width: 100%;
  `}
`;

const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 30px;
`;

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: ${({ isMobile }) => (isMobile ? '15px' : '0px')};
`;

const StyledInput = styled(Input)`
  height: 40px;
  width: 200px;
  border-radius: 10px;
  .ant-input {
    height: 30px;
    padding-right: 6px;
  }
  .ant-input-suffix {
    margin-left: -5px;
    z-index: 2;
  }
  .ant-input:placeholder-shown {
    font-weight: 600;
  }

  ${({ theme }) => theme.md`
    width: 400px;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ isMobile }) => (isMobile ? '6px' : '12px')};
`;

const TabFormGroup = styled.div`
  flex: 1;
  margin-top: 70px;
  flex-direction: column;
  width: 70%;
  gap: 5px;
  margin: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 0.7;
  flex-direction: column;
  width: 100%;

  padding-left: ${({ isMobile }) => (isMobile ? '15px' : '0px')};
`;

const TextareaWrapper = styled.div`
  textarea {
    ::placeholder {
      color: #999999;
      font-size: 16px;
    }
  }
`;

const StyledTextarea = styled.textarea`
  resize: none;
`;
StyledTextarea.displayName = 'StyledTextarea';

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const ChatIcon = styled.svg`
  margin-left: 10px;
  cursor: pointer;
  width: 22px;
  path {
    fill: #491f69;
  }
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const UrgentAlertWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacingMd};
  transform: translateY(-2px);
  svg {
    fill: #fff !important;
  }
  button {
    height: 23px;
  }
`;

const StyledButtonLink = styled(Link)`
  margin-right: ${({ theme }) => theme.spacingMd};
  svg {
    width: 18px;
    fill: ${({ theme }) => theme.primary};
  }
`;
StyledButtonLink.displayName = 'StyledButtonLink';

const HeaderContent = styled.div`
  display: flex;
  margin: 40px 0px;
  align-items: center;
  justify-content: space-between;
`;

const StyledHeader = styled.header`
  width: 100%;
`;
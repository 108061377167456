import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import { ReactComponent as LaughingEmoji } from '../../../Assets/Icons/emoji-laughing.svg';
import { useWindowSize } from '../../../Hooks';

const EmojiPicker = React.forwardRef(({ style, onSelect }, ref) => {
  const { width } = useWindowSize();

  const isMobile = useMemo(() => width < 768, [width]);

  return (
    <StyledPopup
      modal={isMobile}
      trigger={(
        <EmojiButton style={style} />
      )}
      position={'top'}
      ref={ref}
    >
      {(close) => (
        <div>
          {isMobile && <PopupClose onClick={close}>&times;</PopupClose>}
          <Picker onSelect={onSelect} perLine={6} showPreview={false} showSkinTones={false} color={'#1A5549'} />
        </div>
      )}
    </StyledPopup>
  );
});

const StyledPopup = styled(Popup)`
  &-content {
    padding: 0 !important;
    width: auto !important;
  }
`;

const EmojiButton = styled(LaughingEmoji)`
  fill: ${({ theme }) => theme.primary};
  cursor: pointer;
`;

const PopupClose = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  box-shadow: 0 2px 7px 1px #8c8c8c;
  cursor: pointer;

  &:hover {
    background: #e7e7e7;
  }
`;

EmojiPicker.propTypes = {
  style: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
};

export default EmojiPicker;

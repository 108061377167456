import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { localTimezones } from '../../Constants/other';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, TextBlock, ChevronIcon, Loading } from '../../Components/Atoms';
import { WelcomeLayout } from '../../Layout';
import { api } from '../../Util';
import NewPaymentPlan from '../../Components/Molecules/PaymentPlan/NewPaymentPlan';
import { store } from 'react-notifications-component';
import { getSubscriptionStatus, redeemVoucher } from '../../Actions/patient.actions';
import { BottomSectionWrapper, MainSectionWrapper } from '../Components/';
import EmergencyBtn from '../../Components/Atoms/EmergencyBtn';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Plans = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLocal, setIsLocal] = useState(localTimezones.includes(Intl.DateTimeFormat().resolvedOptions().timeZone));
  const [expandAll, setExpandAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const subscription = useSelector((store) => store.user.subscription);
  const isPartnerCheck = useSelector((store) => store.isPartner?.isPartner);
  const userProfile = useSelector((store) => store.user?.profile);
  const [isPartner, setIsPartner] = useState();
  const [_currency, setCurrency] = useState(''); // GBP is set to default and this will change based on the location api response
  const [toggleVoucher, setToggleVoucher] = useState(false);

  // Dont show this page for B2C users
  if (userProfile && userProfile['B2B'] === 1) history.push('/');

  const dispatch = useDispatch();
  // const verifyPartner = useCallback(() => {
  //   setIsLoading(true);
  //   dispatch(checkPartner());
  // }, [dispatch]);

  const handleClick = useCallback(
    async (planId) => {
      const stripe = await stripePromise;

      if (
        planId &&
        (!subscription ||
          subscription.length === 0 ||
          subscription?.plan_status === 'expired' ||
          subscription?.plan_status === 'unsubscribed')
      ) {
        // Call your backend to create the Checkout Session
        return api.payment
          .purchasePlan(
            planId,
            `${window.location.origin}/checkout/success/`,
            `${window.location.origin}/checkout/error`,
          )
          .then((session) => {
            // When the customer clicks on the button, redirect them to Checkout.
            stripe
              .redirectToCheckout({
                sessionId: session.data?.data?.checkout_session_id,
              })
              .then((result) => {
                if (result.error) {
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer
                  // using `result.error.message`.
                }
              });
          });
      } else {
        return api.payment
          .switchPlan(planId)
          .then((res) => {
            if (res.data.status === 'success') {
              store.addNotification({
                title: t('success'),
                message: t('subscriptionPlanUpdated'),
                type: 'success',
                container: 'top-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
            }
            dispatch(getSubscriptionStatus());
          })
          .catch((error) => {
            store.addNotification({
              title: t('error'),
              message: error?.response?.data?.message,
              type: 'danger',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          });
      }
    },
    [subscription, dispatch],
  );

  const handlePlanSelection = () => {
    if (selectedPlan) {
      history.push(`/welcome/activate?plan=${selectedPlan.planId}`);
    } else {
      store.addNotification({
        title: t('warning'),
        message: t('choosePlan'),
        type: 'warning',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const submitPlanVoucher = useCallback(
    (voucherCode) => {
      setIsLoading(true);
      dispatch(redeemVoucher(voucherCode))
        .then(() => {
          setIsLoading(false);
          dispatch(getSubscriptionStatus()).then(() => history.push('/welcome/therapists'));
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [dispatch, history],
  );

  const handleSelectPlan = useCallback((plan) => {
    setSelectedPlan(plan);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    api.others
      .getLocation()
      .then((res) => {
        if (res.status === 'success') {
          const local = res.country === 'United Kingdom' || localTimezones.includes(res.timezone);
          setIsLocal(local);
          // return getPackages(local ? 'GBP' : 'USD');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isLocal]);

  const getPackages = useCallback(
    (_partner, currency = false, counter = 0) => {
      setIsLoading(true);
      let payload = {
        currency: isLocal && !currency ? 'GBP' : currency ? currency : 'USD',
      };
      if (_partner) payload = { ...payload, subdomain: window.location.host };
      // if (_partner) payload = { ...payload, subdomain: 'partner-test.supportroom.com' };
      api.welcome
        .getPackage(payload)
        .then((response) => {
          setIsLoading(false);
          if (!response.data?.data?.packages.length && counter !== 1) {
            setTimeout(() => {
              getPackages(_partner, payload.currency === 'GBP' ? 'USD' : 'GBP', 1);
            }, 1000);
            return false;
          }
          setPlans(response.data?.data?.packages || []);
          setToggleVoucher(response.data?.data?.enable_voucher_plan || false);
          setCurrency(payload.currency);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    },
    [isLocal],
  );

  useEffect(() => {
    if (typeof isPartnerCheck !== 'string') {
      setIsPartner(isPartnerCheck);
      getPackages(isPartnerCheck);
    }
  }, [isPartnerCheck, getPackages]);

  const isBootsPartner = window.location.host === 'boots.supportroom.com';

  return (
    <WelcomeLayout noPadding noTopBar>
      <section>
        <MainSectionWrapper>
          {isLoading && <Loading />}
          <TextBlock type={'primary'}>
            {!isLoading && isPartner ? (
              <>{isBootsPartner ? t('isBootsPartner') : t('notBootsPartner')}</>
            ) : !isLoading && !isPartner ? (
              <>
                {t('bootsPara1')} <span style={{ color: '#33c8a0' }}>{t('bootsPara2')}</span> {t('bootsPara3')}
              </>
            ) : (
              ''
            )}
          </TextBlock>
          <TextBlock type={'secondary'}>{isBootsPartner ? t('flexiPlan1') : t('flexiPlan2')}</TextBlock>
          <PlansWrapper>
            {plans.map((plan, index) => (
              <NewPaymentPlan
                key={index}
                category={plan}
                subscription={subscription}
                selectedPlan={selectedPlan}
                onSelectPlan={handleSelectPlan}
                onSubmit={handleClick}
                expandAll={expandAll}
                currency={_currency}
                isPartner={isPartner}
                setExpandAll={setExpandAll}
              />
            ))}
            {((!isPartner && !isLoading) || toggleVoucher) && (
              <NewPaymentPlan
                key={'businessPlan'}
                category={{ plan: 'businessPlan' }}
                subscription={subscription}
                selectedPlan={selectedPlan}
                onSelectPlan={handleSelectPlan}
                currency={_currency}
                onSubmitVoucher={submitPlanVoucher}
                onSubmit={handleClick}
                expandAll={expandAll}
                isPartner={isPartner}
                setExpandAll={setExpandAll}
              />
            )}
          </PlansWrapper>
        </MainSectionWrapper>
      </section>
      <section>
        <BottomSectionWrapper>
          <EmergencyBtn />
          {!subscription?.is_paid && (
            <Button
              variant="secondary"
              style={{ fontWeight: 600, color: 'white', background: '#33c8a0', marginBottom: '40px' }}
              // onClick={() => history.push('/welcome/activate')}
              disabled={!selectedPlan && !selectedPlan?.planId}
              onClick={handlePlanSelection}
            >
              {/* Let's start my free week */}
              {t('proceed')}
              <ChevronIcon direction="right" width="13" height="13" />
            </Button>
          )}
        </BottomSectionWrapper>
      </section>
    </WelcomeLayout>
  );
};

const PlansWrapper = styled.div`
  width: 550px;
  @media (max-width: 640px) {
    width: 90vw;
  }
`;

export default memo(Plans);
